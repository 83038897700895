@import '../../Helpers/Stylesheet/colors.scss';

.message {
    height: 19px;
    font-family: sanFrancisco;
    font-size: 16px;
    color: $popup-black;
    margin-bottom: 14px;
    @media only screen and (max-width: 769px) {
        height: auto;
        margin-bottom: 20px;
    }
}

.action-container {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    &-or {
        display: flex;
        justify-content: center;
        margin: 20px auto;
        color: $lighter-grey;
        @media only screen and (max-width: 769px) {
            margin: 15px auto;
        }
    }
    &-box {
        width: 100%;
        padding: 30px;
        border: solid 1px $border-color;
        border-radius: 4px;
        @media only screen and (max-width: 769px) {
            padding: 20px;
            &-button {
                margin: auto auto auto 0;
            }
        }
        &-redirect,
        &-archive {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &-msg {
                color: $button-grey;
                height: 14px;
                font-size: 12px;
                margin-bottom: 15px;
                font-family: sanFrancisco;
                @media only screen and (max-width: 769px) {
                    height: auto;
                }
            }
            &-checkbox {
                color: $button-grey;
                margin-bottom: 15px;
                font-family: sanFrancisco;
                span {
                    padding-left: 5px;
                    height: 14px;
                    font-size: 12px;
                }
            }
        }
    }
}
