@import './../../../../Helpers/Stylesheet/colors';

.AccountSettingEditPass {
    span {
        font-size: 12px;
        color: #4767a0;
        font-weight: normal;
        font-family: 'SFProDisplay-Light';
        letter-spacing: 0.6px;
        cursor: pointer;
        .calendar-plus-icon {
            margin-right: 5px;
        }
        .calendar-edit-icon {
            margin-left: 30px;
            margin-right: 5px;
        }
    }
}

.disconnect-button {
    background-color: $google-blue;
    color: $white;
    margin-right: 10px;
    border: solid 1px $google-blue;
    font-family: 'Roboto';
    font-weight: 500;
    &:hover {
        background-color: $google-blue !important;
    }

    &:focus {
        background-color: $google-blue !important;
    }
}

.google-icon-button {
    border: solid 1px $google-blue;
    background-color: $white;
    padding: 0px !important;

    &:hover {
        background-color: $white !important;
    }

    &:focus {
        background-color: $white !important;
    }
}

.google-icon {
    vertical-align: middle;
    margin-left: 10px;
    margin-bottom: 2px;
}

.calendar-sync-btn {
    width: 150px;
    margin-right: 30px;
    font-size: 12px;
    color: #4767a0;
    cursor: pointer;
}

.calendar-title {
    font-family: sanFranciscoMedium;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    width: calc(100% - 260px);
}

.calendar-icon {
    padding-right: 3%;
}

.calendar-data-title {
    font-size: 16px;
    color: #333333;
    margin-bottom: 2px;
}

.calendar-data-text {
    font-size: 13px;
    line-height: 1.3;
    color: #666666;
    padding-right: 30px;
}
.calendar-edit-btn {
    width: 110px;
    font-size: 12px;
    color: #4767a0;
    cursor: pointer;
}

.calendar-loaders {
    padding: 3%;
}

.calendar-badge {
    height: 80%;
    width: 80%;
}

.calendar-info {
    font-family: sanFranciscoMedium;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
}

.calendar-info-icon {
    margin-right: 1.5%;
    margin-left: 1%;
}

.calendar-buttons {
    display: flex;
    flex-direction: row;
    .ant-btn-group {
        display: flex;
    }
}

.calendar-default-btn {
    margin-left: 10px;
}

.calendar-data-body:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}
.calendar-data-body {
    margin-top: 30px;
    padding-top: 30px;
    display: flex;
    border-top: 1px solid #d9d9d9;
}
.calendar-data-body svg {
    margin-right: 10px;
}
.calendar-data-btn {
    margin-left: auto;
}
.calendar-data-btn button {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    background: #4767a0;
    border: none;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}
.calendar-data-end {
    width: 100%;
    height: 1px;
    margin: 2% 0 2% 0;
    opacity: 0.1;
    border: solid 1px #000000;
}
