@import '../../Helpers/Stylesheet/colors.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
}

.view {
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    padding: 12px 15px 15px;
    text-align: left;
    margin-top: 20px;
    &-delete {
        border: 1px solid #e1e4e8;
        border-radius: 4px;
        height: 100px;
        width: 100%;
        color: $popup-black;
        font-size: 14px;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
        padding: 12px 15px 15px;
        margin-top: 17px;
        &-btn {
            justify-content: center;
            display: flex;
            flex-direction: row;
            font-size: 11px;
            margin: 10px 10px 0 0;
        }

        @media only screen and (max-width: 769px) {
            height: 130px;
        }
    }
    &-title {
        color: $popup-black;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        font-family: sanFranciscoMedium;
        display: flex;
        justify-content: space-between;
        &-time {
            height: 20px;
            color: #53627c;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            margin-left: 5px;
            font-family: sanFrancisco;
        }
        &-icons {
            display: flex;
            margin-right: 10px;
        }
        &-icon {
            margin-left: 7px;
            @media only screen and (max-width: 769px) {
                margin-right: 15px;
            }
        }
        &-icon svg {
            height: 13px;
            width: 13px;
        }

        @media only screen and (max-width: 769px) {
            font-size: 12px;
        }
    }
    &-note {
        width: 517px;
        color: $button-grey;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 0 !important;

        @media only screen and (max-width: 769px) {
            width: 100%;
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin-top: 0px;
    &-hasNote {
        margin-top: 20px;
    }
    &-title {
        height: 16px;
        color: $popup-black;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
        display: flex;
        font-weight: bolder;
        margin-top: 20px;
    }
    &-title > .anticon {
        margin-left: auto;
    }
    &-box {
        border: 1px solid #e1e4e8 !important;
        border-radius: 4px;
        margin-bottom: 8px;
        margin-top: 10px;
    }
    &-group {
        text-align: left;
        margin-top: 10px;
        color: $button-grey;
        display: flex;
        justify-content: space-between;
        &-cb {
            height: 16px;
            width: 190px;
            color: #53627c;
            font-size: 10px;
            line-height: 16px;
        }
        &-mark {
            font-size: 12px;
        }
        &-icon {
            margin-left: 5px;
            font-size: 13px;
        }
        &-candidate {
            height: 16px;
            font-size: 12px;
            line-height: 16px;

            @media only screen and (max-width: 769px) {
                margin-top: 15px;
            }
        }

        @media only screen and (max-width: 769px) {
            margin-top: 0;
            flex-direction: column;
        }
    }
    &-private {
        color: $popup-black !important;
        font-size: 14px;
        margin-top: 17px;
        &-flex {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            &-info {
                display: flex;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            &-info-text {
                text-align: left;
                margin-left: 10px;
                @media only screen and (max-width: 769px) {
                    line-height: 1.5;
                }
            }
            &-visible {
                display: flex;
                flex-direction: column;
                margin-top: 15px;
            }
            &-visible-text {
                text-align: left;
                font-size: 14px;
                color: $popup-black;
                font-weight: bolder;

                @media only screen and (max-width: 769px) {
                    margin-top: 10px;
                }
            }
            &-visible-tags {
                margin-top: 5px;
                height: auto;
                max-width: 540px;
                overflow-wrap: break-word;
            }
            &-visible-tagwrapper {
                display: flex;
                flex-wrap: wrap;
            }
        }
        &-people {
            text-align: left;
            height: 16px;
            color: $button-blue !important;
            font-size: 12px !important;
            line-height: 16px;
            margin-top: 10px !important;
            cursor: pointer;
        }
        &-select {
            width: 100%;
            text-align: left;
        }
        &-search {
            justify-content: left;
            height: 32px;
            width: 233px;
            border-radius: 3px;
            background-color: #f1f2f4;
            margin-top: 10px;
            color: #53627c;
            font-size: 12px;
        }
        &-search div {
            height: 32px;
        }
        &-search > div {
            background: #f1f2f4;
            border: none;
        }
        &-search .anticon {
            font-size: 16px;
        }
        &-search svg {
            transform: none !important;
        }
        &-option {
            height: 26px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;
            line-height: 26px;
            padding: 0 12px;
        }
        &-name {
            color: #53627c;
            font-size: 12px;
        }
        &-add {
            width: 29px;
            color: #4767a0;
            font-size: 14px;
        }
    }
    &-info {
        display: flex;
    }
    &-info-icon {
        margin-left: 3px;
        margin-top: 10px;
    }
    &-info-text {
        position: relative;
        top: 9px;
        left: 5px;
    }
}

@media only screen and (max-width: 769px) {
    .mobile-visible {
        margin-top: 15px;
        font-size: 12px;
        &-title {
            font-weight: bolder;
        }
        &-list {
            color: $lighter-grey;
            margin-top: 5px;
        }
    }
    .mobile-time {
        margin-top: 10px;
        display: flex;
        align-items: center;
        &-text {
            margin-left: 5px;
            font-size: 12px;
        }
    }
}
