.next-opportunity-check-inbox {
    padding: 50px 0;
    width: 984px;
    margin: auto;
    @media only screen and (max-width: 995px) {
        padding: 40px 15px;
        width: 100%;
    }
    .next-opportunity-check-inbox-header {
        margin-bottom: 50px;
        h3 {
            font-size: 36px;
            color: #333333;
            font-family: 'SFProText-Heavy';
            margin-bottom: 20px;
            width: 100%;
            @media only screen and (max-width: 995px) {
                width: 100%;
                margin-bottom: 10px;
                font-size: 20px;
                font-family: 'sanFranciscoSemi';
            }
            img {
                margin-left: 20px;
                @media only screen and (max-width: 995px) {
                    width: 20px;
                    margin-left: 10px;
                }
            }
        }
        .next-opportunity-check-inbox-header-h3-lg {
            display: block;
            @media only screen and (max-width: 995px) {
                display: none;
            }
        }
        .next-opportunity-check-inbox-header-h3-sm {
            display: none;
            @media only screen and (max-width: 995px) {
                display: block;
            }
        }
        .next-opportunity-check-inbox-header-txt {
            p {
                font-size: 18px;
                font-weight: 300;
                color: #666666;
                line-height: 1.67;
                margin-bottom: 0;
                width: 100%;
                font-family: 'SFProDisplay-Light';
                letter-spacing: 1px;
                @media only screen and (max-width: 995px) {
                    width: 100%;
                    font-size: 14px;
                    line-height: 1.79;
                }
            }
            .next-opportunity-check-inbox-header-txt-last-para {
                margin: 30px 0 34px 0;
                @media only screen and (max-width: 995px) {
                    margin: 30px 0;
                }
            }
        }
    }
    .next-opportunity-check-inbox-get-access {
        a {
            font-size: 18px;
            font-weight: 600;
            color: #4767a0;
            text-decoration: underline;
            transition: ease 0.4s;
            @media only screen and (max-width: 995px) {
                font-size: 14px;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.next-opportunity-twitter-button {
    width: 150px;
    line-height: 35px;
    border-radius: 20px;
    border: solid 1px #03a9f4;
    background-color: #03a9f4;
    margin-right: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    @media only screen and (max-width: 995px) {
        width: 100%;
    }
    &:hover {
        background-color: #ffffff;
        color: #03a9f4;
        svg {
            fill: #03a9f4;
        }
    }
    svg {
        fill: #ffffff;
        margin-right: 5px;
        margin-top: -2px;
    }
}
