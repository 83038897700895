.AccountSettingWrapper {
    min-height: calc(100vh - 120px);
    background-color: #f7f8fc;
    padding-bottom: 30px;
    .AccountSettingWrapperContent {
        padding: 0 calc((100% - 1200px) / 2);
        display: flex;
        .AccountSettingWrapperContentLeft {
            flex: 1.5;
        }
        .AccountSettingWrapperContentright {
            flex: 1;
            margin-left: 30px;
        }
    }
}
