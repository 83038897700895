// -----------------------------------------------------------------------------
// This file contains all application-wide Sass colors.
// ---------------------------------------------------

$white: white;
$red: #e02020;
$theme-blue: blue;
$theme-black: black;
$button-blue: #4767a0;
$button-grey: #666666;
$google-blue: #4385f5;
$popup-black: #333333;
$sr-blue: #4767a0;
$input-text: #53627c;

$notify-success: #3acc6c;
$notify-warn: #fa6400;

$border-color: #e1e4e8;
$lighter-grey: #999999;
$solitude-grey: #f1f2f4;
$manatee-grey: #8a94a6;
$error-red: #e02020;
