@import './src/container/Helpers/Stylesheet/colors';

.drop-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    border: 2px dashed #4676a0;
    background-color: #f8fafd;
    text-align: center;

    .content {
        color: #666666;
        line-height: 25px;
        font-size: 14px;
    }

    &.error {
        border-color: $red;
        background: #fef8f8;
    }

    span {
        color: $sr-blue;
        cursor: pointer;
    }
}

.drop-zone-error-msg {
    display: flex;
    font-size: 12px;
    align-items: center;
    margin-top: 5px;
    color: $red;

    svg {
        margin-right: 5px;
        width: 14px;
        height: 14px;
    }
}

.drop-zone-mini {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    color: #666666;

    span {
        color: #4676a0;
    }
}
