@import '../../container/Helpers/Stylesheet/colors.scss';

$mobile-width: 400px;

.notification {
    &.ant-notification-notice {
        &.success {
            border-bottom: 3px solid $notify-success;
        }

        &.warning {
            border-bottom: 3px solid $notify-warn;
        }

        @media (max-width: $mobile-width) {
            width: 100%;
            margin-left: 10px;
        }

        border-radius: 4px;
        width: 400px;
        background-color: $white;
        padding: 20px 20px;
        margin-bottom: 10px;
    }

    .ant-notification-notice-with-icon {
        margin: 0px;
    }

    .ant-notification-notice-with-icon > span {
        height: 10px !important;
        width: 30px;
        vertical-align: middle;
        display: inline-block;
    }

    .ant-notification-notice-message {
        font-size: 14px;
        font-family: SFProText-Heavy;
        color: $popup-black;
        margin-left: 40px;
        margin-bottom: 5px;
        line-height: normal;
        font-stretch: normal;
    }

    .ant-notification-notice-description {
        font-size: 14px;
        font-family: sanFrancisco;
        font-weight: normal;
        color: $button-grey;
        margin-left: 40px;
        margin-right: 10px;
        word-break: break-word;
        line-height: normal;
        font-stretch: normal;
    }

    .ant-notification-notice-icon span {
        padding-right: 10px;
    }

    .ant-notification-notice-close {
        position: absolute;
        top: 0px;
        right: 0px;
        color: $button-grey;
        width: 25px;
        height: 25px;
        display: inline-grid;
        padding-top: 5px;
    }
}
