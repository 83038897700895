@import '../../Helpers/Stylesheet/colors.scss';

.account-width-120p {
    width: 120px !important;
}

.modal-as-title {
    font-size: 24px;
    color: #0a1f44;
    font-weight: 500;
    line-height: 32px;
    height: 32px;
    margin-bottom: 10px;
}

.font-family-mediumn {
    font-family: sanFranciscoMedium;
    color: #8a94a6;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
}

.modal-ad-title-color {
    color: $theme-black !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.margin-top-6 {
    margin-top: 6px;
}

.account-modal-ad-input {
    height: 42px;
    width: 245px;
    color: $input-text;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #d9d9d9 !important;
}

.account-modal-role {
    margin-top: 6px;
    width: 100%;
}

.account-modal-role > .ant-select-selection {
    height: 42px;
}

.account-modal-role-option {
    font-size: 16px;
    padding-top: 8px;
}

.itm-access-title {
    margin-bottom: 21px;
    height: 16px;
    width: 336px;
    color: $input-text;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.itm-access-option {
    height: 16px;
    color: $input-text;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
}

.itm-access-def {
    width: 508px;
    color: $input-text;
    font-size: 12px;
    line-height: 16px;
    margin-top: 7px;
}

.itm-access-def-title {
    height: 16px;
    width: 336px;
    color: $input-text;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.itm-job-def-title {
    height: 16px;
    color: $input-text;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
}

.itm-button {
    margin-top: 36px;
}

.itm-access-options {
    display: flex;
    justify-content: space-between;
}

.itm-access-options span {
    padding: 0 4px 0 0;
}

.itm-job {
    width: 94px;
    margin-bottom: 20px;
    display: flex;
    margin-right: 30px;
    line-height: 16px;
}

.itm-margin-right-0 {
    margin-right: 0;
}

.itm-job-name {
    color: $input-text;
}

.allJob-cont {
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    padding: 20px 20px 0;
}

.allJob-selectAll {
    margin-bottom: 20px;
    color: $input-text;
    line-height: 16px;
}

.cp-mt-34 {
    margin-top: 34px;
}

.modal-row {
    margin-top: 20px;
}

.uc-modal-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    bottom: 27px;

    .mt-10 {
        margin-top: 10px;
    }

    .ant-form-item-control {
        line-height: normal !important;
    }

    .ant-form-item {
        font-size: 12px !important;
        margin-bottom: 0 !important;
    }

    .ant-form label {
        font-size: 12px !important;
    }

    .ant-form-explain {
        margin-top: 5px;
    }

    @media only screen and (max-width: 769px) {
        bottom: 15px;
    }
}

.uc-element-body-wrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction: column;
}

.uc-element-text-wrapper {
    margin-top: 50px;
    color: $theme-black;
}

.uc-element-row-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: left;

    @media only screen and (max-width: 769px) {
        flex-direction: column;
        margin-top: 0;
    }
}

.uc-element-column-wrapper {
    width: 260px;

    @media only screen and (max-width: 769px) {
        margin-bottom: 20px;
        width: 100%;
    }
}

.top-margin {
    margin-top: 50px;
}

@media only screen and (max-width: 769px) {
    .top-margin {
        margin-top: 40px;
    }
}
