.next-opportunity-header {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 30px 0 38px 0;
    border-bottom: 1px solid #f2f2f2;
    @media only screen and (max-width: 769px) {
        padding: 13px 0 12px 0;
    }
    .next-opportunity-logo {
        padding-left: 15px;
        span {
            color: #4767a0;
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            font-family: 'sanFrancisco';
            @media only screen and (max-width: 769px) {
                font-size: 12px;
            }
        }
        div {
            color: #4767a0;
            font-size: 24px;
            line-height: normal;
            font-family: sanFranciscoSemi;
            @media only screen and (max-width: 769px) {
                font-size: 14px;
                margin-top: -3px;
            }
        }
    }
    .next-opportunity-signup-button {
        display: flex;
        align-items: center;
        align-content: center;
        @media only screen and (max-width: 769px) {
            padding-right: 15px;
        }
        button {
            width: 95px;
            line-height: 35px;
            border-radius: 20px;
            border: 1px solid #4767a0;
            font-size: 12px;
            font-weight: bold;
            color: #4767a0;
            background-color: #ffffff;
            padding: 0;
            transition: ease 0.5s;
            font-family: 'sanFranciscoSemi';
            cursor: pointer;
            &:hover {
                background-color: #4767a0;
                color: #ffffff;
            }
            @media only screen and (max-width: 769px) {
                width: 70px;
                line-height: 24px;
                font-size: 12px;
            }
        }
        .next-opportunity-twitter-button-lg {
            width: 150px;
            line-height: 35px;
            border-radius: 20px;
            border: solid 1px #03a9f4;
            background-color: #03a9f4;
            margin-right: 10px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: 995px) {
                display: none;
            }
            &:hover {
                background-color: #ffffff;
                color: #03a9f4;
                svg {
                    fill: #03a9f4;
                }
            }
            svg {
                fill: #ffffff;
                margin-right: 5px;
            }
        }
        .next-opportunity-twitter-button-sm {
            border: 0;
            width: auto;
            height: 20px;
            display: none;
            @media only screen and (max-width: 995px) {
                display: block;
                background: none;
                outline: none;
                &:hover {
                    background: none;
                }
            }
            svg {
                fill: #03a9f4;
                margin-right: 10px;
                width: 20px;
            }
        }
    }
}
