.ant-input {
    border: 1px solid #f1f1f4;
    color: #171d48;
}

::-webkit-input-placeholder {
    color: #161a47;
}
:-moz-placeholder {
    color: #161a47;
    opacity: 1;
}
::-moz-placeholder {
    color: #161a47;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #161a47;
}
::-ms-input-placeholder {
    color: #161a47;
}
::placeholder {
    color: #161a47;
}

.op-header-text {
    color: #4767a0;
    text-align: left !important;
    font-weight: 500;
}

.op-header-button {
    line-height: 42px;
    font-size: 12px;
    margin-left: 5px;
}

.empty-opening-wrapper {
    margin: 20px auto 10px;
}

.no-openings-added {
    width: 232px;
    height: 29px;
    font-family: sanFranciscoMedium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.lets-get-started-by {
    width: 308px;
    height: 19px;
    font-family: sanFrancisco;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin: 0 auto 18px;
}

.no-openings-add-btn {
    width: 150px;
    height: 42px;
    margin: 0 auto;
}

.opening-empty svg {
    height: 100px;
    width: 100px;
}

.card-header-text {
    font-size: 18px;
    line-height: 20px;
    color: #53627c;
    height: 20px;
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.card-subtitle-text {
    font-size: 14px;
    line-height: 16px;
    color: #8a94a6;
    height: 16px;
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card-applicant-count {
    font-size: 10px;
    line-height: 16px;
    color: #4767a0;
    height: 16px;
    font-family: sanFranciscoMedium;
    cursor: pointer;
}

.card-candidate-count {
    color: #4767a0;
    font-size: 22px;
    font-family: sanFranciscoMedium;
    cursor: pointer;
}

.card-stage-name {
    font-size: 14px;
    color: #8a94a6;
}

.op-header-container {
    flex-grow: 40;
}

.op-header-button-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.card-container {
    display: flex;
    flex-direction: column;
}

.card-display-flex {
    display: flex;
}

.stage-list {
    display: flex;
    padding: 0 16px;
}

.stage-list-data {
    margin: auto;
    top: 50%;
    text-align: center;
}

.btn-list {
    margin: auto 0;
    display: flex;
    min-width: 197px;
}

.margin-right-40 {
    margin-right: 40px;
}

.coordinator-list {
    display: flex;
    justify-content: center;
}

.coordinator-avatar {
    margin-right: 5px;
    background-color: #4767a0;
    height: 30px;
    width: 30px;
}

.coordinator-popover {
    padding: 20px 12px;
    min-width: 320px;
}

.coordinator-popover1 {
    padding: 12px 12px 2px;
    min-width: 250px;
}

.coordinator-popover-search {
    border-radius: 8px;
}

.coordinator-popover-search input {
    background-color: #f1f1f4;
}

.coordinator-popover-search svg {
    color: #202851;
}

.coordinator-list-result {
    margin-top: 25px;
}

.coordinator-list-result-avatar {
    margin: auto 5px;
    background-color: #4767a0;
    height: 32px;
    width: 32px;
}

.coordinator-result-name {
    margin: auto 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.coordinator-result-btn {
    border: none;
    box-shadow: none;
    margin-left: auto;
}

.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 13.177vw;
}

.oc-coordinator-list {
    color: #8b92a6;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
}

.oc-margin-auto {
    margin: auto;
    margin-right: 0;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.opening-header-section {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.opening-header-title h3 {
    color: #333333;
    font-family: sanFranciscoSemi;
    font-size: 18px;
    margin: 0;
}

.opening-designation h3 {
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 0;
    color: #4767a0;
    padding: 0 25px;
    margin-bottom: 6px;
    font-family: sanFranciscoMedium;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
}
.opening-header-buttons {
    display: flex;
}
.opening-header-buttons > div {
    flex-shrink: 0;
}
.opening-header-buttons .ant-input-search {
    width: 300px !important;
    margin-right: 15px;
}
.opening-header-buttons > :last-child {
    margin-left: 15px;
}
.opening-header-buttons svg {
    width: 16px;
    height: 16px;
}

.opening-header-section .opening-header-buttons button {
    background: #4767a0 !important;
    color: #fff;
}
.opening-header-section .opening-header-buttons button:hover {
    color: #fff;
    background: #4e72b3;
}
.opening-header-buttons button svg path {
    fill: currentColor !important;
    color: #fff;
}
.opening-header-buttons button:first-child {
    width: 150px;
    margin-right: 15px;
}

.opening-card {
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 25px 0 0 0;
    margin-bottom: 15px;
}

.opening-exp-n-location {
    display: flex;
    width: 100%;
}

.open-exp-n-location p {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: #999999;
    margin: 0;
    margin-right: 25px;
}
.open-exp-n-location p svg {
    margin-right: 4px;
}

.opening-exp-n-location-parent {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    padding: 0 25px;
}
.opening-addCan-dropdown-parent {
    display: flex;
}

.openings-add-candidate {
    display: flex;
    margin-right: 100px;
    width: 50%;
}

.openings-add-candidate .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1.5px #4767a0;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: #4767a0;
    margin-right: -5px;
    font-family: 'sanFranciscoSemi';
}

.opening-alloments {
    background-color: #e8eaf3;
    padding: 11px 25px;
    display: flex;
    align-items: center;
    overflow: auto;
}

.opening-alloments::-webkit-scrollbar {
    height: 5px;
}

.opening-alloments::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #d4d4d4;
    border-radius: 5px;
}

.opening-alloments::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
}

.opening-alloments::-webkit-scrollbar-thumb:hover {
    background: #4767a0;
}

.opening-alloments-inner {
    padding-right: 30px;
    flex-shrink: 0;
}

.opening-alloments-inner div {
    font-size: 12px;
    text-transform: capitalize;
}

.opening-alloments-inner div span {
    margin-right: 4px;
    font-size: 12px;
    font-family: SFProText-Heavy;
}

.openings-tag {
    height: 16px;
    width: 90px;
    color: #8a94a6;
    font-size: 8px;
    font-weight: 600;
    line-height: 8px;
    text-align: center;
    border: 1px solid #eff0f4;
    border-radius: 2px;
    background: #f1f1f4;
    padding: 2px 4px;
    margin-left: 10px;
}

.opening-container {
    padding: 30px 0;
    background-color: #f7f8fc;
    min-height: calc(100vh - 120px);
    max-width: 1200px;
    margin: auto;
}
.opening-container .filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}
.opening-container .filter .ant-select {
    min-width: 110px;
    margin-left: 10px;
}
@media only screen and (max-width: 769px) {
    .opening-container {
        padding: 15px !important;
    }
    .opening-header-section {
        display: block;
    }
    .opening-header-title {
        margin-bottom: 10px;
    }
    .opening-header-buttons {
        margin-bottom: 10px;
    }
    .opening-header-buttons button {
        font-size: 12px;
        width: auto;
    }
    .card-container {
        margin-top: 56px;
    }
    .opening-exp-n-location-parent {
        display: block;
    }
    .opening-exp-n-location {
        margin-bottom: 30px;
    }
    .openings-add-candidate {
        width: 80%;
        float: left;
        margin-bottom: 10px;
    }
    .opening-alloments {
        padding: 10px;
        display: inline-block;
        width: 100%;
    }
    .opening-alloments-inner {
        margin-bottom: 10px;
    }
    .opening-alloments-inner:last-child {
        margin-bottom: 0;
    }
}

#add-co-ordinator {
    border: none;
    background-color: rgba(0, 0, 0, 0);
}
