.popup-header {
    display: flex;
    flex-direction: column;

    &-text {
        p {
            font-size: 16px;
            color: #333333;
        }
    }

    .popup-form {
        display: flex;
        flex-wrap: wrap;

        &-field {
            width: 255px;

            .ant-form-item {
                margin-bottom: 0px;
            }

            &-text {
                font-size: 14px;
                line-height: 16px;
                color: #333333;
            }

            .dropdn-option {
                font-size: 16px;
                padding-top: 8px;
            }
        }

        .field-margin {
            margin-left: 25px;
        }

        @media only screen and (max-width: 769px) {
            &-field {
                width: 100%;

                &-text {
                    margin-bottom: 7px;
                }
            }

            .field-margin {
                margin-left: 0px;
                margin-top: 20px;
            }
        }
    }
}
