.header-text {
    height: 32px;
    width: 100%;
    color: #0a1f44 !important;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 32px;
    text-align: center !important;
    font-family: sanFranciscoMedium;
}

.already-have-an-acco {
    height: 16px;
    color: #8a94a6;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
}

.background-colour {
    height: 48px;
    width: 304px;
}

.have-account {
    color: #0d55cf;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.signup-button {
    background-color: #4767a0;
    color: #fff;
    height: 48px;
    width: 144px;
    border-radius: 5px;
    font-family: sanFranciscoMedium;
    border: 0;
}

.signup-form .ant-form-explain {
    text-align: left;
    margin: 5px auto 10px auto;
}

.ant-input-group-addon {
    background-color: #fff;
    border-color: #e1e4e8;
}
.signup-user-icon > svg {
    fill: #c9ced6;
}

.otp-form {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-input input {
    border: 2px solid #007fff !important;
}

.otp-input1 input {
    border: 1.5px solid #e1e4e8 !important;
    border-radius: 4px;
}

.otp-resend {
    text-align: right;
    line-height: 16px;
    min-height: 16px;
    font-size: 12px;
    margin-top: 10px;
    width: auto;
    float: right;
    color: #0d55cf;
    cursor: pointer;
}

.otp-resend-false {
    color: black;
    text-align: right;
    line-height: 16px;
    min-height: 16px;
    font-size: 12px;
    margin-top: 10px;
    width: auto;
    float: right;
}

.block-wrapper {
    display: flex;
}
.left-blocks {
    width: 50%;
}
.block-left-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.block-element {
    border-radius: 10px;
    background-color: #fff;
    position: relative;
}
.block-e-1 {
    width: 17vw;
    height: 17vh;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.block-e-2 {
    width: 11vw;
    height: 17vh;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.block-e-3 {
    width: 20vw;
    height: 17vh;
}

.block-left-middle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
}

.block-e-4 {
    width: 3vw;
    height: 11vh;
}
.block-e-5 {
    width: 3vw;
    height: 28.6vh;
    margin: 22px 0;
}
.block-e-6 {
    width: 3vw;
    height: 11.4vh;
}
.block-e-7 {
    width: 35vw;
    height: auto;
    box-shadow: 0px 0px 60px -19px rgba(0, 0, 0, 0.25);
}

.block-e-8 {
    width: 10vw;
    height: 210px;
}
.block-e-9 {
    width: 10vw;
    height: 43vh;
    margin: 22px 0 0 0;
}

.block-left-bottom {
    display: flex;
    justify-content: space-between;
}
.block-e-10 {
    width: 20vw;
    height: 13vh;
}
.block-e-11 {
    width: 8vw;
    height: 13vh;
}
.block-e-12 {
    width: 20vw;
    height: 13vh;
}

.right-blocks {
    width: 50%;
}
.block-right-top {
    display: flex;
    justify-content: space-between;
}
.block-e-13 {
    width: 20vw;
    height: 14vh;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.block-e-14 {
    width: 12vw;
    height: 14vh;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.block-e-15 {
    width: 15.4vw;
    height: 20.5vh;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}

.block-right-second {
    display: flex;
    justify-content: space-between;
}

.block-e-16 {
    width: 8vw;
    height: 17vh;
    margin-top: -5vh;
}
.block-e-17 {
    width: 11vw;
    height: 17vh;
    margin-top: -5vh;
}
.block-e-18 {
    width: 11.9vw;
    height: 16.3vh;
    margin-top: 10px;
    background-color: #4767a0;
    padding: 15px 13px 14px 15px;
    margin-top: -4vh;
}
.block-e-19 {
    width: 90px;
    height: 90px;
    padding: 16px 12px 12px 12px;
    background-color: #4767a0;
    /* margin: 0 0 20px 0; */
}
.block-e-19-block-content p {
    color: #fff;
    width: 85px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}
.block-e-19-block-content .block-icon {
    bottom: 0px;
    right: 10px;
}
.block-e-19-block-content .block-icon img {
    width: 28px;
    fill: #fff;
}
.block-e-20 {
    width: 8vw;
    height: 17.4vh;
    margin: 1vh 0 0 0;
}

.block-right-thrid {
    display: flex;
    justify-content: space-between;
}

.block-e-21 {
    width: 11vw;
    height: 11vh;
    margin-top: -5vh;
}
.block-e-22 {
    width: 7.8vw;
    height: 11vh;
    background-color: #4767a0;
    padding: 10px 12px 4px 10px;
    margin-top: -5vh;
}
.block-e-23 {
    width: 19.5vw;
    height: 28.4vh;
    background-color: #4767a0;
    padding: 20px;
    margin-top: -4vh;
}
.block-e-24 {
    width: 8vw;
    height: 33.6vh;
    margin-top: 3vh;
}

.block-right-fourth {
    display: flex;
    justify-content: space-between;
}

.block-e-25 {
    width: 19.5vw;
    height: 28.4vh;
    background-color: #4767a0;
    padding: 20px;
    margin-top: -28.5vh;
}
.block-e-26 {
    width: 16%;
    height: 11.2vh;
    background-color: #4767a0;
    padding: 10px 12px 4px 10px;
    margin: -11.4vh 0 0 14px;
}
.block-e-27 {
    width: 22%;
    height: 17vh;
    margin-top: 10px;
    background-color: #4767a0;
    padding: 15px 13px 14px 15px;
    margin-top: -11.5vh;
    margin-right: 9vw;
}

.block-right-fifth {
    display: flex;
    justify-content: space-between;
}

.block-e-28 {
    width: 39%;
    height: 22.6vh;
    margin-top: -4vh;
}
.block-e-29 {
    width: 7.9vw;
    height: 11vh;
    margin: -4vh 0 0 -2vw;
}
.block-e-30 {
    width: 17vw;
    height: 17.7vh;
    margin-top: 10px;
}
.block-e-31 {
    width: 10vw;
    height: 11vh;
    margin: -96px 0 0 408px;
}

.block-icon {
    position: absolute;
    bottom: 14px;
    right: 14px;
}
.block-e-18-block-content p {
    color: #fff;
    width: 85px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}
.block-e-22-block-content p {
    color: #fff;
    width: 85px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
}
.block-e-22-block-content .block-icon {
    bottom: 4px;
    right: 12px;
}

.block-e-23-block-content p {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    width: 148px;
}
.block-e-23-block-content .block-icon {
    right: 20px;
    bottom: 20px;
}

@media only screen and (max-width: 1280px) {
    .block-left-top {
        margin-bottom: 10px;
    }
    .block-e-5 {
        margin: 10px 0;
    }
    .block-e-6 {
        height: 46vh;
    }
    .block-e-7 {
        height: auto;
        width: 464px;
        min-height: auto;
    }
    .block-left-middle {
        margin-bottom: 10px;
    }
    .block-e-9 {
        margin: 10px 0 0 0;
        height: 56.5vh;
    }
    .block-e-2 {
        width: 10vw;
    }
    .block-e-11 {
        width: 7vw;
    }
    .block-e-18 {
        margin-top: 0vh;
    }
    .block-e-19 {
        /* margin-top: 10px; */
    }
    .block-e-20 {
        margin: 1.5vh 0 15px 0;
    }
    .block-e-21,
    .block-e-22,
    .block-e-26 {
        height: 13vh;
    }
    .block-e-23 {
        margin-top: -3vh;
    }
    .block-e-23-block-content p {
        font-size: 16px;
    }
    .block-e-24 {
        margin-top: 2vh;
        height: 36vh;
    }
    .block-e-25 {
        margin-top: -26vh;
    }
    .block-e-26 {
        margin: -9vh 0 0 0;
        width: 15.5%;
    }
    .block-e-27 {
        margin-top: -8.5vh;
    }
    .block-e-28 {
        margin-top: -3vh;
    }
    .block-e-28,
    .block-e-29 {
        height: 40.5vh;
    }
    .block-e-29 {
        margin: -2.2vh 0 0 0;
    }
    .block-e-30 {
        width: 20vw;
        height: 17vh;
        margin-top: 2vh;
    }
    .block-e-31 {
        width: 20vw;
        height: 20vh;
        margin: -140px 0 0 375px;
    }
    .block-right-fourth {
        margin-top: -2vh;
    }
}

@media only screen and (max-width: 995px) {
    .block-left-middle {
        padding: 0 10px;
    }
    .background-colour {
        width: 100%;
    }
}
