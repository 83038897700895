@import '../../Helpers/Stylesheet/colors.scss';

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__left {
        display: flex;
        justify-content: flex-start;
    }

    &__right {
        justify-content: flex-end;
        font-family: sanFranciscoMedium;
        font-weight: 500;
        color: $sr-blue;
        font-size: 12px;

        &--next-candidate-btn {
            cursor: pointer;

            &-disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}

.job {
    font-family: sanFranciscoMedium;
    font-weight: 500;
    font-size: 14px;
    color: $popup-black;

    &__message {
        margin-bottom: 15px;
    }

    &__label {
        margin-bottom: 7px;
    }

    &__input {
        width: 100%;
        font-family: sanFrancisco;
        font-size: 12px;
    }
}

.candidate {
    &__row {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 769px) {
            flex-direction: column;
            margin: 0;
        }
    }

    &__column {
        width: 260px;

        &__small {
            width: 115px;

            @media only screen and (max-width: 769px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        @media only screen and (max-width: 769px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    &__label {
        margin-bottom: 7px;
        font-weight: 500;
        font-size: 14px;
        font-family: sanFranciscoMedium;
        color: $popup-black;
    }

    &__input {
        font-family: sanFrancisco;
        font-size: 12px;
        margin-bottom: 30px;

        &--select {
            width: 100%;
            font-family: sanFrancisco;
            font-size: 12px;
        }

        &--phone {
            margin-bottom: 30px;

            .ant-input {
                font-family: sanFrancisco;
                font-size: 12px;
            }

            .ant-input-group-addon {
                background-color: #fff;

                .ant-select-selection-selected-value {
                    font-family: sanFrancisco;
                    font-size: 12px;
                    color: $button-grey;
                }
            }
        }

        &--date {
            margin-bottom: 30px;
            color: $button-grey;

            .ant-calendar-picker-input {
                font-family: sanFrancisco;
                font-size: 12px;
            }
        }

        @media only screen and (max-width: 769px) {
            margin-bottom: 10px;

            &--phone {
                margin-bottom: 10px;
            }
        }
    }

    &__error {
        margin-top: -20px;
        margin-bottom: 20px;
        color: $error-red;
        display: flex;
        align-content: center;

        &--icon {
            margin-right: 5px;
        }

        @media only screen and (max-width: 769px) {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }

    .ant-input {
        border: solid 1px $border-color;
        color: $button-grey;
    }
}

.add-candidate-division-line {
    margin: 25px auto;
    width: 100%;
    height: 1px;
    background-color: $border-color;
}

.resume {
    font-family: sanFranciscoMedium;
    font-weight: 500;
    color: $sr-blue;
    font-size: 12px;
    padding-top: 10px;
}
