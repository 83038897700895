.candidate-profile {
    max-width: 100%;
}

.create-job-opening-page {
    width: 100%;
    height: 42px;
    font-size: 12px;
    line-height: 20px;
}

.apply-now {
    display: flex;
    height: 42px;
    width: 384px;
}

.incomplete-candidate {
    margin-top: 6px;
    height: 42px;
    width: 245px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #d9d9d9 !important;
}

.autocomplete-list {
    cursor: pointer;
    list-style-type: none;
    padding: 5px 0px 0px 7px;
    color: #697998;
}

.autocomplete-item {
    margin: 4px;
}

.location-auto-complete-list {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 42px;
}
.location-auto-complete-list .autocomplete-list {
    border: 1px solid;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    border: solid 0.5px rgba(102, 102, 102, 0.3);
    padding: 0 10px 10px;
    position: absolute;
    width: 100%;
    top: 36px;
    left: 0;
    max-height: 145px;
    overflow-y: auto;
}
.location-auto-complete-list .autocomplete-list li {
    font-size: 12px;
    line-height: 22px;
    border-bottom: solid 0.5px rgba(102, 102, 102, 0.3);
    padding: 10px 0;
}
.location-auto-complete-list .autocomplete-list li:last-child {
    border-bottom: 0;
    padding: 5px 12px 0 5px;
}

.autocomplete-item-last {
    background-color: #f7f8fc;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    border: solid 0.5px rgba(102, 102, 102, 0.3);
    border-top: none;
}

.autocomplete-branding {
    height: 100%;
    color: black;
    margin-left: calc(50% - 60px);
    line-height: 40px;
    font-size: 12px;
}

.google-logo {
    margin: auto;
}

.autocomplete-container {
    height: 221px;
    position: absolute;
    width: 100%;
    top: 0;
}
