@import '../../Helpers/Stylesheet/colors.scss';

.assign-test {
    &__text {
        font-size: 14px;
        font-family: sanFranciscoMedium;
        line-height: 16px;
        margin-bottom: 7px;
        color: $popup-black;
    }
    &__select {
        margin-bottom: 25px;
        width: 50%;
        font-size: 12px;
    }
    &__message {
        font-size: 16px;
        margin-bottom: 30px;
    }
    &__error {
        margin-top: -20px;
        margin-bottom: 30px;
        color: $error-red;
        display: flex;
        justify-content: left;
        align-content: center;
        font-size: 12px;
        &--icon {
            margin-right: 5px;
            width: 12px;
            height: 12px;
            margin-top: 3px;
        }
    }

    @media only screen and (max-width: 769px) {
        &__select {
            width: 100%;
        }
    }
}
