.header-text {
    height: 32px;
    width: 100%;
    color: #0a1f44 !important;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 32px;
    text-align: center !important;
    font-family: sanFranciscoMedium;
}

.already-have-an-acco {
    height: 16px;
    color: #8a94a6;
    font-family: sanFranciscoMedium;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 10px;
}

.background-colour {
    height: 48px;
    width: 304px;
}

.center-input {
    margin-bottom: 25px;
}

.login-button {
    background-color: #4767a0;
    color: #fff;
    height: 48px;
    width: 144px;
    border-radius: 5px;
    margin-right: 24px;
    font-family: sanFranciscoMedium;
}

.have-account {
    color: #0d55cf;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.forgot-password {
    margin-left: 0;
    color: #4767a0;
    line-height: 48px;
    font-family: sanFranciscoMedium;
    cursor: pointer;
}

@media only screen and (max-width: 1280 px) {
    .forgot-password {
        margin-left: 0;
    }
}
@media only screen and (max-width: 995px) {
    .header-text {
        width: 100% !important;
        height: unset !important;
    }
    .login-button {
        margin: 0;
        display: inline-block;
        width: 95px;
        line-height: 40px;
        height: unset;
        border-radius: 4px;
        font-size: 14px;
        border: 0;
    }
    .forgot-password {
        line-height: 16px;
        margin-bottom: 30px;
    }
    .have-account {
        margin-left: 0 !important;
    }
    .background-colour {
        width: 100%;
    }
}
