body {
    background: #f7f8fc;
}

.ant-input {
    font-size: 12px;
    color: #666;
}

.dropdown-popover-ac .ant-popover-inner-content {
    padding: 0;
}

.dropdown-popover-ac .ant-menu-submenu-title {
    color: #53627c;
}

.dropdown-popover-ac .ant-menu-submenu {
    width: 201px;
}

.ac-dropdown {
    height: 40px !important;
    width: 180px !important;
    margin: 0 !important;
}

.ac-dropdown:first-child {
    margin-top: 4px !important;
}

.ac-dropdown:last-child {
    margin-bottom: 4px !important;
}

.ac-dropdown-company {
    height: auto !important;
    width: 180px;
    margin: 0 !important;
    margin-bottom: 0 !important;
    line-height: 20px !important;
    background: transparent !important;
    padding-left: 24px !important;
}

.ac-dropdown-company:hover,
.ac-dropdown-company:active,
.ac-dropdown-company:focus,
.ac-dropdown-company::after {
    background: transparent !important;
    border: none !important;
}

.ac-dropdown-company .ant-radio-wrapper {
    font-size: 12px !important;
    font-family: sanFranciscoMedium;
    color: #53627c;
    display: block;
    margin-bottom: 20px !important;
    width: 162px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ac-dropdown-company .ant-radio-wrapper:last-child {
    margin-bottom: 0 !important;
}

.ac-dropdown-company:first-child {
    margin-top: 4px !important;
}

.ac-dropdown-company:last-child {
    margin-bottom: 12px !important;
}

.ac-dropdown-company .ant-radio-group {
    width: 162px;
    overflow: hidden;
}

.ac-dropdown:hover {
    background-color: #f4f5fa;
}

.ac-sub-dropdown {
    height: 30px !important;
    width: 120px !important;
    margin: 0 !important;
    line-height: 30px !important;
}

.color-blue {
    color: #53627c;
}

.topbar-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: none;
    line-height: 54px;
    color: #8990a5;
    font-family: sanFranciscoMedium;
    text-align: center;
}

.topbar-search {
    margin-right: 19.5px;
    width: 40px;
    transition: width 0.5s;
}
.topbar-search.ant-select-focused {
    width: 350px;
}
.topbar-search .ant-select-selection__placeholder {
    margin-right: 20px !important;
    margin-top: -9px;
}
.topbar-search .anticon {
    font-size: 14px;
}

.topbar-serach-option {
    height: 47px;
    border-bottom: 1px solid #f1f2f4;
}

.topbar-serach-option:last-child {
    border-bottom: none;
}

.topbar-search-candidateName {
    height: 20px;
    width: 228px;
    color: #53627c;
    font-size: 12px;
    line-height: 20px;
}

.topbar-search-stages {
    height: 16px;
    width: 228px;
    color: #8a94a6;
    font-size: 10px;
    line-height: 16px;
}

.app-name-avatar {
    margin: auto 0;
    background-color: #4767a0;
    height: 24px;
    width: 24px;
    line-height: 24px;
    cursor: pointer;
}

.ant-menu-submenu-active .app-tp-ddi {
    display: inline !important;
}

.app-tp {
    min-width: 135px;
}

.app-tp .ant-menu-sub {
    margin-left: -8px;
    margin-top: -15px;
}

.app-tp-ddi {
    display: none;
    margin-left: 4px;
}

.app-tp-ddi * {
    height: 15px;
    width: 15px;
}

.app-tp-menu {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.app-tp-item {
    height: 28px !important;
    line-height: 28px !important;
    width: 160px;
    color: #8a94a6;
    font-size: 10px !important;
    text-align: center;
}

.sr-tp .ant-popover-inner-content {
    padding: 0;
}

.overall-content {
    margin-top: 56px;
    min-height: calc(100vh - 120px);
}
.mobile-menu {
    display: none;
}
.tap-menu {
    border: 1px solid #eee;
    padding: 5px;
    display: inline-block;
    border-radius: 5px;
    width: 45px;
    height: max-content;
    cursor: pointer;
}
.tap-menu span {
    height: 3px;
    width: 100%;
    border-radius: 3px;
    background-color: #53627c;
    display: block;
    margin: 4px 0;
}
.add-feedback {
    max-width: 100%;
    margin: 0;
}
.add-feedback .ant-modal-content {
    border-radius: 0;
}
.menu-content {
    width: 100%;
}
@media only screen and (max-width: 995px) {
    .desktop-navbar {
        display: none;
    }
    .overall-content {
        margin: 0;
        min-height: calc(100vh - 108px);
    }
    .mobile-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        background: white;
        border-bottom: 1px solid #eee;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 5;
    }
    .topbar-menu {
        position: fixed;
        left: unset;
        transform: unset;
        width: 100%;
    }
    .topbar-menu li {
        width: 30%;
    }
    .ant-menu-submenu-placement-bottomLeft {
        left: 15px !important;
    }
    .ant-menu-submenu-placement-rightTop {
        left: 138px !important;
        top: 50px !important;
    }
    .todo-header {
        display: block;
    }
    .todo-title {
        display: block;
    }
    .todo-subtitle {
        margin: 0;
        display: block;
        font-size: 10px;
    }
    .todo-header-main {
        padding: 0 15px;
    }
    .todo-header-main .td-header-container h3 {
        margin: 20px 0;
        font-size: 18px !important;
    }
    .td-data-container {
        padding: 0 15px;
        display: block;
        min-width: unset;
    }
    .filter-container {
        margin: 0;
        padding: 0;
        width: 100%;
        min-width: unset;
        margin-bottom: 20px;
    }
    .td-details-data {
        width: 100%;
        min-width: unset;
        margin-right: 0;
        padding: 15px;
        display: block;
        margin-bottom: 20px;
    }
    .td-details-data-text {
        width: 100%;
        min-width: unset;
    }
    .td-details {
        display: block;
        height: auto;
    }
    .interview-on,
    .interview-day,
    .interview-date {
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
        height: auto;
        border-left: 0;
    }
    .interview-time-cont {
        padding-left: 0;
    }
    .todo-header {
        margin-bottom: 8px;
    }
    .td-footer {
        height: auto;
        width: 100%;
        min-width: unset;
        line-height: 18px;
        padding: 10px;
        display: block;
        border: 1px solid #eee !important;
        border-radius: 4px;
    }
    .td-details-data.highlight {
        border: 1px solid #eee !important;
    }
    .td-footer div span {
        display: block;
    }
    .footer-dash {
        color: #fff;
        border-bottom: 1px solid #eee;
    }
    .td-details-action-icon-text {
        color: #ddd;
        width: 100%;
    }
    .td-details-action-icon {
        width: 100%;
    }
    .td-todo-list,
    .td-todo-list-inner {
        width: 100%;
        min-width: unset;
    }
    .td-details-data-schedule {
        width: 100%;
    }
    .td-todo-list > div {
        width: 100%;
        min-width: unset;
    }
    .todo-data-cont {
        display: block;
    }
    .todo-data-value {
        width: 100%;
        display: block;
        height: auto;
        margin-left: 0;
    }
    .view-candidate-profile-btn {
        width: 100%;
        margin: 10px auto 0 auto;
        height: unset;
    }
    .todo-cd-name {
        width: 100%;
        max-width: unset;
    }
    .td-bg-fill {
        width: 38px !important;
    }
    .todo-data-cont {
        margin-bottom: 10px;
    }
    .interview-on {
        margin-bottom: 0;
        color: #8a94a6;
        font-size: 14px;
    }
    .interview-day {
        font-size: 14px;
        line-height: 22px;
        color: #53627c;
    }
    .interview-date {
        line-height: 22px;
        color: #53627c;
    }
    .todo-data-title {
        height: unset;
        line-height: 16px;
        color: #53627c;
    }
    .interview-time {
        color: #53627c;
        font-size: 14px;
        line-height: 16px;
    }
    .topbar-menu {
        width: 100%;
        top: 54px;
    }
    .menu-content {
        margin: 54px 0 -54px 0;
        z-index: 999;
    }
    .modal-stage-position {
        margin-top: 10px;
    }
}
.add-feedback {
    max-width: 100%;
    margin: 0;
}
.add-feedback .ant-modal-content {
    border-radius: 0;
}
@media (max-width: 767px) {
    .fs-modal {
        max-width: 100vw;
        margin: 0;
    }
    .fs-modal .ant-modal-body {
        padding: 0 !important;
    }
}

/*used to adjust mandril email templates*/
.mandril-email-template-view {
    margin-left: -50px;
}
.mandril-email-template-view .mcnTextContentContainer {
    min-width: 90% !important;
    max-width: 90% !important;
}
