@import '../../Helpers/Stylesheet/colors.scss';

.custom-inline-error {
    display: inline-flex;

    svg {
        margin-left: 5px;
    }

    &-msg {
        margin-left: 6px;
        line-height: 14px;
        color: $error-red;
    }
}

.active-header {
    color: $sr-blue;
}

.last-sub-category:last-child {
    padding: 0;
}
