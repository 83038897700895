.radio-filter {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}

.radio-filter:last-child {
    margin-bottom: 6px;
}

.radio-filter .count {
    float: right;
    padding-right: 8px;
}

.refine-buttons {
    outline: none;
}

.disabled-icon-feedback g > g {
    stroke: #999;
    cursor: not-allowed;
}

.disabled-icon-calendar g {
    fill: #999;
    cursor: not-allowed;
}

.disabled-icon-comment path {
    fill: #999;
    stroke: #999;
    cursor: not-allowed;
}

.disabled-icon-cancel g {
    fill: #999;
    cursor: not-allowed;
}
