@import '../../Helpers/Stylesheet/colors.scss';

.board-preview-count > .ant-badge-count {
    background-color: #697398;
    color: $white;
    height: 11px;
    width: 11px;
    min-width: 4px;
    border-radius: 50%;
    padding: 0;
    line-height: 10px;
    font-size: 8px;
    box-shadow: none;
    display: flex;
    justify-content: center;
}

.board-preview-text {
    width: 52px;
    color: #697998;
    font-size: 8px;
    font-weight: bold;
    line-height: 7px;
    text-align: center;
    max-height: 50px;
}

.board-preview-icons {
    margin: 0 -2px;
}

.board-preview-icons > svg {
    height: 7px;
    width: 7px;
}

.modal-field-info {
    color: $popup-black;
    font-size: 16px;
    font-weight: 500;
}

.modal-field {
    margin: 10px 0;
}

.modal-radio-text {
    font-size: 14px;
    contain: $button-grey;
}

.modal-preview-wrapper {
    margin-top: 10px;
}

.modal-ad-position {
    width: 100%;
}

.modal-ad-position-option {
    font-size: 16px;
    padding-top: 8px;
}

.modal-ad-name {
    font-size: 13px;
}

.modal-ad-input {
    width: auto;
    height: 42px;
}

.stage-modal-wrapper {
    display: block;
    text-align: left;
    margin-bottom: 20px;
}

.stage-modal-config {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;
    font-size: 16px;
    color: $popup-black;

    &-flex-column {
        flex-direction: column;
    }

    & > p {
        width: 90%;

        @media only screen and (max-width: 769px) {
            width: 100%;
        }
    }

    &-block {
        width: 260px;
        &-heading {
            font-family: sanFranciscoMedium;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 7px;
        }

        &-select {
            width: 100%;
        }
        &-error {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            span {
                margin-left: 5px;
                margin-top: -2px;
                font-size: 12px;
                color: $red;
            }
        }
        @media only screen and (max-width: 769px) {
            width: 100%;
            padding-right: 0;
            & > p {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 769px) {
        margin-top: 20px;
        &-top-margin {
            margin-top: 20px;
        }
    }
}

.stage-modal-radio {
    margin-top: 25px;
    &-heading {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 15px;
    }
}

.stage-modal-preview-wrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction: column;
    height: 102px;
    overflow: hidden;
    width: 550px;
    padding: 10px 0;

    & > * {
        font-size: inherit;
    }
    & > h4 {
        padding: 10px 4px;
    }
}

.stage-board-preview-container {
    position: relative;
    height: 90px;
    display: flex;
    padding: 11px 0;
    background-image: linear-gradient(#c7d1eb, #7f92d2);
    overflow-x: scroll;

    @media only screen and (max-width: 769px) {
        overflow-x: scroll;
    }
}

.stage-column-preview-container {
    width: 100px;
    height: 90%;
    margin: 0 4px;
    background-color: #e1e0eb;
    padding: 7px 5px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
}

.bg-color {
    background-color: #d1d1d1;
}
