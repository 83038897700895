@import '../../Helpers/Stylesheet/colors.scss';

.selection-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__label {
        font-family: sanFranciscoMedium !important;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 7px;
    }

    &__sourcedVia {
        width: 260px;
        .ant-select {
            width: 100%;
            font-size: 12px;
        }
        .ant-select-selection--single {
            border-radius: 4px !important;
            border: solid 1px $border-color !important;
        }
        .ant-select-arrow-icon {
            color: $popup-black;
            svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__sourcedBy {
        width: 260px;
        .ant-select-auto-complete {
            width: 100%;
        }
        .ant-input {
            font-size: 12px;
            color: $button-grey;
            border-radius: 4px !important;
            border: solid 1px $border-color !important;
        }
        &--error {
            display: flex;
            margin-top: 5px;
            &--icon {
                margin-right: 5px;
                margin-top: 2px;
            }
            &--msg {
                color: $error-red;
            }
        }
        &__option {
            display: flex;
            align-items: center;
            &__avatar {
                margin-right: 5px;
                background-color: $sr-blue;
            }

            &__name {
                font-size: 14px;
                width: 85%;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .ant-input-suffix {
            .down {
                transform: rotate(0deg);
                color: $popup-black;
                transition: transform 0.2s linear;
            }
            .up {
                transform: rotate(180deg);
                transition: transform 0.2s linear;
            }
        }
    }

    @media only screen and (max-width: 769px) {
        &__sourcedVia {
            width: 100%;
        }

        &__sourcedBy {
            width: 100%;
            margin-top: 20px;
        }
    }
}
