.interviewer {
    position: relative;
}
.interviewer-select > div {
    transform: none !important;
}
.interviewer-select .ant-select-dropdown-menu-item {
    padding: 0 20px;
}
.interviewer-select .ant-select-dropdown-menu-item .option {
    border-bottom: solid 0.5px rgba(102, 102, 102, 0.3);
    padding: 10px 0;
}
.interviewer-select .ant-select-dropdown-menu-item:last-child {
    border: none;
}
.interviewer-select .ant-select-dropdown-menu-item span {
    display: block;
    font-size: 12px;
    color: #333333;
}
.interviewer-select .ant-select-dropdown-menu-item .email {
    color: #666;
}
.interviewer .invite {
    width: 60px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px #4767a0;
    background-color: #ffffff;
    position: absolute;
    right: 5px;
    top: 5px;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    color: #4767a0;
    cursor: pointer;
}
.interviewer .invite:hover {
    background-color: #4767a0;
    color: #fff;
}
.interviewer .ant-select-selection__rendered {
    display: flex;
    align-items: center;
}
.interviewer .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
    line-height: 20px;
    height: 22px;
}
.interviewer .ant-select-selection--multiple > ul > li,
.interviewer .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 0;
}
.interviewer .ant-select-selection--multiple .ant-select-selection__choice {
    border-radius: 13.5px;
    background-color: #ccdbf8;
    font-size: 12px;
    color: #4767a0;
    padding: 0 30px 0 10px;
}
.interviewer .ant-select-selection--multiple .ant-select-selection__choice__remove {
    right: 0;
    padding: 0 5px;
    background-color: #c1cfeb;
    transform: none;
}
.interviewSection {
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 995px) {
    .interviewer .ant-select-selection__rendered {
        width: 180px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: initial;
        margin-top: 10px;
    }
}
