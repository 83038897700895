.live-update {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    span {
        margin-left: 10px;
    }
    .pulse-button {
        font-family: 'sanFranciscoMedium';
        margin-bottom: 0;
        color: #666666;
        position: relative;
        width: 18px;
        height: 18px;
        border: none;
        box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
        border-radius: 50%;
        background-color: #e84c3d;
        background-repeat: no-repeat;
        cursor: pointer;
        -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    }

    .pulse-button:hover {
        -webkit-animation: none;
        -moz-animation: none;
        -ms-animation: none;
        animation: none;
    }
}

@-webkit-keyframes pulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}
@-moz-keyframes pulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}
@-ms-keyframes pulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}
@keyframes pulse {
    to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
}
