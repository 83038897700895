.ant-input {
    /* background-color: #f1f1f4 !important; */
    border: 1px solid #f1f1f4;
    color: #171d48;
}

::-webkit-input-placeholder {
    color: #161a47;
}
:-moz-placeholder {
    color: #161a47;
    opacity: 1;
}
::-moz-placeholder {
    color: #161a47;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #161a47;
}
::-ms-input-placeholder {
    color: #161a47;
}
::placeholder {
    color: #161a47;
}

.talentpool-header-container {
    width: 300px;
    margin: auto;
    margin-right: 24px;
}

.talentpool-header-text {
    color: #4767a0 !important;
    text-align: left !important;
    max-width: 300px !important;
    width: auto !important;
    margin-bottom: 0 !important;
    font-size: 24px !important;
    font-family: sanFranciscoMedium !important;
    letter-spacing: 0.5px;
    line-height: 32px !important;
}

.talentpool-header-button {
    width: 60%;
    height: 42px;
    font-size: 14px;
    display: flex;
    margin-left: 15px;
    border: solid 1px #4767a0;
    cursor: pointer;
}

.talentpool-header-button > * {
    color: #4767a0 !important;
    margin: auto;
    cursor: pointer;
}

.talentpool-card-header-location {
    margin-left: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #53627c;
    height: 20px;
    width: 128px;
}

.talentpool-card-header-source {
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #53627c;
    height: 20px;
    width: 128px;
}

.talent-card-block-top {
    display: flex;
    flex-direction: row;
}
.talent-card-contact {
    height: 16px;
    width: 204px;
    margin-left: 17px;
    margin-top: 7px;
    color: #8a94a6;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
}
.talent-card-details-main {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 17px;
    color: #8a94a6;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    width: 550px;
}
.talent-card-details-one {
    display: flex;
    flex-direction: row;
    min-width: 400px;
    padding-right: 15px;
    word-wrap: break-word;
}
.talent-card-details-two {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    min-width: 400px;
    padding-right: 15px;
    word-wrap: break-word;
}
.talent-card-details-span {
    width: 310px;
    margin-top: 10px;
    word-wrap: break-word;
}

.talent-card-details-span-one {
    width: 220px;
    margin-top: 10px;
    padding-right: 10px;
    word-wrap: break-word;
}

.horizontal-divider {
    box-sizing: border-box;
    margin-left: -20px;
    margin-top: -5px;
    height: 1px;
    width: 875px;
    border: 1px solid #e1e2eb;
}
.talent-card-block-bottom {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-left: 17px;
    color: #8a94a6;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    padding-top: 8px;
    margin-bottom: -6px;
}
.talent-card-select {
    width: 100px;
}
.talent-card-block-source {
    padding-top: 3px;
    padding-left: 10px;
    width: 530px;
    text-align: right;
}
.talent-card-date {
    padding-top: 3px;
    width: 180px;
    text-align: right;
    padding-right: 20px;
}
.talent-card-star {
    width: 10px;
    justify-items: right;
}
.talentpool-card-subtitle-text input {
    height: 24px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.card-candidate-count {
    color: #4767a0;
    font-size: 22px;
    font-family: sanFranciscoMedium;
}

.card-stage-name {
    font-size: 14px;
    color: #8a94a6;
}

.talentpool-header-search-container {
    display: flex;
    justify-content: space-between;
    width: 400px;
}
.talentpool-header-button-container {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: auto;
}
.talentpool-action-btn {
    height: 42px;
    min-width: 35%;
    margin-left: 10px;
}

.talentpool-header-button-container .ant-btn .anticon {
    position: absolute;
    left: 10px;
    top: 12px;
}

.display-flex {
    display: flex;
}

.stage-list {
    display: flex;
    padding: 0 16px;
}

.stage-list-data {
    margin: auto;
    top: 50%;
    text-align: center;
}

.btn-list {
    margin: auto 0;
    display: flex;
}

.margin-right-30 {
    margin-right: 30px;
}
.talentpool-header-search input {
    background-color: #eff0f4;
    height: 40px;
    width: 400px;
}
.talentpool-header-search input:focus,
.talentpool-header-search input:active {
    box-shadow: none;
}

.talentpool-filter-block {
    display: flex;
    margin-right: 40px;
}
.talentpool-block {
    width: 100%;
    display: flex;
    flex-grow: 12;
    justify-content: center;
}

.tp-filter-box {
    position: relative;
}

.relative-class {
    position: relative;
}

.banner-gcal-wrapper {
    margin-bottom: 20px;
}

.banner-gcal-wrapper-box {
    padding-top: 30px;
    background-color: #f7f8fc;
}

.sticky-with-banner {
    position: sticky;
    top: 188px;
}

.sticky-without-banner {
    position: sticky;
    top: 190px;
}

.sticky-header {
    position: sticky;
    top: 156px;
    height: 120px;
    padding-top: 15px;
    margin-bottom: 2px;
    background-color: #f7f8fc;
    z-index: 1;
}

.sticky-main-header {
    height: 100px;
    position: sticky;
    top: 56px;
    background-color: #f7f8fc;
    z-index: 1;
}

.sticky-bar-with-banner {
    position: sticky;
    top: 156px;
    height: 74px;
    padding-top: 32px;
    background-color: #f7f8fc;
    z-index: 1;
}

.sticky-bar-without-banner {
    position: sticky;
    top: 156px;
    height: 75px;
    padding-top: 32px;
    margin-bottom: 15px;
    background-color: #f7f8fc;
    z-index: 1;
}

.google-banner {
    margin-top: 10px;
    margin-bottom: 8px;
}

.talentpool-block-empty {
    display: flex;
    min-height: 500px;
}

.talent-pool-container-inner {
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 3px;
    width: 360px;
}

.talent-pool-container {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 188px;
    max-height: calc(100vh - 335px);
    padding: 24px 6px 24px 16px;
    overflow: hidden;
}

.ais-Panel-body .ant-checkbox-group {
    width: 320px;
}
.ais-Panel-body {
    overflow: hidden;
}
.ais-Panel-body:hover {
    overflow-y: auto;
}
.talent-pool-container:hover {
    overflow-y: auto;
}

.talentpool-action-block {
    display: flex;
    padding: 8px 0;
}
.talentpool-chip-block {
    text-align: right;
    flex-grow: 2;
    width: 500px;
}
.talentpool-chip-block .ant-tag {
    background-color: #eff0f4;
    border: none;
    border-radius: 2px;
    color: #8a94a6;
}
.talentpool-right-chip-block .ant-tag {
    border-radius: 2px;
    color: #8a94a6;
    background-color: white;
}
.talentpool-right-chip-block {
    width: 5px;
    flex-grow: 1;
    text-align: right;
}
.talentpool-left-chip-block {
    flex-grow: 1;
    margin-left: 10px;
    border-radius: 2px;
    color: #8a94a6;
    text-align: left;
    width: 50px;
}
.talentpool-sidebar-section {
    /* display: block;
        width: 100%; */
    margin-bottom: 16px;
}
.talentpool-sidebar-section .ant-collapse-header {
    padding: 4px 0 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #53627c !important;
}
.talentpool-sidebar-section .ant-collapse-item .ant-collapse-content-box {
    padding-bottom: 15px;
    margin-top: -10px;
    padding-right: 0px;
}
.talentpool-sidebar-section .ant-collapse-item {
    border: none !important;
}
.talentpool-sidebar-section .ant-collapse-header .anticon-right {
    right: 0;
    left: unset !important;
}
.talentpool-sidebar-section .ant-checkbox-wrapper {
    width: 100%;
    margin: 4px 0 !important;
}

.talentpool-sidebar-section .ant-collapse-item-active svg {
    transform: rotate(-90deg) !important;
}
.talentpool-sidebar-section svg {
    height: 14px;
    width: 14px;
    transform: rotate(90deg);
}
.talentpool-sidebar-section .ant-checkbox-wrapper .talentpool-count {
    float: right;
}
.talent-card-block {
    display: flex;
    margin-right: 20px;
    height: auto;
    position: relative;
}
.talent-card-block .talentpool-avatar-block .ant-avatar .talentpool-avatarName {
    display: block;
    font-weight: 900;
    color: #ffffff !important;
    font-size: 30px;
    height: 600px;
}
.talent-card-block .talentpool-avatar-block .ant-avatar {
    background-color: #4767a0;
    border-radius: 12px;
}
.talent-card-block .ant-badge .ant-badge-count {
    color: #4767a0 !important;
    background-color: #fff;
    border: 1px solid #4767a0;
    height: 29px;
    border-radius: 50%;
    width: 29px;
    padding: 3px 0;
    font-size: 10px;
    top: unset;
    right: 2px;
    bottom: -24px;
    box-shadow: none;
    font-weight: bold;
}

.talent-card-block .talent-description .talent-card-header .ant-tag {
    background-color: rgb(166, 174, 188);
    font-size: 10px;
    line-height: 16px;
    border: none;
    color: #fff;
    padding: 0 4px;
    float: right;
    margin: 0;
    border-radius: 2px !important;
    text-transform: uppercase;
}
.talent-card-block .talent-description .talent-card-header {
    flex-direction: row;
    font-size: 20px;
    line-height: 28px;
    color: #53627c !important;
    font-weight: 600;
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 11px;
}

.talent-card-block .talent-description .talentpool-categories-block {
    margin: 24px 0;
}
.talent-card-block .talent-description {
    margin-left: 20px;
    width: 75%;
}
.talent-button-block {
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
}

.talent-card-block .talent-button-block button span {
    color: #4767a0 !important;
}
.talent-card-block .talent-button-block button {
    display: block;
    width: 140px;
    height: 32px;
    background-color: #eff0f4;
    border: none;
    font-size: 12px;
    padding: 0;
    font-family: sanFranciscoMedium;
}
.talent-pool-page {
    background-color: #f7f8fc;
    padding: 0 0 0 0;
}
.talent-pool-page a,
.talent-pool-page a {
    color: #4767a0;
    border-radius: 4px;
}

.talentpool-bottom-icon svg {
    height: 13.2px;
    width: 13.2px;
}

.tp-jobs-filter {
    display: flex;
    flex: 1;
    min-width: 100px;
}

.tp-jobs-filter > span:last-child {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.tp-jobs-filter > .ant-checkbox {
    margin: auto 0;
}

.talentpool-block .ais-InfiniteHits-list {
    padding: 0;
}

.talentpool-block .ais-InfiniteHits-item {
    list-style: none;
}

.talent-pool-header-stats {
    display: flex;
}

.talent-pool-tags {
    align-items: center;
    height: 22px;
    font-size: 10px;
    min-width: 136px;
    border-radius: 3.5px;
    background-color: #94aad0;
    display: flex;
    border: 0;
}

.talent-pool-tags > span:first-child {
    height: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    margin: auto 0;
    line-height: 12px;
    align-items: center;
    display: flex;
    text-align: center;
    padding: 0 3px;
    font-size: 9px;
}

.talent-pool-tags > span:last-child {
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.45px;
    line-height: 18px;
    height: 18px;
    margin-left: 6px;
    font-weight: 600;
}

.talent-pool-filters {
    height: 23px;
    min-width: 12px;
    background-color: #f1f2f4;
    color: #8a94a6;
    font-size: 11px;
    font-weight: 600;
    line-height: 23px;
    margin-top: auto;
    margin-bottom: auto;
}

.talent-pool-filters svg {
    fill: #697998;
    font-weight: 600;
}

.talent-pool-refinements {
    height: 38px;
    display: flex;
    justify-content: flex-end;
}

.tp-headers-cb {
    display: flex;
    justify-content: space-between;
}

.tp-header-count {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tp-header-count > span {
    font-size: 10px;
    line-height: 11px;
    color: #8a94a6;
}

.talent-pool-filters:last-child {
    margin-right: 0;
}

.tp-clear-all {
    height: 23px;
    width: 63px;
    border: 2px solid #d1d6d4;
    border-color: #d1d6d4 !important;
    background-color: #ffffff !important;
    font-size: 11px;
    color: #8a94a6 !important;
    margin: auto 0;
}

.tp-cb-filter {
    display: flex;
    flex: 1;
}

.tp-cb-filter > span:last-child {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.tp-filter-mb-12 {
    margin-bottom: 12px;
}

.mb-6 {
    margin-bottom: 6px;
}

.mb-9 {
    margin-bottom: 9px;
}

.mb-18 {
    margin-bottom: 18px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-3 {
    margin-top: 3px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-12 {
    margin-top: 12px;
}

.ml-4 {
    margin-left: 4px;
}

.tp-filter-title-with-banner {
    color: #333333;
    font-size: 12px;
    font-family: sanFranciscoMedium;
    font-weight: bold;
    margin-top: 33px;
    margin-bottom: 25px;
}

.tp-filter-title-without-banner {
    color: #333333;
    font-size: 12px;
    font-family: sanFranciscoMedium;
    font-weight: bold;
    margin-top: 34px;
    margin-bottom: 25px;
}

.tp-filter-line {
    margin: 20px 0 20px 0;
    width: 297px;
    height: 1px;
    opacity: 0.5;
    border: solid 1px #e1e4e8;
}

.tp-filter-title {
    height: 20px;
    color: #000000 !important;
    font-size: 14px;
    line-height: 20px;
    font-family: sanFranciscoMedium;
    display: flex;
}

.tp-filter-label {
    height: 20px;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    width: 200px;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
}

.tp-job-filter-label {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}

.tp-job-filter-label-wrapper {
    height: 20px;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
}

.tp-filter-count {
    height: 20px;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.tc-name {
    height: 20px;
    color: #53627c !important;
    font-size: 18px;
    line-height: 20px;
}

.tc-jobs {
    margin: 0 7px !important;
    padding: 0 6px !important;
    color: #ffffff !important;
    font-size: 9px !important;
    line-height: 16px !important;
    cursor: pointer;
    white-space: unset;
}

.tc-labels {
    height: 16px;
    color: #8a94a6;
    font-size: 12px;
    line-height: 16px;
}

.tc-info-icon {
    margin-right: 6px;
}

.tc-info-icon svg {
    height: 11px;
    width: 14px;
}

.tp-stage-filter-job {
    color: #666666;
    font-size: 8px;
}

.tc-footer {
    height: 33.5px;
    margin: 0 -12px 0 -20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e1e2eb;
    color: #8a94a6;
    font-size: 10px;
    padding: 0 11.4px 0 20px;
}

.tc-footer div {
    margin: auto 0;
}

.tc-footer-date {
    margin: 0 15.4px 0 20px !important;
}

.tc-footer .sr-bold {
    font-weight: bold;
}

.tc-add-detail-btn {
    height: 26px;
    width: 100px;
    background-color: #f1f2f4;
    color: #4767a0;
    font-size: 12px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    border: none;
    margin-top: 4px;
}

.tc-add-detail-btn:hover,
.tc-add-detail-btn:focus,
.tc-add-detail-btn:active {
    background-color: #f1f2f4 !important;
}

.tp-show-more {
    width: 150px;
    margin: 0 auto 30px;
}

.tp-show-more:hover,
.tp-show-more:focus,
.tp-show-more:active {
    background: transparent !important;
    border: 1px solid #d9d9d9 !important;
}

.tc-cards {
    display: flex;
    flex-direction: column;
}

.tp-dropdown-icon {
    margin-left: auto;
    cursor: pointer;
}

.tp-dropdown-icon svg {
    height: 20.45px;
    width: 20px;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: transform 150ms ease;
}

.rotate-360 {
    transform: rotate(360deg);
    transition: transform 150ms ease;
}

.tp-filter-none .ais-Panel-body {
    display: none;
}

.tc-header-cb {
    margin: 0;
    color: #8a94a6;
    font-size: 10px;
    height: 25px;
    width: 86px;
    border: 1px solid #d1d6d4;
    border-radius: 1px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.tc-header-cb > span {
    margin: 0;
    top: 0;
}

.tc-header-cb .ant-checkbox-inner {
    height: 12px;
    width: 12px;
}

.tc-footer-cb {
    margin: auto 0;
    color: #8a94a6;
    font-size: 10px;
}

.tc-footer-cb .ant-checkbox-inner {
    height: 12px;
    width: 12px;
}

.tp-source {
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 16px;
    line-height: 12px;
    min-width: 20px;
    border-radius: 3.43px;
    background-color: #a6aebc;
    font-size: 11px;
    color: #fff;
    padding: 0 8px;
}

.tp-source > span {
    font-weight: 600;
}

.tc-no-can-icon {
    position: absolute;
    top: 18px;
    left: 20px;
}

.tc-no-can-icon svg {
    height: 13px;
    width: 13px;
}

.tp-refresh {
    margin: auto auto auto 10px;
}

.tp-refresh span {
    color: #fff !important;
    font-size: 10px;
    line-height: 22px;
    font-family: sanFranciscoMedium;
}

.tp-opening-panel {
    width: 320px;
}

.ais-Panel.tp-opening-panel {
    height: auto;
    margin-bottom: 20px;
}

.tp-opening-panel .ais-Panel-body {
    max-height: 140px;
    margin-right: 0;
}

.tp-refresh-now-date {
    width: 241px;
    color: #666666;
    font-size: 12px;
}

.tp-refresh-now-btn {
    width: 69px;
    color: #4767a0 !important;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
}

.tp-refresh-container {
    width: 1200px;
    text-align: right;
    padding-right: 5px;
}

.talent-description-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.openings-tag {
    height: 16px;
    width: 90px;
    color: #8a94a6;
    font-size: 8px;
    font-weight: 600;
    line-height: 8px;
    text-align: center;
    border: 1px solid #eff0f4;
    background: #f1f1f4;
    margin-right: 10px;
    align-self: auto;
}

.tp-page-bulk {
    width: 200px;
    min-height: 30px;
    float: right;
    color: #4767a0;
    font-weight: bold;
}

.tp-page-bulkaction {
    height: 60px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
}

.tp-page-count {
    width: 400px;
    height: 16px;
    font-size: 14px;
    color: #666666;
    font-weight: 500;
}

.tp-page-btn-text {
    font-size: 13px;
}

.tp-page-cancel-btn {
    color: #4767a0 !important;
    border: solid 1px #4767a0;
}

.tp-page-btn-section {
    display: flex;
    flex-direction: row;
}

.talentpool-bulk-btn {
    margin-right: 20px;
}

.color-red {
    color: red !important;
}

.cp-options {
    width: 100%;
    padding: 2px 5px;
}
.tp-data-cont {
    width: 100%;
}

.tp-page-bulkcontainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: bold;
}

.tp-page-can-count {
    color: black;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.tp-page-bulk-btn {
    color: #4767a0;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}

.tp-page-top-select {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tp-page-top-select-container {
    width: 120px;
}

.tp-page-bulk-select {
    color: #666666;
    font-weight: bold;
    cursor: pointer;
}

.tp-card-menu {
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    color: #666666;
}

.tp-card-menu-items {
    font-size: 12px !important;
    width: 100%;
    line-height: 16px;
}

.color-red {
    color: red;
}

.color-orange {
    color: #fa6400 !important;
}

.tp-card-btn {
    margin: 15px 0;
    background: #ffffff;
    color: #4767a0 !important;
    border: solid 1px #4767a0;
}

.cursor-pointer {
    cursor: pointer;
}

.tp-archived-tag {
    min-width: 75px;
    min-height: 25px;
    border-radius: 5px;
    background-color: #eeeeee;
    font-size: 14px;
    margin: 10px 0 0 20px;
    padding: 2px 2px 2px 12px;
}

.tp-card-tags {
    display: flex;
    flex-direction: column;
}

.tp-card-select {
    width: 90px;
}

.color-black {
    color: black;
}

.color-blue {
    color: #4767a0 !important;
}

.tp-card-tooltip {
    width: 15%;
}

.tp-card-menu.ant-menu-root.ant-menu-vertical {
    border: none;
    font-size: 12px;
    min-width: 10.3vw;
}

.ant-menu-item.tp-card-menu-items {
    margin: 0;
}

.tp-card-value {
    color: #000000 !important;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7vw;
}

.tps-card {
    width: 80px;
}

.tpl-card {
    width: 125px;
}

.tps-card-value {
    width: 11vw;
}

.ant-tooltip-inner.tp-card-icon-tt {
    width: 100px;
    max-height: 30px;
}

.tp-cb-filter.ant-checkbox-wrapper {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tp-jobs-filter.ant-checkbox-wrapper {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-popover-inner-content {
    padding: 0;
}

.tp-card-upload {
    outline: 0;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin: 0;
}

.tp-filter-divide {
    height: 1px;
    opacity: 0.5;
    border: solid 1px #e1e4e8;
    margin: 20px auto;
}

.talent-pool-container::-webkit-scrollbar {
    width: 6px;
    border-radius: 42px;
}
.talent-pool-container::-webkit-scrollbar-track {
    background-color: #eeeeee;
    width: 4px;
}
.talent-pool-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.tp-opening-panel .ais-Panel-body::-webkit-scrollbar {
    width: 4px;
}
.tp-opening-panel .ais-Panel-body::-webkit-scrollbar-track {
    background-color: #eeeeee;
    width: 4px;
}
.tp-opening-panel .ais-Panel-body::-webkit-scrollbar-thumb {
    background-color: #ccc;
}
.tps-wrapper {
    display: flex;
    margin-bottom: 18px;
}
.tps-wrapper-card {
    display: flex;
    width: 33.3%;
}
.tps-wrapper-last {
    margin-bottom: 0;
}
.tps-label {
    font-size: 0.9em;
    font-weight: 500;
    color: #999;
    margin-right: 20px;
}
.tps-values {
    font-size: 0.9em;
    font-weight: 500;
    color: #000000;
    word-break: break-word;
    padding-right: 10px;
}
.tp-card-phone {
    display: inline-block;
    margin-left: 10px;
}

.tp-status-filter {
    width: 100%;
    position: relative;
    bottom: 13px;
}

.tp-filter-divide-opening {
    height: 1px;
    opacity: 0.5;
    border: solid 1px #e1e4e8;
    margin-bottom: 20px;
}

@media only screen and (max-width: 995px) {
    .talent-description-button {
        display: block;
    }
    .talentpool-avatar-block {
        text-align: center;
        margin-bottom: 10px;
    }
    .talent-card-block .talentpool-avatar-block .ant-avatar {
        border-radius: 50%;
    }
    .talent-card-block {
        display: block;
        margin: auto;
    }
    .tc-footer {
        height: auto;
        margin: auto;
        display: block;
        padding: 0;
    }
    .talent-card-block .talent-description {
        margin-left: 0;
        width: 100%;
    }
    .talent-card-header {
        flex-direction: column !important;
    }
    .talentpool-card-subtitle-text {
        text-align: center;
    }
    .tc-add-detail-btn {
        width: 100%;
        height: 32px;
        margin-bottom: 4px;
    }
    .talent-card-block .talent-button-block button {
        width: 100%;
    }
    .talent-button-block {
        margin-bottom: 4px;
    }
    .tc-header-cb {
        border-radius: 2px;
        padding: 6px;
    }
    .tc-name {
        text-transform: capitalize;
    }
    .tc-labels {
        width: 100%;
        margin-bottom: 12px;
    }
    .tc-footer-cb .ant-checkbox-inner {
        padding: 7px;
    }
    .talent-pool-container-inner {
        width: 100%;
    }
    .talentpool-filter-block {
        margin-right: 0;
        margin-bottom: 30px;
    }
    .tp-card-tags {
        text-align: center;
        margin-top: 20px;
    }
    .tp-page-bulkaction {
        display: block;
        height: unset;
        padding: 15px;
    }
    .tp-page-count {
        width: 100%;
        height: auto;
        font-size: 12px;
        margin-bottom: 8px;
    }
    .tp-page-btn-section {
        flex-direction: column;
    }
    #actionsButton {
        margin: 0 0 8px 0;
    }
    .tp-page-bulk-select {
        text-align: right !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .tps-wrapper {
        display: block;
        margin-bottom: 0;
    }
    .tps-wrapper-card {
        width: 100%;
        margin-bottom: 10px;
    }
    .tps-wrapper-card-notice {
        margin-bottom: 0;
    }
    .talent-pool-container-inner {
        width: 100%;
    }
    .tp-card-phone {
        display: block;
        margin-left: 0;
        margin-top: 6px;
    }
    .ais-Panel-body .ant-checkbox-group {
        width: 100%;
        padding-right: 0;
    }
    .tp-jobs-filter > span:last-child {
        flex: unset;
    }
    .tp-jobs-filter.ant-checkbox-wrapper {
        justify-content: flex-start;
    }
    .tp-opening-panel {
        width: 100%;
    }
    .color-black {
        word-break: break-all;
    }
    .tp-page-bulkcontainer {
        position: static;
    }
    .sticky-main-header {
        height: auto;
    }
    .sticky-header {
        position: static;
        height: auto;
    }
}
