// Title
.calendar-onboarding-title {
    &-appear {
        opacity: 0;
        transform: translateY(-50%);
    }

    &-appear-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 600ms ease-in, transform 300ms ease-in;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 5000ms;
    }
}

//Image
.calendar-onboarding-image {
    &-appear {
        opacity: 0;
        transform: translateX(-50%);
    }

    &-appear-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 600ms ease-in, transform 300ms ease-in;
    }
}

// SetAvailibility day list animations

.set-availabitiy-transition-enter {
    opacity: 0.2;
    height: 0;
}

.set-availabitiy-transition-enter.set-availabitiy-transition-enter-active {
    opacity: 1;
    height: 50px;
    transition: all 0.4s ease-out;
}

.set-availabitiy-transition-leave {
    opacity: 0.8;
}

.set-availabitiy-transition-leave.set-availabitiy-transition-leave-active {
    opacity: 0;
    height: 0;
    margin: 0;
    transition: all 0.4s ease-out;
}
