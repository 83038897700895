.fp-button,
.fp-button:hover,
.fp-button:active {
    background-color: #4767a0;
    height: 48px;
    width: 304px;
    color: #fff;
    margin: auto;
    border-radius: 6px;
}

.fp-form {
    width: 100%;
    margin: auto;
}

.fp-input input {
    border: 2px solid #007fff !important;
}

.fp-input1 input {
    border: 1.5px solid #e1e4e8 !important;
    border-radius: 4px;
}

.fp-subtitle {
    min-height: 16px;
    color: #8a94a6;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 10px;
    font-family: sanFranciscoMedium;
}

.fp-title {
    font-family: sanFranciscoMedium;
}

.fp-back-icon {
    margin-right: auto;
    margin-left: -42px;
}

@media only screen and (max-width: 995px) {
    .fp-back-icon {
        margin-left: 0;
    }
    .fp-button,
    .fp-button:hover,
    .fp-button:active {
        width: 100%;
        background-color: #4767a0 !important;
    }
}
