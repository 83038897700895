@font-face {
  font-family: "sanFrancisco";
  font-style: normal;
  src: local("SF-Pro-Text-Regular"),
    url(./assets/Font/SF-Pro-Text/SF-Pro-Text-Regular.otf) format("opentype");
}

@font-face {
  font-family: "sanFranciscoMedium";
  font-style: normal;
  src: local("SF-Pro-Text-Medium"),
    url(./assets/Font/SF-Pro-Text/SF-Pro-Text-Medium.otf) format("opentype");
}

@font-face {
  font-family: "SFProText-Heavy";
  font-style: bolder;
  src: local("SFProText-Heavy"),
    url(./assets/Font/SF-Pro-Text/SFProText-Heavy.ttf) format("opentype");
}

@font-face {
  font-family: "SFProText-Bold";
  font-weight: bold;
  src: local("SF-Pro-Display-Semibold"),
    url(./assets/Font/SF-Pro-Text/SF-Pro-Display-Semibold.otf)
      format("opentype");
}

@font-face {
  font-family: "sanFranciscoSemi";
  font-style: normal;
  src: local("SF-Pro-Display-Semibold"),
    url(./assets/Font/SF-Pro-Text/SF-Pro-Display-Semibold.otf)
      format("opentype");
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/Font/Montserrat-Regular.ttf);
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(./assets/Font/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(./assets/Font/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "SFProDisplay-Light";
  /* src: url('SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),  url('SFProDisplay-Light.woff') format('woff'), url('SFProDisplay-Light.ttf')  format('truetype'), url('SFProDisplay-Light.svg#SFProDisplay-Light') format('svg'); */
  src: local("SF-Pro-Display-Semibold"),
    url(./assets/Font/SF-Pro-Text/SFProDisplay-Light.ttf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  src: local("Roboto-Medium"),
    url("./assets//Font//Roboto-Medium.ttf") format("opentype");
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

body {
  font-family: sanFrancisco;
  font-size: 15px;
  margin: 0;
}

h2 {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  background-color: #4767a0;
  color: #fff;
}

.modal-ad-position-option > h5,
.ant-select-selection-selected-value > h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.margin-left-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.tox-statusbar {
  display: none !important;
}

.sr-disable-btn {
  pointer-events: none !important;
}

.w-100 {
  width: 100%;
}

.my-auto {
  margin: auto 0;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.tool-tip-overlay {
  max-width: unset !important;
}

.primary-color {
  color: #4767a0;
}

.td-bg-fill {
  background: #fff;
  border: 2px solid #4767a0;
  height: 34px;
  width: 34px;
  display: flex;
  border-radius: 50%;
}

.td-bg-fill > .td-action-icon {
  margin: auto;
}

.sr-bold {
  font-family: sanFranciscoMedium;
}
.interview-types li {
  display: flex;
  align-items: center;
}
.interview-types li svg {
  margin-right: 5px;
}
.schedule-interview-modal .ant-modal-content {
  height: 100vh;
}
.schedule-interview-modal {
  padding-bottom: 0;
}
.h-80 {
  height: 85px;
}
.schedule-interview-modal .ant-modal-content .ant-modal-body {
  padding: 0 !important;
}
