.managaeNotificationParent {
    padding: 25px 0;
    .managaeNotification {
        border-bottom: 1px solid #e1e4e8;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 25px;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
        .managaeNotificationContent {
            padding-right: 55px;
            h6 {
                font-size: 16px;
                color: #000;
                margin-bottom: 7px;
            }
            p {
                font-size: 12px;
                letter-spacing: -0.08px;
                color: #666666;
                margin-bottom: 0;
            }
        }
    }
}
