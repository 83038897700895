.apply-now-container {
    /* width: 1280px; */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100vh;
}

.apply-now-body {
    margin: 28px auto;
}

.apply-now-body-container {
    display: flex;
    flex-direction: row;
}

.apply-now-container .header {
    /* background: url(/static/media/headerBG.a04c51f0.png); */
    background-size: cover;
    padding: 20px 5%;
}

.job-role {
    font-size: 30px;
    font-weight: bold;
    font-family: sanFranciscoMedium;
    color: rgb(71, 103, 160);
}

.job-details span {
    margin: 10px 30px 0 0;
    display: inline-flex;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(71, 103, 160)
}

.job-details svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.full-time {
    font-size: 12px;
    font-family: sanFranciscoMedium;
    color: rgb(71, 103, 160);
    margin-left: 10px;
}

.apply-now-card {
    width: 875px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    padding: 30px 30px 40px 30px;
    margin-bottom: 40px;
    background: #fff;
}

.apply-now-card-title {
    height: 20px;
    color: #53627c;
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 30px;
}

.apply-now-card-detail {
    color: #8a94a6;
    font-size: 17px;
    line-height: 23px;
    word-break: break-word;
}
.apply-now-card-detail p {
    margin: 0;
}
.apply-now-job {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}
.apply-now-btn {
    height: 40px;
    width: 150px;
    color: '#4767A0';
    font-size: 14px;
    margin-top: 20px;
}
.apply-now-job-detail {
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    padding: 20px 28px 20px 20px;
    color: #697998;
    font-size: 14px;
    background: #fff;
}

.apply-now-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;
    line-height: 16px;
}

.apply-now-column1 {
    width: 81px;
}

.apply-now-column2 {
    width: 112px;
    word-wrap: break-word;
}

.apply-now-social {
    display: flex;
    flex-direction: column;
    height: 91px;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 4px;
    color: #4767a0;
    font-size: 14px;
    line-height: 20px;
}

.apply-now-social-cell {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    cursor: pointer;
}

.apply-now-social-box {
    margin-left: 12px;
    margin-right: 12px;
    width: 24px;
    height: 24px;
}

.apply-form-container {
    min-height: 200px;
    width: 875px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    padding: 40px;
    margin-bottom: 40px;
    background-color: #ffffff;
}

.apply-form-title {
    height: 20px;
    width: 218px;
    color: #4767a0;
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 30px;
}

.last-working {
    margin-left: 10px;
}
.apply-form-cell {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.apply-form-left {
    /* display: flex; */
    /* flex: 1; */
    /* flex-direction: column; */
    width: 385px;
    /* margin-right: 30px; */
    margin-bottom: 20px;
}

.apply-form-right {
    /* display: flex; */
    /* flex: 1; */
    /* flex-direction: column; */
    width: 385px;
    /* margin-right: 30px; */
    margin-bottom: 20px;
}

.apply-form-full {
    /* display: flex; */
    /* flex: 1; */
    /* flex-direction: column; */
    width: 790px;
    /* margin-right: 30px; */
    margin-bottom: 20px;
}

.apply-form-static {
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 500;
    color: #333333;
}

.apply-form-currency {
    display: flex;
    justify-content: space-between;
}

.apply-form-text {
    height: 16px;
    color: #53627c;
    font-size: 12px;
    line-height: 16px;
}

.apply-form-input {
    height: 42px;
    color: #a6aebc;
    font-size: 14px;
    line-height: 20px;
}

.apply-form-right .ant-form-explain,
.apply-form-left .ant-form-explain {
    margin: 3px 0 0 2px;
}

.apply-form-resume-btn button {
    width: 380px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    line-height: 42px;
    padding: 0 10px;
    color: #a6aebc;
}

.apply-form-resume-btn button:hover,
.apply-form-resume-btn button:focus {
    background-color: #fff;
    color: #a6aebc;
}

.apply-form-resume-btn1 {
    width: 108px;
    height: 25px;
    margin: auto;
    margin-right: 0;
    line-height: 25px;
    background-color: #adb6c4;
    color: #fff;
    font-size: 9px;
    border-radius: 4px;
}

.apply-now-input-number,
.apply-now-input-number input {
    width: 100%;
    height: 42px;
    font-size: 14px;
}

.apply-now-btn,
.apply-now-btn:hover,
.apply-now-btn:active,
.apply-now-btn:focus {
    color: #4767a0 !important;
    background-color: #fff !important;
}

.applynow-select .ant-form-item-control {
    line-height: normal;
}

.applynow-select .ant-select-selection--single {
    height: 42px;
}

.applynow-select .ant-select-selection__rendered {
    line-height: 40px;
}

.apply-now-phone .ant-input-group-addon {
    background: none;
    border: 1px solid #f1f1f4;
    border-right: 0;
}

.apply-now-phone span,
.apply-now-phone input {
    height: 42px;
}

.has-error .ant-input-group-addon,
.has-error input {
    border-color: #f5222d !important;
}

.apply-now-phone-input li {
    padding: 0 16px;
    font-size: 12px !important;
    height: 32px !important;
    line-height: 32px !important;
    min-width: 150px;
    font-family: sanFranciscoMedium;
    border-bottom: 1px solid #f4f5fa;
    margin-bottom: 0 !important;
}

.apply-now-phone-input li:last-child {
    border-bottom: none;
}

#mnhJobboardDescriptionForDisplay {
    width: auto !important;
}

.an-serving-notice-period {
    padding: 5px 12px;
    cursor: pointer;
}

.an-serving-notice-period:hover {
    background-color: #d3dae0;
}

.an-np-dp {
    left: 10% !important;
    top: 280px !important;
}

.apply-form-dp input {
    height: 42px;
}

.share-icon-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.share-icon {
    margin: 0 15%;
}

@media (max-width: 767px) {
    .apply-now-card {
        width: 90vw;
    }
    .apply-now-body-container {
        flex-direction: column;
    }
    .apply-now-job {
        margin-left: 0;
        width: auto;
    }
    .apply-now-body {
        position: relative;
        width: 90%;
    }
    .apply-form-container {
        width: auto;
        padding: 20px;
    }
    .apply-form-resume-btn button,
    .apply-form-resume-btn > div {
        width: 100%;
    }
    .apply-form-resume-btn1 {
        width: 50%;
    }
    .apply-form-left {
        width: 100%;
    }
    .header {
        position: relative;
    }
    .notice-period {
        flex-direction: column;
    }
    .last-working {
        margin-left: 0;
        margin-top: 20px;
    }
    .ant-calendar-picker {
        width: 100%;
    }
    .apply-now-btn {
        position: absolute;
        bottom: -12px;
        right: 5%;
        height: 24px;
        width: 76px;
        font-size: 10px;
    }
    .apply-now-job-detail {
        width: 90vw;
    }
    .apply-now-social {
        width: auto;
    }
    .job-role {
        font-size: 18px;
    }
    .apply-now-container .header {
        margin: auto;
        word-break: break-word;
        width: 100%;
        display: block;
    }

    .an-serving-notice-period-value .ant-select-selection-selected-value {
        font-size: 12px;
    }
}
.Company-n-openings-btn {
    margin-top: 18px;
}

.Company-n-openings-btn button {
    width: 100%;
    background: #f7f8fc;
    line-height: 41px;
    border-radius: 4px;
    border: solid 1px #4767a0;
    font-size: 12px;
    font-weight: 600;
    color: #4767a0;
    cursor: pointer;
    margin-bottom: 20px;
}
.Company-n-openings-btn button svg {
    margin-left: 5px;
}

.applynow-opening-icon {
    position: relative;
    top: 1px;
}
