@import '../../Helpers/Stylesheet/colors.scss';

.option {
    margin: 10px 0;

    &__label {
        margin-left: 20px;
        color: $lighter-grey;
        font-weight: normal;
    }
}

.archive-mail-confirm {
    margin-top: 10px;
    span {
        color: $lighter-grey;
    }
}
