.cd-container {
    margin: 0 auto;
    width: 1200px;
}

.cp-container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.cp-header-text {
    color: #4767a0 !important;
    text-align: left !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    font-family: sanFranciscoMedium;
}

.cp-header-container {
    flex-grow: 40;
}

.cp-header-button-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.cp-header-button-container > div {
    margin-left: auto;
}

.header-btn-active {
    color: #4767a0 !important;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1) !important;
    height: 41px !important;
    min-width: 103px !important;
    width: 7.54vw !important;
    border: 2px solid #eee !important;
    border-radius: 6px !important;
    z-index: 20;
}

.header-btn-active:hover {
    color: #4767a0 !important;
}

.cp-cd-status-cont {
    display: flex;
}

.cp-cd-status-cont1 {
    display: flex;
    margin-top: 23px;
}

.cp-cd-status-cont2 {
    display: flex;
    margin-top: 8px;
}

.cp-cd-status {
    height: 23px;
    min-width: 90px;
    width: 6.588vw;
    color: #697998;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
}

.cp-cd-status-label {
    height: 23px;
    min-width: 116px;
    width: 8.49vw;
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    background-color: #a4abbb;
    border-radius: 3px;
}

.bg-white {
    background-color: #ffffff;
}

.cp-cd-status1 {
    min-height: 12px;
    min-width: 90px;
    width: 6.588vw;
    color: #697998;
    font-size: 14px;
    font-weight: 500;
    font-family: sanFranciscoMedium;
    line-height: 18px;
}

.cp-cd-status-label1 {
    min-height: 18px;
    min-width: 116px;
    color: #4767a0;
    font-size: 14px;
    font-weight: 500;
    font-family: sanFranciscoMedium;
    line-height: 18px;
}

.cp-cd-status-label1.flex {
    display: flex;
}

.cp-cd-status-label1 > p {
    max-width: 90px;
    word-break: break-word;
    margin: 0px auto;
    text-transform: capitalize;
}

.cp-cd-status-label2 {
    min-height: 18px;
    min-width: 116px;
    width: 8.49vw;
    color: #4767a0;
    font-size: 14px;
    font-weight: 500;
    font-family: sanFranciscoMedium;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cp-cd-stage-label {
    max-width: 94px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 6px;
    line-height: 2;
}

.cp-cd-stage-icon {
    height: 15px;
    margin: auto 0;
}

.cp-cd-stage-icon > svg {
    height: 15px;
    width: 15px;
}

.cp-action-button-text {
    height: 24px;
    color: #4767a0;
    font-size: 16.8px;
    font-weight: 500;
    line-height: 24px;
    margin: auto auto auto 0 !important;
}

.cp-action-button-icon {
    margin: auto 13px auto 7px !important;
    height: 20px;
    width: 20px;
}

.goto-job-opening-board-btn {
    height: 16px;
    width: 139px;
    color: #94aad0;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    border: 1px solid #94aad0;
    border-radius: 3px;
    margin-top: -12px;
    cursor: pointer;
}

.cp-skills-chip-block {
    margin-left: 5px;
}

.cp-skills-chip-block .ant-tag {
    background-color: #eff0f4;
    border: none;
    border-radius: 2px;
    color: #8a94a6;
    margin: 4px;
}

.cp-card-block {
    display: block;
    width: 100%;
    position: relative;
}

.cp-card-block .cp-avatar-block .ant-avatar .cp-avatarName {
    font-weight: 900;
    color: #ffffff !important;
    font-size: 30px;
}

.cp-card-block .cp-avatar-block .ant-avatar {
    background-color: #4767a0;
    border-radius: 6px;
}

.cp-card-block .ant-badge .ant-badge-count {
    color: #4767a0 !important;
    background-color: #fff;
    border: 1px solid #4767a0;
    height: 29px;
    border-radius: 50%;
    width: 29px;
    padding: 3px 0;
    font-size: 10px;
    top: unset;
    right: 2px;
    bottom: -24px;
    box-shadow: none;
    font-weight: bold;
}

.cp-card-block .cp-avatar-block {
    float: left;
    width: 110px;
}

.cp-card-block .cp-description .cp-card-header {
    font-size: 20px;
    line-height: 32px;
    color: #53627c !important;
}

.cp-card-block .cp-description {
    float: left;
    width: calc(100% - 110px);
}

.cp-card-block .cp-bottom-icon .anticon {
    margin-left: 8px;
    color: #4767a0;
}

.cp-card-block .cp-bottom-icon {
    /*float: right;*/
    /*width: 40px;*/
    position: absolute;
    top: 0;
    right: 0;
}

.cp-profile-block {
    margin-top: -14px;
    margin-bottom: 16px;
}

.cp-profile-block .cp-profile-row {
    display: flex;
    margin: 14px 4px;
    line-height: 10px;
}

.cp-profile-block .cp-profile-row .cp-profile-label {
    width: 150px;
    min-height: 26px;
    color: #4767a0;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    word-wrap: break-word;
}

.cp-profile-block .cp-profile-row .cp-profile-text {
    flex: auto;
    color: #697998;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    word-break: break-all;
    padding-right: 4%;
}

.cp-profile-block .cp-profile-row .cp-profile-text p {
    width: auto;
    margin-left: 5px;
}

.cp-profile-block .cp-profile-row .cp-profile-text a {
    width: auto;
    margin-left: 5px;
    color: #4767a0;
    cursor: pointer;
}

.edit-label {
    margin-top: 7px;
    height: 20px;
    word-wrap: break-word;
}

.profile-ctc {
    height: 31px;
}

.cp-profile-detail-valid {
    display: none;
}

.cp-profile-detail-invalid {
    color: red;
    margin-left: 10px;
    margin-top: 5px;
}

.cp-profile-currency-input {
    display: flex;
    justify-content: space-between;
    height: 26px;
    width: 48%;
}

.cp-profile-currency-input input,
.cp-profile-currency-input .ant-select-selection__rendered {
    color: #697998;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}

.cp-profile-currency-input .ant-form-explain {
    position: relative;
    left: 105%;
    bottom: 30px;
    line-height: 1;
    width: 90%;
    word-wrap: break-word;
}

.cp-profile-block .cp-profile-row .cp-profile-input {
    min-width: 300px;
    height: 26px;
}

.cp-profile-block .cp-profile-row .cp-profile-textarea {
    min-width: 300px;
    height: auto;
}

.cp-profile-block .cp-profile-row .cp-profile-input .ant-form-explain {
    position: relative;
    left: 105%;
    bottom: 30px;
    line-height: 1.3;
    width: 63%;
    word-wrap: break-word;
}

.cp-profile-block .cp-profile-row .cp-profile-input1 {
    min-width: 300px;
    height: 32px;
}

.cp-profile-block .cp-profile-row .cp-profile-input input,
.cp-profile-block .cp-profile-row .cp-profile-input .ant-select-selection__rendered {
    color: #697998;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}
.cp-footer-edit-button {
    text-align: right;
}

.cp-footer-edit-button button {
    width: 120px;
}

.cp-collapse {
    border: none;
    background-color: transparent;
}
.ant-collapse > .ant-collapse-item {
    border: none;
}
.ant-collapse-content {
    border: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 24px;
    line-height: 32px;
    color: #4767a0 !important;
    font-weight: 500;
    padding: 0;
    margin-bottom: 27px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: unset;
    right: 16px;
    font-size: 20px;
    z-index: 998;
}

.display-none {
    display: none;
}

.spin-loader-center1 {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 50px;
}

.full-wide {
    width: 100%;
    position: relative;
}
.new-tab {
    position: absolute;
    right: 14px;
    top: 14px;
    font-size: 12px;
}
.new-tab.right28 {
    right: 28px;
}
.new-tab svg {
    width: 10px;
    height: 10px;
    margin-left: 8px;
}

.an-nn-opt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.an-nn-add {
    color: #4767a0;
    font-family: sanFranciscoMedium;
}

/*feedback*/
.cd-container.Feedback_outer {
    display: flex;
}
.Feedback_outer .Feedback_right-wrapper {
    min-height: 557px;
    flex: auto;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    padding: 40px;
    border-radius: 3px;
}
.Feedback_outer .Feedback_right-wrapper h2 {
    color: #4767a0;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 32px;
}

.feedback_container_title {
    width: 352px;
    height: 32px;
}

.feedback_header-text {
    display: flex;
    justify-content: center;
    height: 30px;
    margin-top: 132px;
    color: #8a94a6;
    font-size: 16px;
    font-weight: 500;
}

.feedback_button {
    display: flex;
    justify-content: center;
}

.feedback-btn {
    height: 50px;
    font-size: 18px;
}

.Feedback_outer .Feedback_right-wrapper .block-wrapper {
    border: 1px solid #4767a0;
    padding: 20px 0;
    display: flex;
    border-radius: 3px;
    margin-bottom: 15px;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .left-block {
    width: 50%;
    padding: 0 15px;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .right-block {
    width: 50%;
    padding: 0 15px;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .left-block .title {
    margin-bottom: 10px;
    display: inline-block;
    color: #53627c;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.rate-block {
    display: flex;
    justify-content: space-between;
    width: 350px;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .rate-block {
    padding-left: 35px;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .rate-block .skills {
    color: #697998;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .right-block ul {
    padding: 0px;
    list-style-type: none;
    display: flex;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .right-block ul li {
    color: #53627c;
    font-size: 12px;
    line-height: 28px;
    position: relative;
    padding: 0 10px;
}

.Feedback_outer .Feedback_right-wrapper .block-wrapper .right-block ul li:first-child {
    padding-left: 0;
}

.Feedback_outer .Feedback_right-wrapper .block-wrapper .right-block ul li:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 5px;
    bottom: 0;
    background: #53627c;
    height: 17px;
    width: 1px;
}

.Feedback_outer .Feedback_right-wrapper .block-wrapper .right-block ul li:last-child:before {
    display: none;
}
.Feedback_outer .Feedback_right-wrapper .block-wrapper .right-block p {
    color: #8a94a6;
    font-style: italic;
    font-size: 18px;
    width: 287px;
    line-height: 22px;
}

.feedback-overall-text {
    color: #8a94a6;
    font-style: italic;
    font-size: 18px;
    min-width: 360px;
    width: 26.354vw;
    line-height: 22px;
}

.Feedback_outer .Message_right-wrapper {
    width: 100%;
    min-height: 557px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    padding: 40px;
    border-radius: 3px;
}
.Feedback_outer .Message_right-wrapper h2 {
    color: #4767a0;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 32px;
}
.Message_container_title {
    width: 352px;
    height: 32px;
    margin-bottom: 39px !important;
}

.Message_right-wrapper .cpm-avatar-block .ant-avatar .cpm-avatarName {
    font-size: 14px;
}

.Message_right-wrapper .cpm-avatar-block .ant-avatar {
    background-color: #4767a0;
    border-radius: 6px;
}

.Message_right-wrapper .cpm-avatar-block {
    float: left;
}

.Message_right-wrapper .cpm-mail-detail .cpm-recruiter-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}
.Message_right-wrapper .cpm-mail-detail .cpm-from-mail {
    font-size: 12px;
    color: #8a94a6;
    margin: 2px;
}

.Message_right-wrapper .cpm-mail-content-block .cpm-mail-label {
    color: #65676e;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}
.Message_right-wrapper .cpm-mail-content-block .cpm-mail-subject {
    margin: 12px 0;
    height: 40px;
}
.Message_right-wrapper .cpm-mail-content-block .cpm-mail-body {
    margin: 12px 0;
    min-height: 205px;
}

.Message_empmty_textInfo {
    margin-top: 132px;
    text-align: center;
}

.Message_header-text {
    /* height: 32px; */
    /* width: 392px; */
    color: #53627c;
    /* font-family: "SF Pro Text"; */
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
}

.Message_sub-header-text {
    height: 96px;
    width: 392px;
    color: #8a94a6;
    /* font-family: "SF Pro Text"; */
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    display: inline-block;
}

.cpm-to-mail-hidden {
    display: none;
}

.cpm-to-mail-active {
    font-size: 12px;
    color: red;
    margin-left: 45px;
}

.attachment-text {
    height: 12px;
    width: 79px;
    color: #a6aebc;
    /* font-family: "SF Pro Text"; */
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
}

.add-message {
    height: 100% !important;
}

.attachment-icon {
    color: #a6aebc;
    /* font-family: "SF Pro Text"; */
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
}

.send,
.cancel {
    height: 45px;
    width: 125px;
}

.send span {
    height: 16px;
    width: 35px;
    color: #ffffff;
    /* font-family: "SF Pro Text";	 */
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.cancel span {
    height: 16px;
    width: 48px;
    color: #4767a0;
    /* font-family: "SF Pro Text";	 */
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.receiver,
.cc {
    height: 16px;
    width: 503px;
}

.receiver {
    margin-left: 21px;
}
.cc {
    margin-left: 20px;
}
.from {
    margin-left: 9px;
}

.cpm-mail-attach-file-block {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cpm-mail-attach-file-block1 {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.cpm-mail-footer-block {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.message-view {
    /*height: 574px;	*/
    /* width: 835px; */
    margin-top: 20px;
    padding: 30px;
    /* border: 1px solid #dddddd; */
    height: auto;
    width: 835px;
}

.cpm-mr-10 {
    margin-right: 10px;
}

.cp-feedback-star .ant-rate-star {
    margin-right: 4px;
}

.cp-feedback-star .ant-rate-star-full g {
    fill: #245c8d;
}
.subject {
    color: #4c4f59;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
}

.main-message-header {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.collapse-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.candidate {
    color: #a3a3a8;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
}

.time {
    color: #4c4f59;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    margin-right: 21px;
    display: flex;
    flex-direction: column;
}
.date {
    height: 14px;
    width: 36px;
    color: #4c4f59;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    margin-left: 15px;
}

.attchIcon {
    margin-left: 10px;
    margin-top: auto;
}

.lockIcon {
    margin-top: auto;
}

.message-exp-icon {
    margin: auto;
}

.date-time {
    display: flex;
}

.ant-collapse {
    border: none;
}

.attach-icon {
    margin-left: 90px;
    height: 12px;
    width: 14px;
    color: #4c4f59;
    /* font-family: "SF Pro Text";	 */
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
}

.view-feddback-text {
    cursor: pointer;
}

.candidate-star > svg > g {
    fill: #4767a0;
}

.new-message-cont {
    padding: 21px 30px;
    border: 1px solid #e1e5e3;
    margin-bottom: 20px;
}

.message-header {
    height: 82px;
    margin-bottom: 20px;
    padding: 20px 30px;
    background-color: #e1e5e3;
}

.message-attachment-btn {
    height: 45px;
    width: 125px;
}

.message-attachment-btn:hover,
.message-attachment-btn:focus {
    background-color: #fff !important;
}

.message-detail-seprator {
    height: 1px;
    border: 1px solid #e1e5e3;
    margin-top: 15px;
    margin-bottom: 25px;
    width: 70%;
    opacity: 0.2;
}

.message-detail-seprator1 {
    height: 1px;
    border: 1px solid #e1e5e3;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 70%;
    opacity: 0.2;
}

.mail-header {
    display: flex;
    justify-content: space-between;
}

.mail-header-new {
    display: flex;
}

.mail-header-detail {
    display: flex;
}

.mail-detail-time {
    height: 15px;
    color: #4c4f59;
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    text-align: right;
    margin-right: 20px;
}

.message-input {
    font-size: 10px;
}

.message-input:focus {
    box-shadow: none;
}

.candidate-message-wrapper {
    flex: auto;
}

.candidate-message-wrapper input:focus,
.candidate-message-wrapper textarea:focus {
    box-shadow: none;
}

.message-files {
    height: 45px;
    display: flex;
    border: 1px solid #f3f3f4;
    min-width: 118px;
    width: 8.638vw;
    padding: 8px 7px;
    margin-right: 10px;
    border-radius: 4px;
    margin-top: 10px;
    position: relative;
}

.message-files .anticon {
    margin: auto;
    margin-right: 6px;
    margin-left: 0;
}

.message-files > div {
    margin-top: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sample-pdf {
    height: 12px;
    opacity: 0.5;
    color: #2e2e2e;
    font-size: 10px;
    line-height: 12px;
}

.message-cursor {
    cursor: pointer;
}

.cpm-mail-content-block .tox.tox-tinymce {
    min-height: 205px;
}

.resume-preview {
    width: 100%;
    min-height: 500px;
}

.cpf-options {
    display: flex;
    justify-content: space-between;
}

.cp-notes-title {
    color: #8a94a6;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
    font-family: sanFranciscoMedium;
    display: flex;
    justify-content: space-between;
}

.cp-internal-notes .ant-typography-ellipsis {
    word-wrap: break-word;
}

.cp-notes-time {
    height: 20px;
    color: #8a94a6;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    font-family: sanFrancisco;
    margin-left: 8px;
}

.cp-notes-icon {
    margin-left: 8px;
}

.cp-notes-icon:first-child {
    margin-left: 0;
}

.cp-notes-icon svg {
    height: 15px;
    width: 15px;
}

.cp-notes-add-btn {
    height: 42px;
    line-height: 42px;
    /* display: flex; */
}

.cp-note-add-icon {
    margin: auto;
}

.cp-note-add-icon svg {
    height: 17px;
    width: 17px;
}

.cpm-cc {
    width: 503px;
    margin-left: 23px;
}

.cpm-cc .ant-select-selection--multiple {
    min-height: 16px;
    padding-bottom: 0 !important;
    padding-top: 1px !important;
}

.cpm-cc .ant-select-selection--multiple:focus,
.cpm-cc .ant-select-selection--multiple:active {
    border-color: #f1f2f4 !important;
    box-shadow: none !important;
}

.cpm-cc .ant-select-selection__choice {
    height: 14px !important;
    margin-top: 1px !important;
    line-height: 12px !important;
    font-size: 9px;
    margin-top: 1px !important;
    color: #4c4f59 !important;
    background-color: #f1f2f4;
}

.cpm-cc .ant-select-selection__rendered {
    height: 16px !important;
    line-height: 14px !important;
    margin-bottom: 0 !important;
}

.cpm-cc .ant-select-search--inline {
    height: 14px !important;
    line-height: 12px !important;
}

.cc-email-options,
.cc-email-dropdown .ant-select-dropdown-menu-item-selected,
.cc-email-dropdown .ant-select-dropdown-menu-item-active {
    height: 26px;
    color: #53627c;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500 !important;
}

.cc-email-options .anticon {
    display: none !important;
}

.cpm-cc-remove-icon svg {
    height: 12px;
    width: 12px;
}

.cpm-mail-content-block .tox-toolbar * {
    color: #53627c !important;
    fill: #53627c !important;
}

.cpm-mark-as-private {
    height: 16px;
    width: 190px;
    color: #a6aebc;
    font-size: 10px;
    line-height: 16px;
    margin-right: 5px;
}

.anticon-lock svg {
    fill: #a6aebc;
}

.cpm-private-cont {
    margin-top: 21px;
}

.cpm-private-message {
    min-height: 18px;
    color: #53627c;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    flex-wrap: wrap;
}

.cpm-panel-close-icon {
    margin-top: auto;
    margin-left: auto;
    display: flex;
}

.no-pointer-event {
    pointer-events: none;
}

.cpm-attachment-icon {
    position: absolute;
    top: -6px;
    right: -10px;
}

.cpm-attachment-icon svg {
    height: 14px;
    width: 14px;
}

.cpm-view-private {
    line-height: 55px;
    margin-left: auto;
    margin-right: 10px;
}

.cp-copy-icon {
    height: 15px;
    position: relative;
    left: 6%;
}

.cp-copy-icon svg {
    width: 12px;
    height: 15px;
}

.cp-whatsapp-icon {
    height: 20px;
    margin-top: -3px;
    margin-left: 3px;
}

.cp-whatsapp-icon svg {
    width: 16px;
    height: 20px;
}

.no-resume-text {
    height: 22px;
    width: 794px;
    color: #8a94a6;
    font-size: 18px;
    line-height: 22px;
}

.cp-update-resume-btn {
    transform: translateY(-20%);
    position: relative;
    right: 5vw;
}

.cp-stage-move-title {
    height: 20px;
    width: 116px;
    color: #4767a0;
    font-size: 10px;
    line-height: 20px;
}

.cp-stage-move-option {
    height: 34px !important;
    width: 158px !important;
    color: #53627c;
    font-size: 11px !important;
    line-height: 34px !important;
    border-bottom: 1px solid #eee;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-family: sanFranciscoMedium;
}

.cp-cd-stage-move {
    width: 158px;
}

.cp-cd-stage-move .ant-popover-title {
    border-bottom: 1px solid #eee;
}

.cp-cd-stage-move .ant-popover-inner-content {
    padding: 0;
    border: 0;
}

.cp-cd-stage-move .ant-menu-item-selected {
    background-color: #f4f6fa !important;
}

.cp-cd-stage-move .ant-menu-item-active {
    background-color: #e1e2eb !important;
}

.cp-ctc-padding {
    padding-left: 140px;
    flex-direction: column;
}

.cp-ctc-user {
    min-height: 20px;
    color: #53627c;
    font-size: 10px;
    line-height: 20px;
    margin-top: 12px;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.add-more-people-who {
    height: 16px;
    color: #4767a0;
    font-size: 10px;
    line-height: 16px;
    cursor: pointer;
}

.left-margin-10 {
    margin-left: 10px;
}

.an-nn-sel-user1 {
    height: 16px;
    line-height: 16px;
    padding: 0 5px;
    min-width: 10px;
    border-radius: 3px;
    background-color: #f1f2f4;
    display: flex;
    justify-content: space-between;
}

.an-nn-sel-user1 .anticon {
    margin: auto 0;
    width: 16px;
}

.an-nn-sel-user1 svg {
    height: 12px;
}

.an-nn-sel-user2 {
    height: 16px;
    line-height: 16px;
    padding: 0 5px;
    min-width: 10px;
    border-radius: 3px;
    background-color: #f1f2f4;
    display: inline-flex;
}

.an-nn-sel-user2 .anticon {
    margin: auto 0;
    width: 16px;
}

.an-nn-sel-user2 svg {
    height: 12px;
}

.top-margin-7 {
    margin-top: 7px;
}

.cp-note-mb {
    margin-bottom: 20px;
}

.cp-action-btn {
    height: 40px;
    width: 7.467vw;
    min-width: 102px;
    margin-left: 20px !important;
}

.associate-job-btn,
.associate-job-btn:hover,
.associate-job-btn:focus {
    background-color: #fff !important;
    color: #4767a0 !important;
    border-color: #4767a0;
}

.associate-job-btn .button-text {
    white-space: normal;
    font-size: 13px;
}

.cp-options {
    padding: 0 16px;
    font-size: 12px !important;
    height: 28px !important;
    line-height: 26px !important;
    min-width: 134px;
    font-family: sanFranciscoMedium;
    border-bottom: 1px solid #f4f5fa;
    margin-bottom: 0 !important;
}

.cp-options:last-child {
    border-bottom: none;
    margin-bottom: 4px !important;
}

.cp-option-color {
    color: #545e7c;
    font-size: 10px;
}

.cp-option-cont {
    border-right: 0;
}

.cp-status-icon {
    margin-left: 8px;
}

.cp-status-icon * {
    fill: #fff;
    height: 12px;
    width: 12px;
}

.cp-source {
    position: absolute;
    right: 135px;
    height: 24px;
    line-height: 26px;
    min-width: 20px;
    border-radius: 3.43px;
    background-color: #a6aebc;
    font-size: 11px;
    color: #fff;
    padding: 0 8px;
    margin-top: 4px;
}

.cp-source > span {
    font-weight: 600;
}

.cp-source > .anticon {
    margin-left: 5px;
    display: none;
}

.cp-source:hover > .anticon {
    display: inline !important;
}

.cp-source svg {
    height: 11px;
    width: 11px;
}

.cp-source * {
    fill: #fff !important;
}

.cpf-edit-icon svg {
    margin-right: 30px;
    height: 28px;
    margin-left: 30px;
}
.avf-title {
    color: #53627c;
    width: 45%;
    height: 28px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.avf-subtitle-cont {
    height: 28px;
    width: 55%;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.avf-skills {
    height: 22px;
    width: 150px;
    color: #697998;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
}

.avf-feedback-star .ant-rate-star {
    margin-right: 4px;
}

.avf-feedback-star .anticon svg {
    width: 17px;
    height: 17px;
}

.avf-feedback-star .ant-rate-star-full g {
    fill: #27628c;
}

.avf-rate-block {
    height: 22.4px;
    line-height: 16px;
    justify-content: space-around;
    display: flex;
}

.avf-stagename {
    margin-left: auto;
    margin-right: 8px;
    white-space: nowrap;
    max-width: 30%;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.avf-jobtitle {
    white-space: nowrap;
    margin-right: 8px;
    margin-left: 8px;
    max-width: 65%;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.avf-date {
    display: flex;
    margin-right: 10px;
    margin-left: 8px;
    text-align: center;
}

.avf-overallfeedback {
    min-height: 72.8px;
    color: #8a94a6;
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    word-wrap: break-word;
    white-space: pre-line;
}

.avf-edit {
    position: relative;
    top: 50%;
    margin-left: 35px;
}

.avf-edit i {
    margin-right: 5px;
}

.avf-edit svg {
    height: 14px;
    width: 14px;
}

.avf-subtitle-cont1 {
    display: flex;
    justify-content: space-between;
    width: 217px;
}
.block-feedback-list {
    width: 100%;
}

.avf-header-container {
    display: flex;
    align-content: space-between;
    margin-bottom: 10px;
    padding-left: 2%;
}

.avf-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.avf-content-feedback {
    width: 40%;
    margin-left: 3.5%;
}

.avf-content-rating {
    width: 40%;
    margin-left: 2%;
}

.avf-content-opinion {
    display: flex;
    margin-right: 1.5%;
    flex-direction: column;
    width: 10%; /*just dummy width to cover the rest */
    height: 100px;
    justify-content: center;
    align-items: center;
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    color: #4767a0;
}
.avf-content-opinion-icon {
    width: 48px;
    height: 48px;
}

.cp-whats-app-cont {
    height: 20px;
    border-radius: 2.4px;
    font-family: sanFranciscoMedium;
    color: #4767a0;
    font-size: 9px;
    line-height: 20px;
    display: flex;
    margin-right: auto;
    margin-left: 8px;
    cursor: pointer;
}

.cp-no-candidate > div {
    height: calc(100vh - 56px);
}

.cp-resume-options {
    background-color: #ffffff;
    border: none;
    box-shadow: none;
}

.zero-margin {
    margin: 0;
}

.cp-serving-notice-period {
    padding: 5px 12px;
    cursor: pointer;
}

.cp-serving-notice-period:hover {
    background-color: #d3dae0;
}

.cp-serving-notice-period-value .ant-select-selection-selected-value {
    font-size: 12px;
}

.cp-np-dp {
    left: 48% !important;
    top: 300px !important;
}

.cp-np-dp .ant-calendar {
    width: 305px;
}

.cp-form-dp input {
    height: 32px;
    min-width: 300px;
}

.flex-column {
    flex-direction: column;
}

.openings-tag {
    height: 16px;
    width: 90px;
    color: #8a94a6;
    font-size: 8px;
    font-weight: 600;
    line-height: 8px;
    text-align: center;
    border: 1px solid #eff0f4;
    border-radius: 2px;
    background: #f1f1f4;
    padding: 2px 4px;
    margin-left: 10px;
}

.note-delete-btn {
    margin: 1px 0 0 10px;
}

.cp-tm-search {
    width: 55%;
    margin-left: 10px;
}

.an-nn-search {
    width: 35%;
}

.via-stp {
    width: 180px;
    height: 30px;
    margin-top: 15px;
    padding-top: 5px;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.29);
}
.via-stp svg {
    color: #999999;
    width: 14px;
    margin-left: 10px;
    height: 14px;
}
.via-stp span {
    font-size: 12px;
    margin-left: 5px;
    color: #999999;
    line-height: 12px;
    font-weight: bold;
}
.ml-auto {
    margin-left: auto;
}

@media only screen and (max-width: 769px) {
    .cp-header-button-container {
        display: block;
    }
    .cp-header-button-container .ant-btn-group {
        width: 100%;
    }
    .cp-header-button-container .ant-btn-group button {
        width: 100% !important;
        min-width: unset !important;
        margin: 4px 0;
    }
    #actions-button {
        width: 100%;
        margin-left: unset !important;
        margin-top: 4px;
    }
    .Feedback_outer {
        display: block;
        padding: 0 15px;
    }
    .cp-cd-status-label1 > p {
        max-width: unset;
    }
    .cp-profile-block .cp-profile-row .cp-profile-text {
        width: 100%;
        padding: 0;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
        width: -webkit-fill-available;
    }
    .cp-card-block .cp-avatar-block {
        float: unset;
        width: 100%;
    }
    .cp-avatar-block span span {
        border-radius: 50% !important;
        margin-bottom: 30px;
    }
    .cp-card-block .cp-description {
        float: unset;
        width: 100%;
    }
    .cp-profile-block .cp-profile-row {
        margin: 0 0 12px 0;
    }
    .cp-profile-block .cp-profile-row .cp-profile-label,
    .cp-profile-text p {
        font-size: 14px;
    }
    .cp-profile-block .cp-profile-row {
        display: block;
    }
    .cp-profile-text p {
        margin-left: 0 !important;
        color: #656565;
        font-weight: bold;
        font-size: 16px;
    }
    .cp-profile-block .cp-profile-row .cp-profile-label {
        width: 100%;
        margin: 0;
        min-height: unset;
        margin-bottom: 8px;
        color: #697998;
    }
    .cp-profile-block .cp-profile-row .cp-profile-input {
        min-width: unset;
        height: unset;
    }
    .ant-form-item {
        margin-bottom: 20px;
        width: 100%;
    }
    .cp-profile-currency-input {
        width: 100%;
        height: unset;
    }
    .cp-profile-block .cp-profile-row .cp-profile-textarea {
        min-width: unset;
    }
    .cp-notes-add-btn {
        margin: auto;
    }
    .cp-header-container .cp-header-text {
        margin-bottom: -16px;
    }
    .Feedback_right-wrapper {
        padding: 15px !important;
        min-height: unset !important;
    }
    .feedback_header-text {
        margin-top: 0;
        height: unset;
        margin-bottom: 20px;
    }
    .feedback_container_title {
        width: 100%;
    }
    .Message_right-wrapper,
    .new-message-cont {
        padding: 15px !important;
    }
    .mail-header-detail {
        display: block;
    }
    .Message_container_title {
        width: 100%;
        height: unset;
        margin-bottom: 12px !important;
    }
    .Message_right-wrapper .cpm-avatar-block {
        float: unset;
        margin: auto;
        text-align: center;
        margin-bottom: 10px;
    }
    .Message_right-wrapper .cpm-mail-detail .cpm-recruiter-name {
        text-transform: capitalize;
    }
    .message-detail-seprator {
        width: 100%;
    }
    .mail-header {
        display: block;
    }
    .mail-header-detail {
        margin-bottom: 10px;
    }
    .mail-detail-time {
        font-size: 10px;
        margin-right: 0;
    }
    .mail-detail-time-parent {
        align-content: center;
        align-items: center;
        justify-content: space-between;
    }
    .message-header {
        height: unset;
        padding: 15px;
    }
    .main-message-header {
        display: block;
    }
    .subject,
    .collapse-title {
        margin-bottom: 4px;
        font-size: 10px;
        line-height: 14px;
    }
    .candidate {
        font-size: 10px;
    }
    .date-time .time {
        font-size: 10px;
        width: 100%;
    }
    .cpm-mail-detail p {
        text-align: center;
    }
    .mail-header-new {
        display: block;
    }
    .receiver,
    .cc {
        margin-left: 0;
        width: 100%;
        height: unset;
    }
    .cpm-cc {
        text-align: center;
        width: 100%;
        margin-left: 0;
    }
    .Message_right-wrapper .cpm-mail-detail .cpm-from-mail {
        text-align: center;
        margin: 4px 0 0 0;
    }
    .Message_right-wrapper .cpm-mail-content-block .cpm-mail-subject {
        font-size: 12px;
    }
    .message-attachment-btn {
        width: 100%;
    }
    .cpm-mail-footer-block {
        display: block;
        margin-top: 10px;
    }
    .send,
    .cancel {
        width: 100%;
        height: unset;
        line-height: 30px;
        margin: 4px 0;
    }
    .ant-upload.ant-upload-select {
        width: 100%;
    }
    .cpm-mail-attach-file-block {
        display: block;
        flex-wrap: unset;
        width: 100%;
    }
    .ant-checkbox-wrapper {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .ant-modal-body {
        padding: 15px;
    }
    .dash-opening-container {
        display: block !important;
    }
    .create-job-opening {
        margin-bottom: 10px;
        width: 100%;
    }
    .job-static-text {
        width: 100%;
        height: unset;
        font-size: 20px;
        letter-spacing: unset;
        font-weight: bold;
    }
    .job-opening-form-tab {
        display: block;
        height: unset;
    }
    .job-opening-form-tab button {
        width: 100% !important;
        margin: 0 0 10px 0;
        letter-spacing: 0;
    }
    .dash-opening-container {
        padding-top: 15px;
    }
    .job-detail-container {
        margin-top: 30px;
    }
    .job-detail-form {
        margin: 0;
        padding: 15px;
    }
    .job-detail-form-cell {
        display: inline-block;
        margin-top: 0 !important;
        margin: 10px 0;
    }
    .job-detail-form-left {
        margin: 0 0 16px 0;
    }
    .job-detail-form-right {
        display: block;
    }
    .job-form-cmp-cnt {
        margin-bottom: 0;
        display: block !important;
        height: unset !important;
    }
    .job-form-compensation-status {
        margin-top: 10px;
    }
    .job-form-cmp-cnt .ant-form-item-control {
        line-height: 12px !important;
        margin-bottom: 10px;
    }
    .save-buttons button {
        width: 100% !important;
        margin-right: 0 !important;
    }
    .cpm-from-mail span {
        margin: 0;
        word-break: break-all;
        display: block;
    }
    .cpm-from-mail {
        margin: 8px 0 0 0;
    }
    .cpm-from-mail input {
        text-align: center;
    }
    .cpm-from-mail div {
        text-align: center;
    }
    #templateBody {
        padding-top: 0 !important;
    }
    .Feedback_outer .Message_right-wrapper {
        min-height: unset;
    }
    .mcnTextContent {
        padding: 0 !important;
        padding-left: 0 !important;
    }
    .candidate-profile-source-parent > div > div > div {
        float: unset !important;
    }
    .candidate-profile-source {
        position: unset;
        width: 100%;
        height: unset;
    }
    .candidate-source-panel .ant-collapse-header .ant-collapse-arrow {
        right: 0 !important;
        top: 20px !important;
    }
    .cp-update-resume-btn button {
        right: 36px;
    }
    .Feedback_outer .Feedback_right-wrapper .block-wrapper {
        display: block;
        border: 0;
        padding: 0;
    }
    .Feedback_right-wrapper {
        position: relative;
    }
    .avf-header-container {
        display: block;
    }
    .avf-header-container .avf-title {
        width: 100%;
        margin-bottom: 0;
    }
    .avf-header-container .avf-subtitle-cont {
        width: 100%;
        display: block;
        height: unset;
        position: relative;
    }
    .cpf-edit-icon {
        position: absolute;
        top: 12px;
        right: -16px;
    }
    .avf-content-wrapper {
        display: block;
    }
    .avf-content-feedback {
        width: 100%;
        margin-left: 0;
    }
    .avf-content-rating {
        width: 100%;
        margin-left: 0;
        margin: -40px 0 20px 0;
    }
    .avf-rate-block .avf-skills {
        font-size: 14px;
    }
    .avf-overallfeedback {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 4px;
    }
    .avf-stagename-divider {
        display: none;
    }
    .avf-subtitle-cont .avf-stagename {
        max-width: unset;
        width: 100%;
    }
    .avf-stagename {
        text-align: left;
    }
    .avf-jobtitle {
        margin-right: 0;
        margin-left: 0;
        max-width: unset;
        text-align: left;
        white-space: unset;
    }
    .avf-date {
        display: flex;
        margin-right: 0;
        margin-left: 0;
        text-align: left;
    }
    .avf-content-opinion {
        width: 100%;
    }
    .ant-checkbox-wrapper {
        text-align: left;
        margin-bottom: 8px;
    }
    .cp-notes-icon {
        margin-left: 0;
        margin-top: 4px;
    }
    .no-resume-text {
        height: auto;
        width: 100%;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .cp-ctc-padding {
        padding-left: 0;
    }
    .cp-ctc-user {
        min-height: unset;
        font-size: 12px;
        line-height: 18px;
    }
    .add-more-people-who {
        height: unset;
        font-size: 12px;
        line-height: 18px;
        height: unset;
    }
    .cp-internal-notes .ant-typography-ellipsis,
    .cp-notes-time,
    .cp-notes-title {
        font-size: 12px;
    }
    .cp-internal-notes .ant-typography-ellipsis {
        margin-top: 4px;
    }
}
