.candidate-card > .ant-card-body {
    padding: 8px 12px;
}

.candidate-card > .ant-card-head {
    padding: 0 16px;
}

.candidate-card .ant-card-head-title {
    padding: 13px 0 4px;
}

.cd-card-meta .ant-card-meta-title {
    font-size: 8px;
}

.card-options {
    padding: 0 16px;
    font-size: 12px !important;
    height: 32px !important;
    line-height: 32px !important;
    min-width: 150px;
    font-family: sanFranciscoMedium;
    border-bottom: 1px solid #f4f5fa;
    margin-bottom: 0 !important;
}

.card-options:last-child {
    border-bottom: none;
    margin-top: 0px;
}

.card-icons {
    margin: auto 3px;
}

.card-avatar {
    margin-right: 10px;
    cursor: pointer;
}

.card-avatar-bg {
    background-image: linear-gradient(#2e68ff, #00c7ff);
}

.card-head-cont {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.display-flex {
    display: flex;
}

.card-option-color {
    color: #545e7c;
}

.card-option-cont {
    border-right: 0;
}

.card-option-icon {
    margin: auto;
    cursor: pointer;
    height: 10px;
}

.card-title-cont {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.cd-card-titlle {
    color: #0a1f44;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
}

.cd-card-titlle:hover {
    cursor: pointer !important;
}

.cd-card-meta {
    padding: 0;
}

.card-subtitle {
    color: #8a94a6;
    line-height: 16px;
    height: 16px;
    font-size: 12px;
}

.candidate-card {
    border-radius: 4px;
    margin-bottom: 16px;
    border-color: rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    padding: 0 !important;
}

.candidate-card-meta {
    color: #8a94a6;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    font-family: sanFranciscoMedium;
}

.meta-cont {
    margin-top: 8px;
    display: flex;
}

.meta-cont > * {
    margin-right: 5px;
}

.card-coordinator-icon {
    height: 13px;
    width: 13px;
    color: #ffffff;
    font-size: 9.6px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    background-color: #697398;
    border-radius: 3px;
    margin: 2px 2px 0;
}

.card-assignedTo-icon {
    height: 13px;
    width: 13px;
    color: #ffffff;
    font-size: 9.6px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    background-color: #00c7ff;
    border-radius: 3px;
    margin: 2px 4px 0 2px;
}

.is-rejected {
    pointer-events: none;
}

.star-candidate > svg > g,
.is-rejected > svg > g {
    fill: #697998 !important;
}

.card-pointer {
    cursor: pointer;
}

.ats-card-highlight {
    border: 2px solid #4767a0;
}

.cd-card-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 125px;
    cursor: pointer;
}

.ats-card-disable {
    pointer-events: none;
}

.ats-assign-tm-search {
    height: 32px;
    width: 233px;
    border-radius: 3px;
    background-color: #f1f2f4;
    margin: 10px auto;
    color: #53627c;
    font-size: 12px;
}

.ats-assign-tm-search div {
    height: 32px;
}

.ats-assign-tm-search > div {
    background: #f1f2f4;
    border: none;
}

.ats-assign-tm-search .anticon {
    font-size: 16px;
}

.ats-assign-tm-search svg {
    transform: none !important;
}

/* Customize the label (the container) */
.ats-card-cb-cont {
    display: flex;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
    height: 16px;
    margin-left: auto;
}

/* Hide the browser's default checkbox */
.ats-card-cb-cont input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.ats-card-cb-ch {
    position: absolute;
    top: 2px;
    right: 0;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.ats-card-cb-cont:hover input ~ .ats-card-cb-ch {
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.ats-card-cb-cont input:checked ~ .ats-card-cb-ch {
    background-color: #697398;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* Create the filter-checkmark/indicator (hidden when not checked) */
.ats-card-cb-ch:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the filter-checkmark when checked */
.ats-card-cb-cont input:checked ~ .ats-card-cb-ch:after {
    display: block;
}

/* Style the filter-checkmark/indicator */
.ats-card-cb-cont .ats-card-cb-ch:after {
    left: 2px;
    top: -1px;
    width: 4px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
