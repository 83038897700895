.ant-popover-title {
    border-bottom: 2px solid #e8e8e8;
    padding: 8px 16px;
}

.ant-popover-inner-content {
    padding: 0;
}

.ant-menu-item-active {
    background-color: #f4f5fa;
}

.column-card-count {
    background-color: #697398;
    color: #fff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    padding: 0;
    line-height: 23px;
    font-size: 15px;
    box-shadow: none;
}

.sort-by-popover {
    color: #858da2;
    line-height: 2;
    font-family: sanFranciscoMedium;
    font-size: 12px;
}

.sort-by-popover-item {
    border-bottom: 1px solid #f4f5fa;
}

.sort-by-popover-item:last-child {
    border-bottom: none;
}

.sort-by-popover-option {
    color: #545e7c;
    font-family: sanFranciscoMedium;
}

.sort-by-icon {
    font-size: inherit;
    margin-right: 8px;
}

.column-drag-icon {
    font-size: inherit;
}

.border-right-0 {
    border-right: 0;
}

.column-title {
    font-size: 14px;
    line-height: 20px;
    font-family: sanFranciscoMedium;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.modal-preview-title:hover .column-title-btn {
    display: flex !important;
}

.column-title-btn {
    display: none;
}

.column-edit-icon {
    font-size: 16px;
    margin-left: 8px;
}

.column-title-preview {
    align-items: center;
}

.column-title-no-candidate {
    align-items: center;
    margin-left: 30%;
    margin-bottom: 7%;
}

.modal-preview-title {
    width: 170px;
    display: flex;
    justify-content: center;
}

.ats-skeleton {
    border-radius: 4px;
    margin-bottom: 16px;
    border-color: rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    padding: 13px;
    background-color: #fff;
    width: 245px;
}

/* Customize the label (the container) */
.col-cb-cont {
    display: flex;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
}

/* Hide the browser's default checkbox */
.col-cb-cont input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.col-cb-ch {
    position: absolute;
    top: 0;
    right: 6px;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.col-cb-cont:hover input ~ .col-cb-ch {
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.col-cb-cont input:checked ~ .col-cb-ch {
    background-color: #697398;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* Create the filter-checkmark/indicator (hidden when not checked) */
.col-cb-ch:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the filter-checkmark when checked */
.col-cb-cont input:checked ~ .col-cb-ch:after {
    display: block;
}

/* Style the filter-checkmark/indicator */
.col-cb-cont .col-cb-ch:after {
    left: 2px;
    top: -1px;
    width: 4px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.column-select-all-cont {
    height: 16px;
    line-height: 16px;
    color: #8a94a6;
    font-size: 10px;
}

.col-cb-select-all-label {
    margin-left: auto;
    margin-right: 23px;
    line-height: 12px;
}

.ats-col-filters svg {
    height: 18px;
    width: 20px;
    fill: #697998;
}

.ats-col-popover-item:first-child {
    margin-top: 4px !important;
}

.ats-col-popover-item {
    height: 34px !important;
    line-height: 32px !important;
    width: 156px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.ats-col-submenu-item .ant-menu-submenu-title {
    height: 30px !important;
    line-height: 24px !important;
    width: 156px;
    margin-bottom: 0 !important;
}

.ats-col-popover-option {
    color: #53627c;
    font-size: 12px;
}

.black-black-base-display {
    width: 59px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ats-column-container {
    padding-top: 10px;
}

.ats-column-empty-container {
    padding-top: 38px;
    height: 65vh;
}

.ats-col-action-btn,
.ats-col-action-btn:hover,
.ats-col-action-btn:focus,
.ats-col-action-btn:active {
    height: 18px;
    width: 67px;
    border-radius: 2px;
    background-color: #dddee8 !important;
    margin-right: 6px;
    color: #0a1f44 !important;
    font-size: 10px;
    padding: 0 !important;
    border: none !important;
}

.ats-col-test-action-btn,
.ats-col-test-action-btn:hover,
.ats-col-test-action-btn:focus,
.ats-col-test-action-btn:active {
    height: 18px;
    width: 50px;
    border-radius: 2px;
    background-color: #dddee8 !important;
    margin-right: 6px;
    margin-left: 5px;
    color: #0a1f44 !important;
    font-size: 10px;
    padding: 0 !important;
    border: none !important;
}

.ats-action-cancel {
    font-size: 12px;
    color: #ffffff;
    font-family: sanFranciscoMedium;
    font-style: normal;
    cursor: pointer;
}

.black-black-base-900 {
    height: 18px;
    width: 87px;
    border: 0.5px solid #d9dbe5;
    border-radius: 2px;
    color: #d3d5e0;
    font-size: 10px;
    line-height: 16px;
    margin-right: 5px;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.board-column:hover .column-nocandidate {
    display: inline;
}

.column-nocandidate {
    display: none;
}

.ats-col-test-down-icon {
    margin: auto 0;
    margin-left: 4px;
}

.ats-assign-stage > span {
    display: flex;
    justify-content: space-between;
}

.ats-multiple-text {
    max-width: 102px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ats-multiple-pop-option:last-child {
    margin-bottom: 4px !important;
}

.highlight-stage {
    animation-name: hideBorder;
    animation-duration: 3s;
    animation-timing-function: steps(1, end);
}

@keyframes hideBorder {
    0% {
        border: 2px solid #4767a0;
    }

    100% {
        border: none;
    }
}

.doselect-all-test .ant-popover-inner-content {
    max-height: 210px;
    overflow-y: auto;
}

@media only screen and (max-width: 995px) {
    .ats-column-container {
        padding-top: 10px;
    }
}
