.ant-popover-inner-content {
    padding: 0;
}

.stage-filter {
    padding: 0 8px !important;
    font-size: 12px !important;
    height: 30px !important;
    line-height: 30px !important;
    min-width: 120px;
}

.stage-filter1 {
    padding: 0 8px !important;
    font-size: 12px !important;
    height: 30px !important;
    line-height: 30px !important;
    min-width: 250px;
}

.stage-filter-individual {
    padding: 0 8px !important;
    font-size: 12px !important;
    height: 30px !important;
    line-height: 30px !important;
    width: 130px !important;
}

.sort-filter {
    font-size: 13px;
    color: rgb(137, 144, 165);
    padding: 0px 16px;
    width: 205px;
    height: 28px;
    line-height: 28px;
    font-weight: 600;
    text-align: left;
    display: flex;
}

.sort-filter:hover,
.sort-filter:focus {
    width: 275px;
    background-color: #fff !important;
    color: rgb(137, 144, 165) !important;
}

.filter-icon {
    margin: auto 0 auto auto !important;
}

/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: auto;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 12px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: transparent;
    border: 3px solid #8a94a6;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: transparent;
    border: 3px solid #8a94a6;
    border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #8a94a6;
    border: 3px solid #8a94a6;
    border-radius: 3px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 3px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(42deg);
}

.sort-filter-name {
    margin-left: 10px;
}

/* Customize the label (the container) */
.filter-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.filter-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark2 {
    position: absolute;
    top: 8px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.filter-container:hover input ~ .checkmark2 {
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.filter-container input:checked ~ .checkmark2 {
    background-color: #697398;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* Create the filter-checkmark/indicator (hidden when not checked) */
.checkmark2:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the filter-checkmark when checked */
.filter-container input:checked ~ .checkmark2:after {
    display: block;
}

/* Style the filter-checkmark/indicator */
.filter-container .checkmark2:after {
    left: 3px;
    top: -1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.view-job-detail {
    background-color: rgb(173, 182, 196);
    margin: auto 10px;
    line-height: 12px;
    width: 77px;
    height: 14px;
    font-size: 8px;
    font-family: sanFranciscoMedium;
    border-radius: 2px;
    color: #fff;
}

.candidate-list-header {
    width: 72%;
    display: flex;
    flex-direction: column;
}

.title-cont {
    width: 80%;
    display: flex;
    flex-direction: row;
}

.title-text {
    color: #4767a0 !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    height: 32px !important;
    margin-bottom: 6px !important;
    text-align: left !important;
    max-width: 80% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title-subtitle {
    color: #53627c !important;
    margin-top: 0 !important;
    letter-spacing: 0.29px !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.list-display-flex {
    display: flex;
    width: 1200px;
    margin: auto;
}

.filter-title {
    font-family: sanFranciscoMedium;
    color: #545e7c !important;
    margin: 0 0 8px !important;
    line-height: 28px !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.filter-cd-title {
    margin-left: 20px;
    font-family: sanFranciscoMedium;
    color: #545e7c !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.filter-cd-count {
    margin: auto 0 auto auto;
}

.move-to-stage-btn {
    font-size: 13px;
    margin-right: 10px;
    width: 154px;
    height: 28px;
    background-color: #f1f1f4;
    border: none;
    color: #8990a5;
    padding: 0 16px;
}

.move-to-stage-btn:hover,
.move-to-stage-btn:focus,
.move-to-stage-btn:active {
    background-color: #f1f1f4 !important;
    color: #8990a5 !important;
}

.sort-popover {
    width: 275px;
}

.sort-popover-menu {
    border-right: 0;
}

.sort-popover-menu-option {
    color: #545e7c;
}

.cd-list-wrapper {
    margin-top: 15px;
}

.disable-btn {
    pointer-events: none;
}

.can-stage-detail {
    cursor: pointer;
}

.can-stage-detail:hover .can-stage-hover {
    display: inline !important;
}

.can-stage-hover {
    display: none;
    margin-left: 4px;
}

.candidates-list-container {
    padding: 30px 0px;
}

@media only screen and (max-width: 995px) {
    .candidates-list-container {
        padding: 0 15px;
        width: 100%;
    }
    .candidate-list-header {
        width: 100%;
    }
    .title-cont {
        display: block;
        flex-direction: column;
        width: 100%;
    }
    .title-cont h3 {
        margin-bottom: 0;
        text-transform: capitalize;
        font-size: 20px !important;
    }
    .title-text {
        max-width: 100% !important;
        height: unset !important;
        margin-bottom: 2px !important;
    }
    .view-job-detail {
        width: 100%;
        margin: auto;
        border: 0;
        height: unset;
        line-height: 16px;
        margin-bottom: 12px;
        font-size: 8px;
    }
    .title-subtitle {
        margin-bottom: 6px !important;
    }
    .list-display-flex {
        display: block;
        min-width: unset;
        width: 100%;
    }
    .filter-container {
        width: 10% !important;
    }
    .cd-list-wrapper {
        min-width: unset;
        width: 100%;
        margin-top: 0;
    }
    .sort-filter {
        min-width: unset;
        width: 100%;
    }
    .sort-filter > div {
        width: 100%;
    }
    .move-to-stage-btn {
        margin-right: 0;
        margin-bottom: 8px;
    }
}
