.career-title {
    margin-bottom: 10px;
    display: block;
    font-family: sanFranciscoMedium;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding: 4% 0 0 4%;
}

.carrer-cont-title {
    margin-bottom: 10px;
    display: block;
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
}

.benefit-title .ant-form-explain {
    margin-bottom: 0px;
}

.card-container {
    display: flex;
    flex-direction: column;
}

.career-icons {
    margin: 6px 0 0 6px;
}

.career-loader {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.career-loader-page {
    background-color: #f7f8fc;
}

.no-header-text {
    height: 20px;
    width: 75%;
    object-fit: contain;
    color: #8a94a6;
    font-size: 14px;
    line-height: 22px;
    margin: auto 3%;
    display: flex;
    flex-direction: row;
}

.no-header-btn {
    position: relative;
    top: 5px;
    right: 10px;
}

.career-header-link {
    color: #4767a0;
    margin-bottom: 15px;
}

.career-header-close {
    margin: 1.5px 0 0 2px;
    color: #4767a0;
}

.carrer-cont-btn-single {
    margin: 0 5px 0 0;
}

.career-section-icons {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px #4767a0;
    margin-right: 5px;
}

.career-cont-btn,
.career-save-cancel-container {
    float: right;
    display: flex;
    flex-direction: row;
}

.career-up-arrow {
    width: 5.8px;
    height: 9.9px;
    transform: rotate(90deg);
    background-color: #4767a0;
}

.career-culture-btn {
    position: absolute;
    right: 2%;
    top: -5px;
    color: #ffffff;
    background-color: #8a94a6;
}

.career-culture-text {
    height: 20px;
    width: 200px;
    object-fit: contain;
    color: #8a94a6;
    font-size: 14px;
    line-height: 22px;
    margin: 2% 0 0 5%;
}

.career-header-btn {
    color: #ffffff;
    background-color: darkgray;
}

.no-banner-btn {
    position: relative;
    top: 5vh;
    left: 19vw;
}

.career-banner-btn {
    background-color: #ffffff;
    color: #4767a0;
}

.career-btns {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 65px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    justify-content: flex-end;
    padding-right: 2.3%;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
}

.career-button-float-container {
    display: flex;
    flex-direction: row;
}

.career-btn-individual {
    margin-right: 10px;
    text-align: center;
}

.career-footer-btn {
    width: 130px;
    height: 36px;
    font-size: 14px;
}

.career-img-tooltip {
    margin-left: 5px;
}

.career-tooltip {
    font-size: 14px;
}

.career-upload {
    border-style: dashed;
    border-width: 2px;
    border-color: darkgray;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.career-opening-info {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #666666;
}

.upload-list-inline-company {
}

.upload-list-inline-company .ant-upload-list-item {
    position: relative;
    bottom: 5vh;
    left: 10vw;
}

.ant-upload-list-company ant-upload-list-picture {
    margin-top: 80px;
    margin-left: 100px;
    background-color: #666666;
}

.upload-list-inline-company .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
}

.upload-list-inline-company .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
}

.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 150px;
    margin-right: 10px;
    margin-top: 20px;
}

.ant-upload-list ant-upload-list-picture {
    margin-top: 80px;
}

.upload-list-inline .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
}

.upload-list-inline .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
}

.company-logo-upload {
    display: inline-block;
}

.upload-list-inline-banner .ant-upload-list-item {
    width: 400px;
}
.upload-list-inline-banner .ant-upload-animate-enter {
    animation-name: antMoveUpIn;
}
.upload-list-inline-banner .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
}

.career-page-transition-appear {
    opacity: 0.3;
}

.career-page-transition-appear.career-page-transition-appear-active {
    opacity: 1;
    transition: opacity 0.8s ease;
}

.career-page-transition-enter {
    opacity: 0.01;
    transform: scaleX(0) translateY(-1000px);
}

.career-page-transition-enter.career-page-transition-enter-active {
    opacity: 1;
    transform: scaleX(1) translateY(0);
    transition: all 500ms ease-in-out;
}

.career-page-transition-leave {
    opacity: 1;
    transform: scaleX(1) translateX(0);
}

.career-page-transition-leave.career-page-transition-leave-active {
    opacity: 0.01;
    transform: scale(1) translateX(500px);
    transition: all 400ms ease-out;
}

.ReactCrop {
    background-color: #fff0 !important;
}

.drawer-herder-title {
    font-family: sanFranciscoMedium;
    font-size: 16px;
    font-weight: 500;
}

.ant-col .ant-upload-list-picture-card {
    float: none;
}
