.account-width-120p {
    width: 120px !important;
}

.modal-as-title {
    font-size: 24px;
    color: #0a1f44;
    font-weight: 500;
    line-height: 32px;
    height: 32px;
    margin-bottom: 10px;
    justify-content: left;
}

.font-family-mediumn {
    font-family: sanFranciscoMedium;
    color: #8a94a6;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
}

.modal-ad-title-color {
    color: #545e7c;
}

.margin-top-6 {
    margin-top: 6px;
}

.account-modal-ad-input {
    height: 42px;
    width: 245px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #d9d9d9 !important;
}

.account-modal-role {
    margin-top: 6px;
    width: 100%;
}

.account-modal-role > .ant-select-selection {
    height: 42px;
}

.account-modal-role-option {
    font-size: 16px;
    padding-top: 8px;
}

.itm-access-title {
    margin-bottom: 21px;
    height: 16px;
    width: 336px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.itm-access-option {
    height: 16px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.itm-access-def {
    width: 508px;
    color: #53627c;
    font-size: 12px;
    line-height: 16px;
    margin-top: 7px;
}

.itm-access-def-title {
    height: 16px;
    width: 336px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.fb-button {
    margin-top: 39px !important;
}

.feedback-body {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.feedback-rating-item {
    display: flex;
    justify-content: space-between;
    height: 22.4px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 8.66px;
}

.feedback-star .ant-rate-star {
    margin-right: 4px;
}

.feedback-star .ant-rate-star-full g {
    fill: #4767a0 !important;
}

.feedback-overall-title {
    height: 16px;
    width: 250px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 20px;
}

.feedback-input {
    height: 141px;
    width: 251px;
    /*background-color: #e91e41;*/
}

.opinion-man {
    margin-top: 26px;
}
.opinion-man > p {
    text-align: left;
    height: 16px;
    color: #53627c;
    font-family: sanFranciscoMedium;
    font-size: 14px;
    line-height: 16px;
}

.opinion-set {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 21px;
    /*background-color: #2e68ff;*/
}

.opinion-icon {
    cursor: pointer;
    margin-right: 30px;
    /*background-color: #e91e41;*/
    width: 66px;
}

.opinion-icon:last-child {
    margin-right: 0;
}

.opinion-icon-text {
    color: #c8ccca;
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
}

.icon-image {
    height: 60px;
    width: 60px;
    margin: auto;
}

.active-text {
    color: #4767a0;
}

@media only screen and (max-width: 769px) {
    .feedback-body {
        display: block;
        margin: 24px 0 0 0;
    }
    .feedback-overall-title {
        width: 100%;
        margin-bottom: 14px;
    }
    .feedback-rating-title {
        width: 100%;
        margin: 14px 0 0 0;
    }
    .opinion-set {
        display: block;
    }
    .opinion-icon {
        display: flex;
        align-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;
    }
    .icon-image {
        margin: unset;
        margin-right: 14px;
    }
    .fb-button {
        margin-top: 20px !important;
        display: block;
    }
    .fb-button button {
        width: 100% !important;
        margin: 4px 0;
    }
}
