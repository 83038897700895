@import '../../Helpers/Stylesheet/colors.scss';

.cp-input {
    height: 42px;
}

.pass-field-1 {
    margin-bottom: 0px;
}

.pass-field-2 {
    margin-bottom: 20px;
}

.ant-form-explain {
    min-height: 0px;
}

.pass-error {
    display: flex;
    flex-direction: row;

    &-msg {
        margin-top: 10px;
        margin-left: 5px;
        line-height: 14px;
        color: $red;
    }
}

.field-height {
    height: 44px;
}

.last-field-height {
    height: 24px;
}

@media only screen and (max-width: 769px) {
    .pass-field-1 {
        margin-bottom: 0px !important;
    }
}
