.ats-searchbox {
    min-width:  300px;
    margin-right: 10px;
}

.ats-searchbox .ant-select-selection__rendered {
    height: 40px;
    background-color: #eff0f4;
    color: #697998;
    line-height: 40px;
}

.ats-searchbox .ant-select-selection--single {
    height: 40px;
    background-color: #eff0f4;
    border: none;
    box-shadow: none;
}

.ats-searchbox input {
    color: #697998;
    font-family: sanFranciscoMedium;
}

.ats-searchbox ::placeholder {
    color: #697998;
}

.ats-searchbox .ant-select-selection--single:active,
.ats-searchbox .ant-select-selection--single:focus {
    box-shadow: none;
}

.ats-searchbox button {
    height: 40px;
    background-color: #4767A0;
}

.ats-algolia-search-btn {
    border-radius: 0 4px 4px 0;
    height: 40px;
}

.ats-algolia-option {
    font-size: 14px;
    padding-top: 8px;
    color: #697998;
}

.ats-disable {
    pointer-events: none;
}

.ats-search-stages {
	height: 35px;
	width: 228px;
	color: #8A94A6;
	font-size: 10px;
}