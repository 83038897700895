.incomplete-profile {
    left: 0;
}

.complete-profile {
    left: -50%;
}

.message-with-icon-modal-wrapper {
    display: flex;
    width: 100%;

    &-icon {
        width: 30px;
    }

    &-msg {
        width: calc(100% - 30px);
        font-size: 16px;
        line-height: 20px;
    }
}

.add-tag-tooltip .ant-tooltip-inner {
    font-size: 12px;
    color: #666666;
    background: #fff;
    min-width: 120px;
    max-height: 150px;
    overflow-y: auto;
    div {
        padding: 5px;
    }
}

.add-tag-tooltip .ant-tooltip-content {
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ebebeb;
        opacity: 0.2;
        border-radius: 2.5px;
    }
}
