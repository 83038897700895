h1.ant-typography,
.ant-typography h1 {
    font-family: sanFranciscoMedium;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    text-align: center;
}

h3.ant-typography,
.ant-typography h3 {
    font-family: sanFranciscoMedium;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #333333;
}

.header-logo {
    margin-bottom: 20px;
}
.header-logo svg {
    width: 100px;
    height: 100px;
}

.dashboard-icon-tick {
    margin-right: 10px;
}

.dashboard-card-info {
    list-style: none;
    padding: 0;
}

.dashboard-card-info li::before {
    content: '\2022';
    color: #999999;
    font-weight: bold;
    display: inline-block;
    width: 1em;
}

.dashoboard-card-btn,
.dashoboard-card-btn:hover,
.dashoboard-card-btn:active {
    height: 40px;
    width: 180px;
    background-color: #4767a0;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    font-family: sanFranciscoMedium;
}

.dashboard-card-text {
    color: #8a94a6;
    font-size: 14px;
}

.dashboard-main-container {
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding: 30px 0;
}

.joblist-edit {
    margin-left: 20px;
}

.candidate-count {
    margin-right: 4px;
}

.dash-opening-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 1200px;
}

.view-all {
    display: flex;
    justify-content: center;
}

.job-static-text {
    height: 32px;
    width: 268px;
    color: #4767a0;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 32px;
}

.job-link {
    height: 42px;
    width: 214px;
    background: #f0f2f5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    margin-top: 56px;
    margin-bottom: -40px;
}

.goto-dash {
    height: 42px;
    width: 214px;
    background: #f0f2f5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    margin-top: 56px;
}

.goback-text {
    color: #999;
    font-size: 12px;
    margin: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 1200px;
}
.goback-text .arrow {
    font-size: 8px;
    margin: 0 5px;
}
.goback-text .blue {
    color: #4767a0;
}

.job-opening-form {
    width: 100%;
}

.job-opening-form-tab {
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: space-between;
}

.job-detail-container {
    /* width: 876px; */
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    margin-top: 30px;
    background-color: #fff;
}

.job-detail-header {
    display: flex;
    align-items: center;
    height: 46px;
    background: #f0f2f5;
    padding-left: 40px;
    font-size: 16px;
    font-weight: bold;
    font-family: sanFranciscoMedium;
    color: #53627c;
}

.job-detail-form {
    display: flex;
    flex-direction: column;
    margin: 20px 40px 40px 40px;
}

.job-detail-form-cell {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 30px;
}

.job-detail-form-left {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    margin-right: 34px;
}

.job-detail-form-right {
    display: flex;
    flex: 0.5;
    flex-direction: column;
}

.job-detail-form-right .ant-input-number-input {
    text-align: right;
    padding-right: 25px;
}

.job-detail-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.bottom-radio {
    display: flex;
    align-items: center;
    color: #53627c;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
}

.description {
    margin-top: 30px;
}

.save-buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 60px;
}

.app-form {
    display: flex;
    flex-direction: column;
    margin: 8px 40px 40px 40px;
}

.app-form-cell {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    justify-content: space-between;
}

.app-form-cell-text {
    color: #53627c;
    font-size: 18px;
}
.app-form-cell {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    justify-content: space-between;
}

.app-form-cell-text {
    color: #53627c;
    font-size: 18px;
}

.app-form-save-future {
    display: flex;
    align-items: center;
    color: #53627c;
    font-size: 10px;
}

.promote-form {
    display: flex;
    flex-direction: row;
}
.job-ctr {
    border-bottom: 1px solid #e1e4e8;
}

.job-ctr:last-child {
    border: none;
}

.job-board-cell {
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 88px;
    border: 1px solid #eff0f4;
    border-radius: 8px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    margin-right: 20px;
}

.job-board-text-image {
    display: flex;
    margin-top: 15px;
    margin-left: 15px;
    align-content: center;
}

.promote-text {
    color: #0a1f44;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}

.promote-bottom-cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    color: #53627c;
    font-size: 10px;
    margin-top: 11px;
}

.board-note {
    height: 16px;
    width: 308px;
    color: #53627c;
    font-size: 10px;
    margin-top: 6px;
    margin-bottom: -20px;
}

.social-row {
    display: flex;
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 40px;
}

.app-form-save-future {
    display: flex;
    align-items: center;
    color: #53627c;
    font-size: 10px;
}

.promote-form {
    display: flex;
    flex-direction: row;
}

.job-board-cell {
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 88px;
    border: 1px solid #eff0f4;
    border-radius: 8px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    margin-right: 20px;
}

.job-board-text-image {
    display: flex;
    margin-top: 15px;
    margin-left: 15px;
    align-content: center;
}

.promote-text {
    color: #0a1f44;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}

.promote-bottom-cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    color: #53627c;
    font-size: 10px;
    margin-top: 11px;
}

.board-note {
    height: 16px;
    width: 308px;
    color: #53627c;
    font-size: 10px;
    margin-top: 6px;
    margin-bottom: -20px;
}

.social-row {
    display: flex;
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 40px;
}

.social-cell {
    display: flex;
    flex-direction: row;
    width: 175px;
    height: 55px;
    border: 1px solid #eff0f4;
    border-radius: 8px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    color: #0a1f44;
    font-size: 12px;
}

.referral-row {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 30px;
}

.referral-text-link {
    height: 16px;
    width: 680px;
    color: #53627c;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
}

.referral-text-email {
    margin-top: 15px;
    height: 16px;
    width: 680px;
    color: #53627c;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
}

.referral-career-page {
    margin-top: 15px;
    height: 16px;
    width: 680px;
    color: #53627c;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
}

.referral-text-notes {
    margin-top: 10px;
    height: 16px;
    width: 680px;
    color: #53627c;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
}

.share {
    flex-direction: row;
}

.share-buttons {
    margin-right: 20px;
}

.share .buttons {
    width: 175px;
    height: 55px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 2px 0 rgba(10, 31, 68, 0.1);
    border-radius: 4px;
    font-size: 12px;
    color: #0a1f44;
    font-family: sanFranciscoMedium;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.share svg {
    margin-right: 5px;
}

/* Customize the label (the container) */
.jb-checkbox {
    display: block;
    position: relative;
    padding-left: 16px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.jb-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.jb-checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.jb-checkbox:hover input ~ .jb-checkmark {
    background-color: transparent;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.jb-checkbox input:checked ~ .jb-checkmark {
    background-color: #697398;
    border: 2px solid #697398;
    border-radius: 3px;
}

/* Create the filter-checkmark/indicator (hidden when not checked) */
.jb-checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the filter-checkmark when checked */
.jb-checkbox input:checked ~ .jb-checkmark:after {
    display: block;
}

/* Style the filter-checkmark/indicator */
.jb-checkbox .jb-checkmark:after {
    left: 3px;
    top: 0px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.dashboard-icon > svg {
    height: 29px;
    width: 40px;
    fill: #5c6682;
}

.coordinator-popover {
    padding: 20px 12px;
    min-width: 320px;
}

.coordinator-popover-search {
    border-radius: 8px;
}

.coordinator-list-result {
    margin-top: 25px;
}

.coordinator-list-result-avatar {
    margin: auto 5px;
    background-color: #4767a0;
    height: 32px;
    width: 32px;
}

.coordinator-result-name {
    margin: auto 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.coordinator-result-btn {
    border: none;
    box-shadow: none;
    margin-left: auto;
}

.coordinator-avatar1 {
    margin-left: 5px;
    background-color: #4767a0;
    height: 32px;
    width: 32px;
}

.coordinator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1.5px solid #4767a0;
    font-size: 14px;
    font-weight: bold;
    font-family: sanFranciscoMedium;
    color: #4767a0;
    margin: -2px;
    background: #fff;
    cursor: pointer;
}

.plus {
    font-size: 14px;
}

.three-dots:hover {
    cursor: pointer;
}

.joblist-btn {
    display: flex;
}

.joblist-btn > * {
    margin: auto 0 auto 20px !important;
}

.dashboard-3-title {
    height: 20px;
    width: 773px;
    color: #4767a0;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 23px;
    font-family: sanFranciscoMedium;
}

.dashboard-card-text-3 {
    color: #8a94a6;
    width: 583px;
    font-size: 14px;
    font-family: sanFranciscoMedium;
}

.dash-card-info {
    display: flex;
    justify-content: space-between;
}

.duration,
.location {
    display: inline;
    margin-right: 30px;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    font-family: sanFranciscoMedium;
}
.location {
    margin-left: 4px;
}
.duration i,
.location i {
    margin-right: 5px;
}
.duration-location .duration p,
.location p {
    margin-bottom: 0;
}
.duration-location {
    display: flex;
    margin-top: 6px;
}

.job-list-coordinator-text {
    height: 16px;
    color: #8a94a6;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-right: 3px;
}

.dashboard-text {
    margin-bottom: 10px;
    display: block;
    font-family: sanFranciscoMedium;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

.dashboard-text i {
    cursor: pointer;
}
.dashboard-title {
    background-color: #fff;
    box-shadow: 0 10px #fff, 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    margin-bottom: 0 !important;
    height: 32px !important;
    line-height: 35px !important;
    margin-left: 1.65%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 20px;
    width: fit-content;
    position: relative;
}

.dashboard-assignment-cont {
    margin: 30px auto;
    width: 100%;
}

.dashboard-assignment-cont .dashboard-text {
    margin-left: 0;
}

.dashboard-title-interviewer {
    background-color: #fff;
    box-shadow: 0 10px #fff, 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    margin-bottom: 0 !important;
    height: 32px !important;
    line-height: 35px !important;
    margin-left: 1.75%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 20px;
    width: fit-content;
    position: relative;
}

.dashboard-assignment-body {
    max-height: 223px;
    width: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    overflow: hidden auto;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
}

.dashboard-assignment-title {
    height: 40px;
    width: 200px;
    background-color: #fff;
    color: #4767a0;
    font-size: 16px;
    font-weight: 600;
    padding: 11px 20px;
    position: relative;
    border-bottom: none;
    box-shadow: 0 10px #fff, 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dashboard-assignment-title .anticon {
    margin-left: 6px;
    cursor: pointer;
}

.dashboard-assignment-btn {
    height: 28px;
    width: 157px;
    color: #ffffff;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    margin: auto;
    margin-right: 0;
    font-family: sanFranciscoMedium;
}

.no-interview {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
}

.no-interview-text {
    width: 70%;
}

.no-interview-icon {
    display: flex;
    width: 30%;
    padding-left: 20%;
}

.ce-body {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    padding: 20px 0;
    font-size: 14px;
    font-weight: 600;
    color: #8a94a6;
    border-radius: 4px;
}

.ce-block-left,
.ce-block-right {
    flex: 1;
    padding: 0 25px;
}

.ce-icon {
    margin-right: 10px;
}

.ce-notes {
    display: flex;
}

.ce-notes b {
    color: #53627c;
}

.ce-text {
    width: 100%;
    display: flex;
    color: #8a94a6;
    flex-direction: column;
}

.ce-button {
    margin-top: 10px;
    font-size: 11px;
}

.ce-body input {
    border-color: #e1e4e8;
    font-size: 14px;
    font-weight: 600;
    color: #a6aebc;
    margin-top: 20px;
}

.ce-noteProperties {
    display: flex;
}
.ya-title {
    flex: 5;
    padding-right: 20px;
}

.ya-title-bold {
    font-family: sanFranciscoMedium;
    text-transform: capitalize;
    font-size: 16px;
    color: #4767a0;
}

.ya-role {
    flex: 1;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ya-date {
    flex: 2;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    text-align: right;
}

.ya-role-title {
    margin-right: 5px;
}

.ya-date-title {
    margin-right: 20px;
}

.ya-title-time {
    line-height: 45px;
}

.ya-title-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ya-title-detail:first-child {
    margin-bottom: 8px;
}

.ya-title-detail {
    line-height: 30px;
}

.ya-tooltip {
    font-size: 10px;
}

.interview-time-icon {
    padding-right: 8px;
}

.interview-details {
    color: blue;
}

.plus-button {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 769px) {
    .talentpool-header-search {
        width: 100%;
        margin: auto;
        padding-right: 0;
    }
    .talentpool-header-search input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .talentpool-header-search-container,
    .talentpool-header-container {
        width: 100%;
    }
    .talent-pool-tags {
        min-width: unset;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
    }
    .talent-pool-header-stats {
        display: block;
    }
    .talentpool-header-button-container {
        display: block;
        width: 100%;
    }
    .talentpool-header-button-container button {
        margin: auto;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        text-align: left;
    }
    .talentpool-header-button > * {
        margin: 0 0 0 10px;
    }
    .talentpool-header-button div i {
        margin-left: 0 !important;
    }
    .talentpool-filter-block-parent {
        display: block;
    }
    .talent-pool-container {
        padding: 0 15px;
        height: auto;
    }
    .talent-pool-container p {
        width: auto;
    }
    .filter-card {
        width: 100%;
        margin: auto;
    }
    .tp-data-cont {
        width: 100%;
    }

    /* Dashboard */
    .dashboard-main-container {
        padding: 15px;
    }
    .job-ctr {
        font-size: 10px;
    }
    .ce-body {
        padding: 10px 0;
    }
    .ce-body div {
        display: block;
        margin: 0;
    }
    .ce-body .ce-notes {
        display: flex;
    }
    .ce-block-left {
        text-align: center;
    }
    .ce-block-notes {
        margin: 0;
    }
    .ce-button {
        margin: 10px auto 30px auto;
    }
    .ce-body input {
        margin-top: 10px;
    }
    .ce-block-left,
    .ce-block-right {
        padding: 0 15px;
    }
    .ce-noteProperties {
        margin-left: 10px !important;
    }
    .td-data-container {
        display: block;
    }
    .card-count {
        font-size: 18px;
    }
    .card-count-text {
        font-size: 9px !important;
    }
    .dashboard-coordinator-tooltip {
        margin-bottom: 0;
    }
    #moreButton {
        float: right;
    }
    .list-cell {
        display: block;
        margin: 0;
        padding: 10px;
        height: unset;
    }
    .positionDetail {
        width: 100%;
        padding-right: 0;
        margin: 0 0 10px 0;
    }
    .positionDetail span {
        font-size: 14px;
        word-break: break-word;
    }
    .no-interview {
        padding: 10px;
        display: block;
        text-align: center;
    }
    .no-interview div {
        text-align: left;
        font-size: 14px;
    }
    .no-activity div {
        margin: 0;
        padding: 10px;
        font-size: 14px;
    }
    .no-activity div div {
        padding: 0;
    }
    .dashboard-assignment-body {
        padding: 0 15px;
    }
    .ya-role {
        margin: 6px 0;
    }
    .ya-date {
        text-align: left;
    }
    .ya-title {
        padding-right: 0;
    }
    .duration span {
        margin-right: 0;
        display: block;
    }
    .location {
        margin-right: 0;
    }
    .job-ctr {
        display: block !important;
        margin: 0px 15px !important;
        padding: 15px 0 !important;
    }
    .joblistDetailContainer {
        display: block;
    }
    .joblistTitleContainer {
        display: block !important;
        margin-bottom: 10px;
    }
    .location span p {
        display: flex;
        word-break: break-all;
    }
    .location span p i {
        margin-top: 4px;
    }
    .dashboard-coordinator-tooltip {
        margin-bottom: 6px !important;
    }
    .job-description-parent {
        display: block;
        flex-direction: column;
    }
    .job-descriptions {
        margin-bottom: 10px;
    }
    .job-detail-header {
        height: unset;
        padding-left: 0;
        padding: 15px;
        font-size: 14px;
    }
    .job-detail-container .app-form {
        margin: 15px;
    }
    .job-detail-container .app-form {
        margin-top: 0;
        margin: 0 0 20px 0;
        padding: 15px;
    }
    .job-detail-container .app-form .app-form-cell {
        display: block;
    }
    .job-detail-container .app-form .app-form-cell .app-form-cell-text {
        font-size: 14px;
        margin-bottom: 6px;
    }
    .job-detail-container .app-form .app-form-cell .ant-btn-group button {
        width: 100% !important;
        margin: 4px 0;
    }
    .job-detail-container .referral-row {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 0;
        margin: 15px;
    }
    .job-detail-container .referral-row .referral-text-link,
    .referral-text-email,
    .referral-text-notes,
    .referral-career-page {
        width: 100%;
        height: unset;
    }
    .job-detail-container .referral-row button {
        margin-left: 0 !important;
        margin-top: 8px;
    }
    .job-detail-container .share {
        display: block;
    }
    .job-detail-container .share .share-buttons {
        margin-right: 0;
    }
    .job-detail-container .share .share-buttons .SocialMediaShareButton .buttons {
        width: 100%;
        justify-content: left;
        padding-left: 50px;
    }
    #copyUniqueCareerPageUrl,
    #copyUniqueEmailId,
    #copyReferalInput,
    #copyUniqueCareerPageUrlButton,
    #copyUniqueEmailButtonId,
    #copyReferalButton {
        width: 100% !important;
    }
    .duration-location {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .plus {
        margin-top: -9px;
    }
    .candidate-count {
        margin: 4px 0 2px 0;
    }
    .duration-location span {
        line-height: normal;
    }

    .duration-location {
        display: block;
        margin-bottom: 10px;
    }
    #addCoordinatorButton {
        background-color: #fff;
    }
    .coordinator-popover {
        min-width: 260px;
        width: calc(100% - 15px);
    }
    .duration,
    .location {
        display: flex;
        align-content: center;
        align-items: center;
        margin: 0 0 8px 0;
    }
    .location {
        margin-left: 0;
    }
    .dash-card-info {
        display: block;
    }
    .dashboard-3-title {
        width: 100%;
        height: auto;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
    }
    .dashboard-card-text-3 {
        width: 100%;
    }
    .dash-card-info-inner {
        margin-bottom: 12px;
    }
    .dash-card-info-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}
