.board-header-text {
    color: #4767a0 !important;
    text-align: left !important;
    font-weight: 500 !important;
    font-size: 24px;
    display: flex;
    line-height: 32px;
}

.header-subtitle {
    color: #8b92a6;
    font-family: sanFranciscoMedium;
    font-size: 14px;
    width: 90.62vw;
    margin: auto;
}

.header-button {
    width: 157px;
    height: 42px;
    font-size: 14px;
}

.header-button-container {
    display: flex;
    justify-content: space-between;
}

.modal-as-title {
    font-size: 24px;
    color: #0a1f44;
    font-weight: 500;
    line-height: 32px;
    height: 32px;
    margin-bottom: 10px;
}

.font-family-mediumn {
    font-family: sanFranciscoMedium;
    color: #8a94a6;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
}

.margin-top-5 {
    margin-top: 5px;
}

.modal-ad-position {
    margin-top: 5px;
    width: 100%;
}

.modal-ad-position-option {
    font-size: 16px;
    padding-top: 8px;
}

.modal-ad-preview {
    margin-top: 15px;
}

.w-100 {
    width: 100%;
}

.modal-ad-radio {
    text-align: right;
    padding-right: 0;
}

.width-120p {
    width: 119px;
    height: 42px;
}

.modal-ad-input {
    width: 265px;
    height: 42px;
}

.modal-ad-name {
    font-size: 13px;
}

.ats-header-search-cont {
    flex-grow: 40;
}

.board-change-job-icon {
    margin: 8px 0 0 0;
}

.board-header-text-title {
    margin-right: 10px;
    word-break: break-word;
    text-align: justify;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.job-change-title {
    height: 20px;
    color: #4767a0;
    font-size: 10px;
    line-height: 20px;
}

.job-change-option {
    height: 34px !important;
    width: 215px !important;
    color: #53627c;
    font-size: 12px !important;
    line-height: 34px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-family: sanFranciscoMedium;
    border-bottom: 1px solid #eee;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
}

.job-change-overlay .ant-menu {
    border: none;
}

.job-change-overlay {
    width: 215px;
}

.job-change-overlay .ant-popover-title {
    border-bottom: 1px solid #eee;
}

.job-change-overlay .ant-popover-inner-content {
    padding: 0;
    border: 0;
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
}

.job-change-overlay .ant-menu-item-selected {
    background-color: #f4f6fa !important;
}

.job-change-overlay .ant-menu-item-active {
    background-color: #e1e2eb !important;
}

.header-container {
    width: 35%;
}

.ats-filter {
    display: flex;
    justify-content: flex-end;
    color: #8a94a6;
    font-size: 15px;
    font-weight: 600;
    width: 1200px;
    margin: auto;
    margin-top: 10px;
}

.ats-filter-text {
    margin-right: 10px;
}

.ats-view-button {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.ats-filter-no-result {
    height: 32px;
    color: #53627c;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.ats-filter-no-result-button {
    height: 42px;
    min-width: 40%;
    margin-left: 10px;
    margin-top: -10px;
}

.ats-filter-button-text {
    color: #ffffff;
    margin-left: 25px;
    font-size: 20px;
}

@media only screen and (max-width: 995px) {
    .header-container {
        width: 100%;
        margin-bottom: 15px;
    }
    .board-header-text {
        font-size: 20px;
        font-weight: bold !important;
        line-height: normal;
    }
    .board-change-job-icon {
        margin: 0;
    }
    .board-change-job-icon svg {
        width: 16px;
    }
    .header-button-container {
        display: block;
    }
    .ats-searchbox {
        min-width: auto !important;
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 10px !important;
    }
    .ant-btn-lg {
        width: 100%;
        margin-left: 0 !important;
        margin: 4px 0;
    }
    .ats-filter {
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        justify-content: flex-start;
        padding: 0 15px;
        margin-top: 30px;
    }
    .ats-filter-text {
        margin: 0 0 10px 0;
        text-align: center;
    }
    .ats-view-button {
        margin: 4px 0 0 0;
        justify-content: flex-end;
    }
    .ats-filter-no-result {
        white-space: unset;
        top: 90%;
        font-size: 16px;
        width: 100%;
        padding: 0 15px;
    }
    .ats-filter-select {
        margin-top: 0;
        padding: 0 15px;
    }
    .candidate-opening-select {
        width: 100% !important;
    }
    .ats-column-empty-container {
        padding-top: 0 !important;
        height: 100vh;
    }

    .modal-ad-input .ant-select-selection--single {
        height: 40px;
    }
    .modal-ad-title-color {
        margin-top: 20px;
    }
}
