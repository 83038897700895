.message-container {
    &__msg {
        margin-bottom: 15px;
    }
    &__question {
        font-family: sanFranciscoSemi;
        font-size: 16px;
        line-height: 1.38;
    }
}
