.next-opportunity {
    .next-opportunity-container {
        width: calc(100% - 80px);
        margin: auto;
        padding: 0 40px;
        @media only screen and (max-width: 995px) {
            width: 100%;
            padding: 0;
        }
    }
    .next-opportunity-download {
        padding: 50px 0;
        position: relative;
        @media only screen and (max-width: 995px) {
            padding: 0;
            margin-bottom: 50px;
        }
        .next-opportunity-download-pattern {
            width: 198px;
            height: 198px;
            @media only screen and (max-width: 995px) {
                width: 136px;
                height: 136px;
            }
            img {
                max-width: 100%;
            }
        }
        .next-opportunity-download-content {
            text-align: center;
            position: absolute;
            top: 74px;
            left: 0;
            right: 0;
            @media only screen and (max-width: 995px) {
                position: unset;
                padding: 0 15px;
                margin-top: -100px;
            }
            div {
                color: #333333;
                display: inline-block;
                font-size: 36px;
                font-weight: bold;
                font-family: 'SFProText-Heavy';
                text-align: center;
                margin-bottom: 30px;
                @media only screen and (max-width: 995px) {
                    font-size: 26px;
                    margin-bottom: 50px;
                }
            }
            h1 {
                width: calc(100% - 124px);
                margin: auto;
                font-size: 30px;
                line-height: 1.67;
                color: #666666;
                margin-bottom: 15px;
                font-family: 'SFProDisplay-Light';
                letter-spacing: 1px;
                font-weight: lighter;
                @media only screen and (max-width: 995px) {
                    width: 100%;
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                span {
                    font-family: 'sanFranciscoMedium';
                    margin-bottom: 0;
                    color: #666666;
                    @media only screen and (max-width: 995px) {
                        font-size: 20px;
                    }
                }
            }
            .down-arrow {
                margin-bottom: 0;
                @media only screen and (max-width: 995px) {
                    margin-top: 10px;
                    display: inline-block;
                }
                img {
                    opacity: 0.8;
                    transition: ease 0.3s;
                    @media only screen and (max-width: 995px) {
                        width: 15px;
                    }
                    &:hover {
                        opacity: 1;
                        margin-top: 10px;
                    }
                }
                &:hover {
                    img {
                        opacity: 1;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .next-opportunity-form-section {
        background-color: #f7f8fc;
        padding: 50px 0;
        @media only screen and (max-width: 995px) {
            height: auto;
            padding: 50px 15px 0 15px;
        }
        .next-opportunity-form-section-iframe {
            iframe {
                width: 100%;
                height: 770px;
                @media only screen and (max-width: 995px) {
                    border-radius: 8px;
                    border: 1px solid #999999;
                }
            }
        }
        .next-opportunity-form-download-card {
            box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
            background-color: #ffffff;
            width: calc(100% - 160px);
            margin: auto;
            padding: 50px 40px 98px 40px;
            @media only screen and (max-width: 995px) {
                margin-top: 50px;
                padding: 50px 15px;
                width: calc(100% + 30px);
                margin: auto;
                margin-left: -30px;
                margin-right: 0;
                transform: translate(15px, 15px);
                box-shadow: none;
                border-bottom: 1px solid #f2f2f2;
            }
            .next-opportunity-name-email {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                @media only screen and (max-width: 995px) {
                    display: block;
                }
                .next-opportunity-download-name {
                    position: relative;
                    input {
                        width: 304px;
                        line-height: 48px;
                        border-radius: 4px;
                        border: solid 1.5px #e1e4e8;
                        background-color: #f3f4f6;
                        padding: 0 24px 0 12px;
                        font-size: 14px;
                        font-weight: 500;
                        margin-right: 20px;
                        @media only screen and (max-width: 995px) {
                            width: 100%;
                            margin-bottom: 20px;
                            padding: 0 36px 0 12px;
                        }
                        &::placeholder {
                            color: #999999;
                            font-size: 14px;
                        }
                    }
                    img {
                        position: absolute;
                        right: 34px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        @media only screen and (max-width: 995px) {
                            top: -22px;
                            right: 14px;
                            bottom: 0;
                        }
                    }
                }
                .next-opportunity-download-name-error {
                    input {
                        border: solid 1.5px #e02020;
                        background-color: #ffffff;
                        &::placeholder {
                            color: #e02020;
                        }
                    }
                }
                .next-opportunity-download-button {
                    @media only screen and (max-width: 995px) {
                        text-align: center;
                        margin-top: 10px;
                    }
                    button {
                        width: 144px;
                        line-height: 48px;
                        background-color: #4767a0;
                        color: #ffffff;
                        border-radius: 24px;
                        border: 1px solid #4767a0;
                        cursor: pointer;
                        transition: ease 0.5s;
                        @media only screen and (max-width: 995px) {
                            font-size: 12px;
                            line-height: 30px;
                            font-family: 'sanFranciscoSemi';
                        }
                        &:hover {
                            border: 1px solid #4767a0;
                            background-color: #ffffff;
                            color: #4767a0;
                        }
                    }
                }
            }
        }
    }

    .next-opportunity-who-made-this {
        background-color: #f7f8fc;
        padding: 80px 0 90px 0;
        @media only screen and (max-width: 995px) {
            margin-top: 0;
            padding: 50px 30px;
        }
        .next-opportunity-who-made-this-header {
            h2 {
                font-size: 36px;
                font-weight: 600;
                color: #666666;
                text-align: center;
                font-family: 'sanFranciscoSemi';
                margin-bottom: 40px;
                @media only screen and (max-width: 995px) {
                    font-size: 20px;
                    margin-bottom: 30px;
                }
            }
        }
        .next-opportunity-who-made-this-content {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            @media only screen and (max-width: 995px) {
                display: block;
                text-align: center;
            }
            .next-opportunity-who-made-this-springrecruit-logo {
                margin-right: 56px;
                @media only screen and (max-width: 995px) {
                    margin-right: 0;
                    margin-bottom: 30px;
                    img {
                        width: 148px;
                    }
                }
            }
            .next-opportunity-who-made-this-springrecruit-para {
                position: relative;
                p {
                    width: 733px;
                    margin: auto;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.67;
                    color: #666666;
                    text-align: left;
                    font-family: 'SFProDisplay-Light';
                    letter-spacing: 1px;
                    @media only screen and (max-width: 995px) {
                        font-size: 16px;
                        line-height: 1.88;
                        width: 100%;
                        text-align: center;
                        height: auto;
                    }
                }
            }
        }
    }
}

.next-opportunity-txt-content {
    text-align: center;
    h1 {
        width: calc(100% - 110px);
        margin: auto;
        font-size: 30px;
        font-weight: 300;
        line-height: 1.67;
        color: #666666;
        margin-bottom: 30px;
        font-family: 'SFProDisplay-Light';
        letter-spacing: 1px;
        font-weight: lighter;
        @media only screen and (max-width: 995px) {
            width: 100%;
            font-size: 20px;
            line-height: 34px;
        }
        span {
            font-family: 'sanFranciscoSemi';
        }
    }
}

.next-opportunity-standup-idea {
    text-align: center;
    margin: 80px 0;
    @media only screen and (max-width: 995px) {
        margin-bottom: 40px;
        margin: 70px 0 40px 0;
        padding: 0 30px;
    }
    .next-opportunity-meeting-icon {
        width: 100px;
        height: 100px;
        margin: auto;
        background-color: #e5f7fe;
        border-radius: 50%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        @media only screen and (max-width: 995px) {
            margin-bottom: 30px;
        }
        img {
            max-width: 70%;
            opacity: 1;
        }
    }
    h3 {
        font-size: 36px;
        font-weight: 600;
        font-family: 'sanFranciscoSemi';
        margin-bottom: 30px;
        color: #666666;
        @media only screen and (max-width: 995px) {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }
    p {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.67;
        color: #666666;
        margin-bottom: 20px;
        font-family: 'SFProDisplay-Light';
        letter-spacing: 1px;
    }
    a {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.67;
        color: #4767a0;
        font-family: 'sanFranciscoMedium';
    }
}

.next-opportunity-download-pattern-bottom {
    width: 182px;
    height: 182px;
    position: absolute;
    top: -40px;
    right: -60px;
    bottom: 0;
    @media only screen and (max-width: 995px) {
        top: unset;
        right: -15px;
        bottom: -45px;
        width: 136px;
        height: 136px;
    }
    img {
        max-width: 100%;
    }
}

.next-opportunity-typeform-full {
    width: 70%;
    margin: auto;
    padding: 50px 0;
    @media only screen and (max-width: 995px) {
        width: 100%;
        padding: 50px 15px;
    }
    iframe {
        border: 5px;
        height: 75vh;
        @media only screen and (max-width: 995px) {
            border-radius: 5px;
        }
    }
    .next-opportunity-typeform-full-header {
        h3 {
            font-family: 'sanFranciscoSemi';
            font-size: 36px;
            color: #333333;
            @media only screen and (max-width: 995px) {
                font-size: 20px;
            }
        }
    }
}
