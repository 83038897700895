.account-width-120p {
    width: 120px !important;
}

.add-can-resume-name {
    height: 42px;
    min-width: 245px;
    color: #4767a0;
    font-size: 14px;
    line-height: 42px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 3px;
    cursor: pointer;
}

.modal-as-title {
    font-size: 24px;
    color: #0a1f44;
    font-weight: 500;
    line-height: 32px;
    height: 32px;
    margin-bottom: 10px;
}

.font-family-mediumn {
    font-family: sanFranciscoMedium;
    color: #8a94a6;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
}

.modal-ad-title-color {
    color: #545e7c;
    font-size: 12px;
}

.margin-top-6 {
    margin-top: 6px;
}

.account-modal-ad-input {
    height: 42px;
    width: 245px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #d9d9d9 !important;
}

.account-modal-role {
    margin-top: 6px;
    width: 100%;
}

.account-modal-role > .ant-select-selection {
    height: 42px;
}

.account-modal-role-option {
    font-size: 16px;
    padding-top: 8px;
}

.itm-access-title {
    margin-bottom: 21px;
    height: 16px;
    width: 336px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.itm-access-option {
    height: 16px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.itm-access-def {
    width: 508px;
    color: #53627c;
    font-size: 12px;
    line-height: 16px;
    margin-top: 7px;
}

.itm-access-def-title {
    height: 16px;
    width: 336px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.candidate-upload-start {
    min-height: 100%;
    min-width: 100%;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.candidate-upload-info {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.candidate-upload-info-detail {
    margin-bottom: 7px;
}

.candidate-upload-start > div {
    height: 16px;
    width: 243px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin: 0 auto;
    white-space: nowrap;
}

.candidate-upload-click-here {
    font-family: sanFranciscoMedium;
    cursor: pointer;
    color: #4767a0;
}

.candidate-upload-inprogress-cont {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100%;
}

.candidate-upload-inprogress {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.candidate-upload-inprogress-text {
    margin-top: 10px;
    width: 227px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    font-family: sanFranciscoMedium;
}

.candidate-upload-inprogress-loader {
    width: 225px;
    height: 22px;
    margin-bottom: 10px;
    display: flex;
}

.candidate-upload-inprogress-33 {
    width: 75px;
    height: 22px;
    background-color: #4767a0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.candidate-upload-inprogress-66 {
    width: 75px;
    height: 22px;
    background-color: #fff;
}

.candidate-upload-inprogress-66-fill {
    background-color: #4767a0 !important;
}

.candidate-upload-inprogress-100 {
    width: 75px;
    height: 22px;
    background-color: #fff;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.candidate-upload-wrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    height: 252px;
    border: 3px dashed #93a2d0;
    background-color: #f1f1f4;
}

.candidate-uploaded-title {
    height: 20px;
    color: #53627c;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: left !important;
    margin-bottom: 6px;
    font-family: sanFranciscoMedium;
    display: flex;
}

.candidate-uploaded-title-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.candidate-uploaded-title > div {
    margin-right: 9px;
}

.candidate-uploaded-location {
    height: 16px;
    color: #8a94a6;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left !important;
    margin-bottom: 6px;
    font-family: sanFranciscoMedium;
}

.candidate-uploaded-summary {
    height: 16px;
    width: 480px;
    color: #8a94a6;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left !important;
    margin-bottom: 6px;
    font-family: sanFranciscoMedium;
}

.candidate-upload-wrapper > .ant-upload.ant-upload-drag {
    border: none !important;
    background-color: #f1f1f4;
}

.candidate-no-jobs {
    height: 48px;
    width: 547px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.candidate-job-item {
    width: 245px;
    line-height: 1;
}

.candidate-job-cont {
    width: 100%;
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
}

.no-job-text {
    margin-top: 35px;
    text-align: left;
    font-family: sanFranciscoMedium;
    height: 48px;
    width: 547px;
    color: #53627c;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.cd-form-main {
    overflow: hidden;
}

.cd-form {
    max-height: 403px;
    overflow-x: hidden;
    overflow-y: auto;
    /* margin-right: -10px; */
}

.cd-count-index {
    height: 16px;
    width: 245px;
    color: #4767a0;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2px;
}

.cd-count-cont {
    display: flex;
    justify-content: space-between;
    text-align: left;
    height: 20px;
}

.cd-count-cont .anticon {
    margin: auto 0;
    margin-left: auto;
}

.candidate-job-cont .ant-form-item {
    margin-bottom: 0;
}

.no-margin-cd {
    margin-right: 0 !important;
}

.candidate-upload-manually {
    height: 16px;
    color: #8a94a6;
    font-size: 10px;
    line-height: 16px;
    text-align: left;
    margin-top: 5px;
    font-family: sanFranciscoMedium;
}

.phone-input-sr {
    border: none !important;
    width: 245px;
}

.phone-input-sr .ant-input-group-addon {
    background: none;
}

.phone-input-sr .ant-input {
    height: 42px;
    color: #53627c;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #d9d9d9 !important;
}

.has-error .phone-input-sr input {
    border: 1px solid #f5222d !important;
}

.phone-input-sr .ant-select-selection-selected-value {
    font-size: 12px;
}

.phone-input-dropdown li {
    font-family: sanFranciscoMedium;
    border-bottom: 1px solid #f4f5fa;
}

.phone-input-dropdown li:last-child {
    border-bottom: none;
}

@media only screen and (max-width: 769px) {
    .cd-form-main {
        display: block;
    }
    .add-candidate-form-inputs {
        width: 100%;
        margin-bottom: 16px;
    }
    .candidate-job-cont {
        display: block !important;
    }
    .candidate-job-item {
        width: 100% !important;
    }
    .modal-as-title {
        height: unset !important;
    }
}
