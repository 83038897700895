.next-opportunity-footer {
    background-color: #1c2234;
    padding: 24px 0;
    text-align: center;
    @media only screen and (max-width: 769px) {
        display: inline-block;
        width: 100%;
        padding: 0;
    }
    span {
        font-size: 14px;
        color: #999999;
        @media only screen and (max-width: 769px) {
            display: block;
            margin: 10px 0;
        }
        a {
            font-size: 14px;
            color: #999999;
            cursor: pointer;
            font-weight: 100;
            margin: 10px 0;
        }
    }
    .next-opportunity-term-conditions {
        padding: 0 10px;
        margin: 0 10px;
        border: 1px solid #999999;
        border-top: 0;
        border-bottom: 0;
        color: #999999;
        @media only screen and (max-width: 769px) {
            padding: 0;
            margin: 0;
            border: 0;
        }
    }
    .next-opportunity-privacy-policy {
        color: #999999;
    }
}
