.delete-width-120p {
    width: 119px;
    height: 42px;
}

.chp-input {
    height: 48px;
}

.cnc-action-wrapper {
    margin-top: 16px !important;
}

.cnc-modal-as-title {
    height: 32px;
    color: #0A1F44;
    font-size: 24px;
    font-family: sanFranciscoMedium;
    letter-spacing: 0.5px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 28px;
}
