.AccountSettingCandidateSourceInitative {
    img {
        margin-bottom: 15px;
    }
    p {
        font-size: 14px;
        line-height: 21px;
        line-height: 1.5;
        letter-spacing: -0.09px;
        color: #666666;
        margin: 0 0 20px 0;
    }
    button {
        line-height: 40px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background-color: #4767a0;
        border: 0;
        padding: 0;
    }
    .CandidateSubscriptionType {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
            font-size: 16px;
            font-family: sanFranciscoMedium;
        }
        .CandidateTitle {
            color: #999999;
            min-width: 224px;
            margin-right: 30px;
        }
        .CandidateType {
            width: 100%;
        }
    }
    .OpeningSubscriptionType {
        display: flex;
        flex-direction: row;

        p {
            font-size: 16px;
            font-family: sanFranciscoMedium;
        }
        .OpeningTitle {
            color: #999999;
            min-width: 224px;
            margin-right: 30px;
        }
        .SelectedOpenings {
            margin-left: -10px;
            .SingleOpening {
                display: inline-block;
                white-space: nowrap;
                margin: 0 5px 10px;
                span {
                    border-radius: 14px;
                    background-color: #ccdbf8;
                    padding: 4px 11px;
                    font-size: 12px;
                    color: #4767a0;
                }
                &:last-child {
                    margin: 0 0 10px 5px;
                }
            }
        }
    }
}

.CandidateEditIcon {
    border-top: 1px solid #e1e4e8;
    padding: 20px 0 0 0;
    margin-bottom: 6px;
    text-align: right;
    span {
        font-size: 12px;
        color: #4767a0;
        cursor: pointer;
    }
    img {
        margin: 0 5px 0 0;
    }
}
