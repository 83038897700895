@import '../../Helpers/Stylesheet/colors.scss';

.primary-button {
    background-color: $button-blue;
    height: 40px;
    border-radius: 4px;
    color: $white;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    border: none;
    min-width: 110px;
}

.dropdown-button {
    &:hover,
    &:focus {
        background-color: $white !important;
        color: $button-grey !important;
    }
}
