.header-text {
    height: 32px;
    width: 100%;
    color: #0a1f44 !important;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 32px;
    text-align: center !important;
    font-family: sanFranciscoMedium;
}

.already-have-an-acco {
    height: 16px;
    color: #8a94a6;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
}

.background-colour {
    height: 48px;
    width: 304px;
}

.have-account {
    color: #0d55cf;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.signup-button {
    background-color: #4767a0;
    color: #fff;
    height: 48px;
    width: 144px;
    border-radius: 5px;
    font-family: sanFranciscoMedium;
    border: 0;
}

.signup-form .ant-form-explain {
    text-align: left;
    margin: 5px auto 10px auto;
    width: 304px;
}

.signup-user-icon > svg {
    fill: #c9ced6;
}

.otp-form {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-input input {
    border: 2px solid #007fff !important;
}

.otp-input1 input {
    border: 1.5px solid #e1e4e8 !important;
    border-radius: 4px;
}

.otp-resend {
    text-align: right;
    line-height: 16px;
    min-height: 16px;
    font-size: 12px;
    margin-top: 10px;
    width: auto;
    float: right;
    color: #0d55cf;
    cursor: pointer;
}

.otp-resend-false {
    color: black;
    text-align: right;
    line-height: 16px;
    min-height: 16px;
    font-size: 12px;
    margin-top: 10px;
    width: auto;
    float: right;
}

.otp-resend:hover {
}

.block-wrapper {
    display: flex;
}
.left-blocks {
    width: 50%;
}
.block-left-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.block-element {
    border-radius: 10px;
    background-color: #fff;
    position: relative;
}
.block-e-1 {
    width: 17vw;
    height: 17vh;
}
.block-e-2 {
    width: 11vw;
    height: 17vh;
}
.block-e-3 {
    width: 20vw;
    height: 17vh;
}

.block-left-middle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
}

.block-e-4 {
    width: 3vw;
    height: 11vh;
}
.block-e-5 {
    width: 3vw;
    height: 28.6vh;
    margin: 22px 0;
}
.block-e-6 {
    width: 3vw;
    height: 11.4vh;
}
.block-e-7 {
    width: 35vw;
    height: auto;
}

.block-e-8 {
    width: 10vw;
    height: 210px;
}
.block-e-9 {
    width: 10vw;
    height: 43vh;
    margin: 22px 0 0 0;
}

.block-left-bottom {
    display: flex;
    justify-content: space-between;
}
.block-e-10 {
    width: 20vw;
    height: 13vh;
}
.block-e-11 {
    width: 8vw;
    height: 13vh;
}
.block-e-12 {
    width: 20vw;
    height: 13vh;
}

.right-blocks {
    width: 50%;
}
.block-right-top {
    display: flex;
    justify-content: space-between;
}
.block-e-13 {
    width: 20vw;
    height: 14vh;
}
.block-e-14 {
    width: 12vw;
    height: 14vh;
}
.block-e-15 {
    width: 16vw;
    height: 20vh;
}

.block-right-second {
    display: flex;
    justify-content: space-between;
}

.block-e-16 {
    width: 8vw;
    height: 17vh;
    margin-top: -5vh;
    margin-right: -1vw;
}
.block-e-17 {
    width: 11vw;
    height: 17vh;
    margin-top: -5vh;
}
.block-e-18 {
    width: 11.9vw;
    height: 16.3vh;
    margin-top: 10px;
    background-color: #4767a0;
    padding: 15px 13px 14px 15px;
    margin-top: -4vh;
}
.block-e-19 {
    width: 90px;
    height: 90px;
    /* margin: 10px 0 0 0; */
    padding: 16px 12px 12px 12px;
    background-color: #4767a0;
    margin-top: 2vh;
}
.block-e-19-block-content p {
    color: #fff;
    width: 85px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}
.block-e-19-block-content .block-icon {
    bottom: 0px;
    right: 10px;
}
.block-e-19-block-content .block-icon img {
    width: 28px;
    fill: #fff;
}
.block-e-20 {
    width: 8vw;
    height: 17.4vh;
    margin: 1vh 0 0 0;
}

.block-right-thrid {
    display: flex;
    justify-content: space-between;
}

.block-e-21 {
    width: 11vw;
    height: 11vh;
    margin-top: -5vh;
}
.block-e-22 {
    width: 7.8vw;
    height: 11vh;
    background-color: #4767a0;
    padding: 10px 12px 4px 10px;
    margin-top: -5vh;
}
.block-e-23 {
    width: 19.5vw;
    height: 28.4vh;
    background-color: #4767a0;
    padding: 20px;
    margin-top: -4vh;
}
.block-e-24 {
    width: 8vw;
    height: 33.6vh;
    margin-top: 3vh;
}

.block-right-fourth {
    display: flex;
    justify-content: space-between;
}

.block-e-25 {
    width: 19.5vw;
    height: 28.4vh;
    background-color: #4767a0;
    padding: 20px;
    margin-top: -28.5vh;
}
.block-e-26 {
    width: 16%;
    height: 11.2vh;
    background-color: #4767a0;
    padding: 10px 12px 4px 10px;
    margin: -11.4vh 0 0 -0.5vw;
}
.block-e-27 {
    width: 22%;
    height: 17vh;
    margin-top: 10px;
    background-color: #4767a0;
    padding: 15px 13px 14px 15px;
    margin-top: -11.5vh;
    margin-right: 9vw;
}

.block-right-fifth {
    display: flex;
    justify-content: space-between;
}

.block-e-28 {
    width: 39%;
    height: 22.6vh;
    margin-top: -4vh;
}
.block-e-29 {
    width: 7.9vw;
    height: 11vh;
    margin: -4vh 0 0 -2vw;
}
.block-e-30 {
    width: 17vw;
    height: 17.7vh;
    margin-top: 10px;
}
.block-e-31 {
    width: 10vw;
    height: 11vh;
    margin: -96px 0 0 408px;
}

.block-icon {
    position: absolute;
    bottom: 14px;
    right: 14px;
}
.block-e-18-block-content p {
    color: #fff;
    width: 85px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}
.block-e-22-block-content p {
    color: #fff;
    width: 85px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
}
.block-e-22-block-content .block-icon {
    bottom: 4px;
    right: 12px;
}

.block-e-23-block-content p {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    width: 148px;
}
.block-e-23-block-content .block-icon {
    right: 20px;
    bottom: 20px;
}
.block-e-1,
.block-e-2,
.block-e-3,
.block-e-4,
.block-e-5,
.block-e-6,
.block-e-8,
.block-e-9,
.block-e-10,
.block-e-11,
.block-e-12,
.block-e-13,
.block-e-14,
.block-e-15,
.block-e-16,
.block-e-17,
.block-e-20,
.block-e-21,
.block-e-9,
.block-e-24,
.block-e-29,
.block-e-28,
.block-e-31,
.block-e-30 {
    background-color: transparent !important;
}
.mobile-view-half-bottom {
    display: none;
}

@media only screen and (max-width: 1280px) {
    .block-e-17 {
        margin-left: 1vw;
    }
    .block-left-top {
        margin-bottom: 10px;
    }
    .block-e-6 {
        height: 46vh;
    }
    .block-e-9 {
        margin: 10px 0 0 0;
        height: 56.5vh;
    }
    .block-left-middle {
        margin-bottom: 10px;
    }
    .block-e-9 {
        margin: 10px 0 0 0;
        height: 56.5vh;
    }
    .block-e-29,
    .block-e-28 {
        height: 23vh;
    }
    .block-e-31 {
        width: 20vw;
        height: 20vh;
        margin: -140px 0 0 375px;
    }
    .block-e-30 {
        width: 18vw;
        height: 17vh;
    }
    .block-e-7 {
        width: 34vw;
        min-height: auto;
    }
    .block-e-2 {
        width: 10vw;
    }
    .block-e-11 {
        width: 7vw;
    }
    .block-e-21,
    .block-e-22 {
        height: 13vh;
    }
}

@media only screen and (max-width: 995px) {
    .left-blocks {
        width: 100% !important;
    }
    .block-e-7 {
        width: 100% !important;
    }
    .left-blocks {
        margin-right: 0 !important;
    }
    .block-last-left,
    .block-left-bottom,
    .block-last-left,
    .block-left-middle-right,
    .block-e-2,
    .block-e-3,
    .block-left-bottom {
        display: none !important;
    }
    .block-form-holder {
        width: 100%;
    }
    .signup-button {
        width: 100% !important;
    }
    .block-wrapper {
        display: block !important;
    }
    .block-e-1 {
        width: 100% !important;
        height: unset !important;
        border-radius: 0 !important;
    }
    .right-blocks {
        display: none;
    }
    .header-text {
        font-size: 18px !important;
    }
    .mobile-view-half-bottom {
        display: block;
        padding: 0 10px;
    }
    .block-e-25,
    .block-e-23 {
        margin-top: 0 !important;
        width: 100% !important;
        height: unset !important;
        display: inline-block;
    }
    .block-e-25 p {
        font-size: 18px !important;
        line-height: 28px !important;
        width: 80%;
    }
    .block-e-25 .block-icon {
        position: unset;
        float: right;
    }
    .block-e-23-block-content p {
        font-size: 18px !important;
        line-height: 28px !important;
        width: 80% !important;
    }
    .block-e-23-block-content .block-icon {
        position: unset;
        float: right;
    }
    .block-e-26,
    .block-e-22 {
        margin-top: 0 !important;
        width: 50% !important;
    }
    .realtime-collabration {
        display: flex;
        margin: 10px 0;
    }
    .block-e-22 {
        margin-right: 5px;
        height: 94px;
    }
    .block-e-23 p {
        width: 100% !important;
    }
    .block-e-26 {
        margin-left: 5px !important;
        height: 94px;
    }
    .block-e-19 {
        margin-top: 0;
        width: 30% !important;
        margin-right: 5px;
        height: 100px !important;
    }
    .block-e-19 p {
        font-size: 10px !important;
        width: unset !important;
    }
    .block-e-27 {
        margin-top: 0 !important;
        width: 70% !important;
        margin-left: 5px;
        margin-right: 0 !important;
        height: 100px !important;
        display: inline-block;
        padding: 13px !important;
    }
    .block-e-27 p {
        font-size: 14px !important;
        width: 100% !important;
        margin-bottom: 0;
    }
    .block-e-27 .block-icon {
        position: unset;
        float: right;
    }
}
